import React from 'react';
import Button from '@material-ui/core/Button';
import { PinterestIcon } from '../../images/icons';
import GetConfig from '../../helpers/GetConfig';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import { getUserStats } from '../../helpers';

interface PinterestButtonProps {
  gamesStarted: string;
  gamesFinished: string;
  currentStreak: string;
  levelsCompleted: string;
  totalPlayTime: string;
  highestPositionAllTime: string;
}

const PinterestButton: React.FC<PinterestButtonProps> = ({
  gamesStarted,
  gamesFinished,
  currentStreak,
  levelsCompleted,
  totalPlayTime,
  highestPositionAllTime,
}) => {
  const isWebView = useSelector((state: RootState) => state.isWebView.webView);
  const { apiShareUrl } = GetConfig();
  const shareCode = useSelector((state: RootState) => state.user.shareCode);

  const share = () => {
    const shareUrl = `${apiShareUrl}share/${shareCode}`;
    const shareText = getUserStats(
      gamesStarted,
      gamesFinished,
      currentStreak,
      levelsCompleted,
      totalPlayTime,
      highestPositionAllTime
    );
    const pinterestUrl = `http://www.pinterest.com/pin/create/button?url=${shareUrl}&description=${encodeURIComponent(
      shareText
    )}`;
    trackEvent('Share', 'Share Selected', 'Pinterest');
    if (isWebView) {
      window.location.href = pinterestUrl;
    } else {
      window.open(pinterestUrl, 'PinIt', 'location=0,status=0,width=800,height=650');
    }
  };

  return (
    <Button
      classes={{
        root: 'PinterestBtn ShareBtn',
        label: 'BtnLabel',
      }}
      onClick={share}
    >
      <PinterestIcon />
    </Button>
  );
};

export default PinterestButton;
