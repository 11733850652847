import Game from './Scenes/Game';

export enum BoardGravity {
  None,
  Down,
  Up,
  Left,
  Right,
}

export interface coord {
  x: number;
  y: number;
}

export enum GameOverType {
  TimeUp,
  NoMoves,
}

export interface SceneConfig {
  level: number;
  windowWidth: number;
  totalNumberOfLevels: number;
  autoStart?: boolean;
}

export interface BoardPosition {
  color: number;
  filled: boolean;
}

export class Level {
  constructor(
    public columnCount: number,
    public rowCount: number,
    public timeInSeconds: number,
    public gravity: BoardGravity,
    public numberOfTileTypes: number,
    public pointsPerMatch: number,
    public levelCompleteMessage?: string,
    public changingGravity: boolean = false
  ) {}

  isMobile: boolean = false;

  numberOfColumns(): number {
    return this.isMobile ? this.rowCount : this.columnCount;
  }

  numberOfRows(): number {
    return this.isMobile ? this.columnCount : this.rowCount;
  }

  maxOfColumnsOrRows(): number {
    return Math.max(this.columnCount, this.rowCount);
  }
}

export interface LevelList {
  [key: number]: Level;
}

export interface PieceSprite extends Phaser.GameObjects.GameObject, Phaser.GameObjects.Sprite {
  color?: number;
  piece?: boolean;
  pos?: coord;
}

export interface StormPhaserGame extends Phaser.Game {
  scene: StormGameScene;
}

interface StormGameScene extends Phaser.Scenes.SceneManager {
  keys: StormGameKeys;
}

interface StormGameKeys {
  Game?: Game;
}
