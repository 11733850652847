import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { trackEvent } from '../tracking/GoogleAnalytics';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    trackEvent('Error', 'AppError', 'General Error');
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className="error">
            <Container>
              <h5>Oops, looks like we can't find the page you were looking for.</h5>
              <Link to="/">
                <Button variant="primary" size="lg">
                  Back to homepage
                </Button>
              </Link>
            </Container>
          </div>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
