import { BoardPosition, coord, Level } from '../types';

export const isInside: (pos: coord, board: Array<Array<BoardPosition>>) => boolean = (pos, board) =>
  pos.x >= 0 && pos.y >= 0 && pos.x < board[0].length && pos.y < board.length;

export const findStraight: (
  to: coord,
  pos1: coord,
  pos2: coord,
  board: Array<Array<BoardPosition>>,
  numberOfRows: number
) => boolean = (to, pos1, pos2, board, numberOfRows) => {
  for (let i = 1; i <= numberOfRows; i++) {
    let val = {
      x: pos1.x + to.x * i,
      y: pos1.y + to.y * i,
    };
    if (isInside(val, board)) {
      if (board[val.y][val.x].filled) {
        if (val.x === pos2.x && val.y === pos2.y) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  return false;
};

const find_around: (
  to: coord,
  pos1: coord,
  pos2: coord,
  board: Array<Array<BoardPosition>>,
  level: Level,
  next?: boolean
) => Array<coord> | null = (
  to: coord,
  pos1: coord,
  pos2: coord,
  board: Array<Array<BoardPosition>>,
  level: Level,
  next: boolean = false
) => {
  let ways = [];
  for (let i = 1; i <= level.maxOfColumnsOrRows(); i++) {
    let val = {
      x: pos1.x + to.x * i,
      y: pos1.y + to.y * i,
    };
    if (isInside(val, board)) {
      if (board[val.y][val.x].filled) {
        if (!board[val.y][val.x]) {
          //return false;
        }
        if (val.x === pos2.x && val.y === pos2.y) {
          ways.push(val);
          return ways;
        } else {
          return null;
        }
      } else {
        ways.push(val);
        if (next) {
          if (to.x === 1 || to.x === -1) {
            let top = find_around({ x: 0, y: -1 }, val, pos2, board, level);
            if (top) {
              for (let i = 0; i < top.length; i++) {
                ways.push(top[i]);
              }
              return ways;
            }
            let down = find_around({ x: 0, y: 1 }, val, pos2, board, level);
            if (down) {
              for (let i = 0; i < down.length; i++) {
                ways.push(down[i]);
              }
              return ways;
            }
          } else {
            let left = find_around({ x: -1, y: 0 }, val, pos2, board, level);
            if (left) {
              for (let i = 0; i < left.length; i++) {
                ways.push(left[i]);
              }
              return ways;
            }
            let right = find_around({ x: 1, y: 0 }, val, pos2, board, level);
            if (right) {
              for (let i = 0; i < right.length; i++) {
                ways.push(right[i]);
              }
              return ways;
            }
          }
        } else {
          if (to.x === 1 || to.x === -1) {
            if (findStraight({ x: 0, y: 1 }, val, pos2, board, level.maxOfColumnsOrRows())) {
              for (let j = val.y + 1; j < pos2.y + 1; j++) {
                ways.push({ x: val.x, y: j });
              }
              return ways;
            } else if (findStraight({ x: 0, y: -1 }, val, pos2, board, level.maxOfColumnsOrRows())) {
              for (let j = val.y - 1; j > pos2.y - 1; j--) {
                ways.push({ x: val.x, y: j });
              }
              return ways;
            }
          } else {
            if (findStraight({ x: 1, y: 0 }, val, pos2, board, level.maxOfColumnsOrRows())) {
              for (let j = val.x + 1; j < pos2.x + 1; j++) {
                ways.push({ x: j, y: val.y });
              }
              return ways;
            } else if (findStraight({ x: -1, y: 0 }, val, pos2, board, level.maxOfColumnsOrRows())) {
              for (let j = val.x - 1; j > pos2.x - 1; j--) {
                ways.push({ x: j, y: val.y });
              }
              return ways;
            }
          }
        }
      }
    }
  }
  return null;
};

export const findWay = (pos1: coord, pos2: coord, array: Array<Array<BoardPosition>>, level: Level) => {
  let board = JSON.parse(JSON.stringify(array)); //Framed board from array
  for (let y = 0; y < level.numberOfRows(); y++) {
    board[y].unshift({ filled: false });
    board[y].push({ filled: false });
  }
  let bx = [];
  for (let x = 0; x < level.numberOfColumns() + 2; x++) {
    bx.push({ filled: false });
  }
  board.push(bx);
  board.unshift(bx);
  pos1.x++;
  pos1.y++;
  pos2.x++;
  pos2.y++;
  let ways = [];
  for (let i = 0; i < 4; i++) {
    let to = { x: -1, y: 0 };
    if (i === 1) {
      to = { x: 1, y: 0 };
    } else if (i === 2) {
      to = { x: 0, y: -1 };
    } else if (i === 3) {
      to = { x: 0, y: 1 };
    }
    let res = find_around(to, pos1, pos2, board, level, true);
    if (res) {
      let arr = [];
      res.unshift(pos1);
      for (let i = 0; i < res.length; i++) {
        arr.push({ x: res[i].x - 1, y: res[i].y - 1 });
      }
      ways.push(arr);
    }
  }
  let pick = null;
  let max = 999;
  for (let i = 0; i < ways.length; i++) {
    if (ways[i].length < max) {
      max = ways[i].length;
      pick = ways[i];
    }
  }

  pos1.x--;
  pos1.y--;
  pos2.x--;
  pos2.y--;
  return pick;
};

export const is_layer_empty = (board: Array<Array<BoardPosition>>, level: Level) => {
  for (let y = 0; y < level.numberOfRows(); y++) {
    for (let x = 0; x < level.numberOfColumns(); x++) {
      if (board[y][x].filled) {
        return false;
      }
    }
  }
  return true;
}
