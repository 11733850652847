/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Container } from 'react-bootstrap';

import { IGroupBaseModel, IPagedGroupListModel } from 'types/Groups';
import callApi from '../infrastructure/api/CallApi';
import Loader from './Loader';
import PublicGroupListItem from './Groups/PublicGroupListItem';
import { getPageNumbers } from 'helpers';
import PaginationButton from './Groups/PaginationButton';
import ArrowLeft from '../images/chex2023/icons/ArrowLeft';
import ArrowRight from '../images/chex2023/icons/ArrowRight';
import { trackEvent, trackPageView } from 'infrastructure/tracking/GoogleAnalytics';
import { useSelector } from 'react-redux';
import RootState from 'types';
import { groupPublicPage } from 'types/pageNameContants';
import { badgeNotification } from 'helpers/BadgeNotification';
import { useHistory } from 'react-router';
import { ProfileRoute } from 'constants/Routes';

const GroupPublicListPage: React.FC = (props) => {
  const componentMountRef = useRef<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [items, setItems] = useState<Array<IGroupBaseModel>>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [groupIds, setGroupIds] = useState<Array<string>>([]);
  const [apiCalled, setApiCalled] = useState<boolean>(true);
  const { id: userId } = useSelector((state: RootState) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (!items.length || componentMountRef.current) {
      callApi<IPagedGroupListModel>(`api/app/group/paged-list?page=${page}`, 'GET').then(({ data }) => {
        setNumberOfPages(data.numberOfPages);
        setItems(data.items);
      });
    }
    componentMountRef.current = true;
    trackPageView(`${groupPublicPage}${page}`, `/group-list/${page}`);
  }, [page]);

  useEffect(() => {
    callApi<Array<string>>(`api/app/group/user-group-ids/${userId}`, 'GET').then(({ data }) => {
      console.log(data);
      setGroupIds(data);
      setApiCalled(true);
    });
  }, []);

  const onNewGroup = (group: IGroupBaseModel) => {
    setGroupIds([...groupIds, group.id]);
    if (group.awardedBadges.length > 0) {
      badgeNotification(group.awardedBadges, [], () => history.push(ProfileRoute));
    }

    setItems(
      items.map((publicGroup) => {
        if (publicGroup.id === group.id) {
          return {
            ...publicGroup,
            memberCount: publicGroup.memberCount + 1,
          };
        }
        return publicGroup;
      })
    );
  };

  if (items.length === 0 || !apiCalled) {
    return <Loader />;
  }

  return (
    <Container className="my-4 landing_main group my-md-5">
      <h3 className="mb-3 pretitle mb-md-5">Play with your friends and other Hallmark viewers!</h3>
      <h1>Public Groups</h1>
      <Row className="public-group-list-item-container" data-masonry='{"percentPosition": true }'>
        {items.map((x) => (
          <PublicGroupListItem
            key={x.id}
            group={x}
            onGroupJoinSuccess={onNewGroup}
            isMember={groupIds.includes(x.id)}
          />
        ))}
      </Row>
      <Row className="justify-content-center">
        <nav aria-label="...">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link next"
                disabled={page === 1}
                onClick={() => {
                  trackEvent('Previous page', 'Previous page click', 'Groups Public Page ' + page);
                  setPage((currentPage) => currentPage - 1);
                }}
              >
                <ArrowLeft />
                <span className="sr-only">Previous</span>
              </button>
            </li>
            <div className="groups-navigation-desktop">
              {getPageNumbers(page, numberOfPages, false).map((pageNumber, i) => (
                <PaginationButton
                  key={i}
                  pageNumber={pageNumber}
                  index={i}
                  isSelected={pageNumber === page}
                  onClick={() => {
                    trackEvent('Page number', 'Page number click ' + pageNumber, 'Groups Public Page ' + page);
                    if (pageNumber !== page) {
                      setPage(pageNumber);
                    }
                  }}
                />
              ))}
            </div>
            <div className="groups-navigation-mobile">
              {getPageNumbers(page, numberOfPages, true).map((pageNumber, i) => (
                <PaginationButton
                  key={i}
                  pageNumber={pageNumber}
                  index={i}
                  isSelected={pageNumber === page}
                  onClick={() => {
                    trackEvent('Page number', 'Page number click ' + pageNumber, 'Groups Public Page ' + page);
                    if (pageNumber !== page) {
                      setPage(pageNumber);
                    }
                  }}
                />
              ))}
            </div>
            <li className="page-item">
              <button
                className="page-link previous"
                disabled={page === numberOfPages}
                onClick={() => {
                  trackEvent('Next page', 'Next page click', 'Groups Public Page ' + page);
                  setPage((currentPage) => currentPage + 1);
                }}
              >
                <ArrowRight />
                <span className="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </nav>
      </Row>
    </Container>
  );
};

export default GroupPublicListPage;
