import RootState, { TuneInType } from '../../types';
import { PromotedItemDto } from '../../types/index';

const promotedItemFallback: PromotedItemDto = {
  type: TuneInType.Invite,
  externalUrl: '',
  imageUrl: '',
  logoUrl: '',
  title: '',
  displayTitle: '',
  tuneIn: '',
  vimeoCode: '',
  videoUrl: '',
  awardEntry: false,
  id: '',
};
export const selectPromotedItem = (state: RootState) =>
  state.promotion.count ? state.promotion.promotedItems[state.promotion.promotionIndex] : promotedItemFallback;
