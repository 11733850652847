import React from 'react';

const GroupsIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_689_18460)">
        <path
          d="M23.36 5.59H21.09L19.76 2.97C19.708 2.86455 19.6275 2.77577 19.5277 2.71368C19.4278 2.6516 19.3126 2.6187 19.195 2.6187C19.0775 2.6187 18.9622 2.6516 18.8624 2.71368C18.7625 2.77577 18.6821 2.86455 18.63 2.97L18.44 3.33C18.5291 3.45764 18.6029 3.59524 18.66 3.74C18.8268 4.14332 18.8693 4.58728 18.782 5.01491C18.6947 5.44253 18.4816 5.83432 18.17 6.14L16.54 7.73L17.44 9.79C17.5638 10.0686 17.6278 10.3701 17.6278 10.675C17.6278 10.9799 17.5638 11.2814 17.44 11.56L19.2 10.56L22 12.14C22.1144 12.2076 22.2471 12.2374 22.3794 12.225C22.5116 12.2127 22.6366 12.1589 22.7364 12.0712C22.8362 11.9836 22.9058 11.8667 22.9352 11.7372C22.9646 11.6076 22.9523 11.4721 22.9 11.35L21.72 8.7L23.81 6.7C23.9189 6.61773 23.9977 6.50183 24.034 6.37026C24.0703 6.2387 24.0622 6.09883 24.0109 5.97235C23.9596 5.84586 23.868 5.73983 23.7504 5.6707C23.6327 5.60157 23.4955 5.5732 23.36 5.59Z"
          fill="#652B82"
        />
        <path
          d="M6.34994 9.79L7.23994 7.73L5.61994 6.14C5.30841 5.83432 5.09533 5.44253 5.00802 5.01491C4.92071 4.58728 4.96317 4.14332 5.12994 3.74C5.20885 3.52625 5.32762 3.32944 5.47994 3.16L5.37994 2.97C5.32793 2.86455 5.24744 2.77577 5.14759 2.71368C5.04775 2.6516 4.93252 2.6187 4.81494 2.6187C4.69736 2.6187 4.58213 2.6516 4.48229 2.71368C4.38244 2.77577 4.30195 2.86455 4.24994 2.97L2.90994 5.59H0.64994C0.525998 5.58733 0.404103 5.62188 0.299994 5.68918C0.195885 5.75649 0.114344 5.85346 0.0658999 5.96757C0.0174556 6.08169 0.00433291 6.2077 0.0282264 6.32935C0.0521199 6.45099 0.111932 6.56268 0.19994 6.65L2.27994 8.65L1.12994 11.35C1.08006 11.4709 1.06907 11.6044 1.0985 11.7319C1.12792 11.8594 1.1963 11.9745 1.29414 12.0613C1.39198 12.1482 1.51442 12.2024 1.64448 12.2165C1.77455 12.2306 1.90577 12.2039 2.01994 12.14L4.80994 10.57L6.28994 11.4C6.10464 10.8752 6.12609 10.2995 6.34994 9.79Z"
          fill="#652B82"
        />
        <path
          d="M12.5304 0.899998L14.0404 3.9H16.6104C16.7503 3.89796 16.8876 3.93791 17.0046 4.01468C17.1216 4.09145 17.2129 4.20153 17.2667 4.33069C17.3205 4.45986 17.3344 4.60219 17.3065 4.73932C17.2787 4.87645 17.2103 5.00208 17.1104 5.1L14.7604 7.39L16.0604 10.39C16.1158 10.5259 16.1277 10.6757 16.0945 10.8187C16.0613 10.9617 15.9846 11.0909 15.875 11.1885C15.7653 11.2861 15.6281 11.3473 15.4822 11.3637C15.3363 11.3801 15.189 11.3508 15.0604 11.28L11.8904 9.5L8.74037 11.28C8.61148 11.3449 8.46608 11.3695 8.32302 11.3507C8.17996 11.3319 8.04584 11.2706 7.93806 11.1747C7.83028 11.0787 7.7538 10.9526 7.71855 10.8127C7.6833 10.6728 7.6909 10.5255 7.74037 10.39L9.00037 7.39L6.67037 5.07C6.56971 4.9715 6.50111 4.84493 6.47352 4.70683C6.44594 4.56873 6.46064 4.42551 6.51573 4.2959C6.57081 4.16629 6.66371 4.05631 6.78229 3.98033C6.90086 3.90434 7.0396 3.86589 7.18037 3.87H9.75037L11.2504 0.869998C11.3111 0.750711 11.4042 0.651008 11.5191 0.582385C11.634 0.513762 11.766 0.479015 11.8998 0.482151C12.0336 0.485287 12.1638 0.526179 12.2754 0.600109C12.3869 0.67404 12.4753 0.777999 12.5304 0.899998Z"
          fill="#652B82"
        />
        <path
          d="M17.1797 17.65C17.1797 18.2653 17.4241 18.8554 17.8592 19.2905C18.2943 19.7256 18.8844 19.97 19.4997 19.97C20.115 19.97 20.7051 19.7256 21.1402 19.2905C21.5753 18.8554 21.8197 18.2653 21.8197 17.65C21.8197 17.0347 21.5753 16.4446 21.1402 16.0095C20.7051 15.5744 20.115 15.33 19.4997 15.33C18.8844 15.33 18.2943 15.5744 17.8592 16.0095C17.4241 16.4446 17.1797 17.0347 17.1797 17.65Z"
          fill="#652B82"
        />
        <path
          d="M2.20996 17.65C2.20996 17.9547 2.26997 18.2563 2.38656 18.5378C2.50315 18.8193 2.67404 19.075 2.88947 19.2905C3.10491 19.5059 3.36066 19.6768 3.64214 19.7934C3.92361 19.91 4.22529 19.97 4.52996 19.97C4.83463 19.97 5.13631 19.91 5.41779 19.7934C5.69926 19.6768 5.95502 19.5059 6.17045 19.2905C6.38588 19.075 6.55677 18.8193 6.67336 18.5378C6.78995 18.2563 6.84996 17.9547 6.84996 17.65C6.84996 17.3453 6.78995 17.0436 6.67336 16.7622C6.55677 16.4807 6.38588 16.2249 6.17045 16.0095C5.95502 15.7941 5.69926 15.6232 5.41779 15.5066C5.13631 15.39 4.83463 15.33 4.52996 15.33C4.22529 15.33 3.92361 15.39 3.64214 15.5066C3.36066 15.6232 3.10491 15.7941 2.88947 16.0095C2.67404 16.2249 2.50315 16.4807 2.38656 16.7622C2.26997 17.0436 2.20996 17.3453 2.20996 17.65Z"
          fill="#652B82"
        />
        <path
          d="M18.1697 24.5H23.9997C23.4663 23.5192 22.6164 22.7481 21.5885 22.3123C20.5607 21.8764 19.4155 21.8016 18.3397 22.1C17.9326 22.2325 17.5431 22.4138 17.1797 22.64C17.5806 23.2196 17.9128 23.8438 18.1697 24.5Z"
          fill="#652B82"
        />
        <path
          d="M6.77 22.61C6.42657 22.3938 6.05691 22.2224 5.67 22.1C4.59279 21.7991 3.44535 21.8726 2.41537 22.3086C1.38539 22.7446 0.533837 23.5172 0 24.5H5.75C6.01754 23.8335 6.35972 23.1995 6.77 22.61Z"
          fill="#652B82"
        />
        <path
          d="M17.0798 24.5C16.683 23.458 15.9912 22.5541 15.0891 21.8988C14.187 21.2435 13.1135 20.8652 11.9998 20.81C10.8723 20.8499 9.78151 21.2211 8.86378 21.8774C7.94605 22.5337 7.24208 23.4459 6.83984 24.5H17.0798Z"
          fill="#652B82"
        />
        <path
          d="M9 16.75C9 17.1321 9.07527 17.5105 9.22151 17.8636C9.36775 18.2167 9.5821 18.5375 9.85232 18.8077C10.1225 19.0779 10.4433 19.2922 10.7964 19.4385C11.1494 19.5847 11.5279 19.66 11.91 19.66C12.2921 19.66 12.6706 19.5847 13.0236 19.4385C13.3767 19.2922 13.6975 19.0779 13.9677 18.8077C14.2379 18.5375 14.4522 18.2167 14.5985 17.8636C14.7447 17.5105 14.82 17.1321 14.82 16.75C14.82 16.3678 14.7447 15.9894 14.5985 15.6364C14.4522 15.2833 14.2379 14.9625 13.9677 14.6923C13.6975 14.4221 13.3767 14.2077 13.0236 14.0615C12.6706 13.9153 12.2921 13.84 11.91 13.84C11.5279 13.84 11.1494 13.9153 10.7964 14.0615C10.4433 14.2077 10.1225 14.4221 9.85232 14.6923C9.5821 14.9625 9.36775 15.2833 9.22151 15.6364C9.07527 15.9894 9 16.3678 9 16.75Z"
          fill="#652B82"
        />
      </g>
      <defs>
        <clipPath id="clip0_689_18460">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <linearGradient id="gradient" x1="20" y1="22" x2="3.3939" y2="9.70365" gradientUnits="userSpaceOnUse">
          <stop stop-color="#BB0F8B" />
          <stop offset="1" stop-color="#FFBC39" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GroupsIcon;
