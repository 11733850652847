import GameConfigState from './GameConfigState';
import { GameConfigActionTypes, SETCONFIG } from './actionTypes';

const initialState: GameConfigState = {
  landingPageText: '',
  tiles: [],
  tileCount: 0,
  prizeTitle: '',
};

const gameConfigReducer: (state: GameConfigState, action: GameConfigActionTypes) => GameConfigState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SETCONFIG:
      const newState: GameConfigState = {
        landingPageText: action.landingPageText,
        tiles: action.tiles,
        tileCount: action.tiles.length,
        prizeTitle: action.prizeTitle,
      };
      return newState;
    default:
      return state;
  }
};

export default gameConfigReducer;
