export const landingPage = "Hallmark Channel's Matching Moments - Landing Page";
export const profilePage = "Hallmark Channel's Matching Moments - Profile Page";
export const formPage = "Hallmark Channel's Matching Moments - Form Page";
export const gameIntroModal = "Hallmark Channel's Matching Moments - Game Intro Modal";
export const gamePage = "Hallmark Channel's Matching Moments - Game Screen";
export const nextLevelModal = "Hallmark Channel's Matching Moments - Next Level Modal";
export const gameCompleteModal = "Hallmark Channel's Matching Moments - Game Complete Modal";
export const leaderboardWeeklyPage = "Hallmark Channel's Matching Moments - Leaderboard Weekly";
export const leaderboardOverallPage = "Hallmark Channel's Matching Moments - Leaderboard Overall";
export const leaderboardGroupPage = "Hallmark Channel's Matching Moments - Leaderboard Group";
export const gameOverTimeUpModal = "Hallmark Channel's Matching Moments - Game Over Modal - Out of Time";
export const returningUserModal = "Hallmark Channel's Matching Moments - Continue/Restart Game Modal";
export const gameOverNoMoreMovesModal = "Hallmark Channel's Matching Moments - Game Over Modal - No More Moves";

export const groupDetailsPage = "Hallmark Channel's Matching Moments - Group Details Page";
export const groupIndexPage = "Hallmark Channel's Matching Moments - Group Index Page";
export const groupPublicPage = "Hallmark Channel's Matching Moments - Group Public Page ";

export const gamePausedModal = "Hallmark Channel's Matching Moments - Game Paused Modal";
export const gameHelpModal = "Hallmark Channel's Matching Moments - Game Help Modal";
export const rulesPage = "Hallmark Channel's Matching Moments - Rules";
