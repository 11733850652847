import HelpIcon from './help.svg';
import HintIcon from './hint.svg';
import LeaderboardIcon from './leaderboard.svg';
import MuteIcon from './mute.svg';
import PauseIcon from './pause.svg';
import PointsIcon from './points.svg';
import ShuffleIcon from './shuffle.svg';
import TimeIcon from './time.svg';
import UnmuteIcon from './unmute.svg';

export const gameNavIconsColor = '#657B65';

const GameNavIcons = {
  Help: HelpIcon,
  Hint: HintIcon,
  Leaderboard: LeaderboardIcon,
  Mute: MuteIcon,
  Pause: PauseIcon,
  Points: PointsIcon,
  Shuffle: ShuffleIcon,
  Time: TimeIcon,
  Unmute: UnmuteIcon,
};
export default GameNavIcons;
