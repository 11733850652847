import React from 'react';
import PlayIcon from './icons/PlayIcon';
import LeaderboardIcon from './icons/LeaderboardIcon';
import GroupsIcon from './icons/GroupsIcon';
import ProfileIcon from './icons/ProfileIcon';
import { GameRoute, LeaderboardRoute, GroupIndexRoute, ProfileRoute } from 'constants/Routes';

export const getNavbarUrl = (title: string) => {
  switch (title) {
    case 'Play':
      return GameRoute;
    case 'Leaderboard':
      return LeaderboardRoute;
    case 'Groups':
      return GroupIndexRoute;
    case 'Profile':
      return ProfileRoute;
    default:
      return '/';
  }
};

export const getNavbarIcon = (title: string) => {
  switch (title) {
    case 'Play':
      return <PlayIcon />;
    case 'Leaderboard':
      return <LeaderboardIcon />;
    case 'Groups':
      return <GroupsIcon />;
    case 'Profile':
      return <ProfileIcon />;
  }
};
