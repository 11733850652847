import { LOGIN, LOGOUT, SEENMODAL, SETENTRYCOUNT, UserActionTypes, SETHIGHSCORE, SETPROFILEIMAGE } from './actionTypes';
import { UserDto, EntryCountDto } from '../../types';

export function login(user: UserDto): UserActionTypes {
  return { type: LOGIN, ...user };
}

export function logout(): UserActionTypes {
  return { type: LOGOUT };
}

export function viewedModal(): UserActionTypes {
  return { type: SEENMODAL };
}

export function setEntryCount(dto: EntryCountDto): UserActionTypes {
  return { type: SETENTRYCOUNT, ...dto };
}

export function setHighScore(score: number): UserActionTypes {
  return { type: SETHIGHSCORE, score };
}

export function setProfileImage(profileImage: number): UserActionTypes {
  return { type: SETPROFILEIMAGE, profileImage };
}
