/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState, { UserBaseDto } from '../../types/index';
import useWindowDimensions from './useWindowDimensions';
import { SceneConfig, GameOverType } from '../../game/types';
import Game from '../../game/Scenes/Game';
import levels from '../../game/Helpers/levels';
import { formatTime } from '../../helpers/index';
import {
  addTimeBonus,
  restartGame,
  loaded,
  showHelp,
  sentToApi,
  apiCallEnd,
  apiCallStart,
} from '../../slices/game/actions';
import { setEntryCount } from '../../slices/users/actions';
import { localStorageKey } from '../../constants';
import useCountdownTimer from './useCountdownTimer';
import callApi from '../../infrastructure/api/CallApi';
import getConfig, { Config } from '../../game/Settings/config';
import { EntryCountDto } from '../../types/index';
import trackPageViewGoogle from '../../infrastructure/tracking';
import { gameCompleteModal, nextLevelModal } from '../../types/pageNameContants';
import { trackEvent } from '../tracking/GoogleAnalytics';
import { startingHints, startingShuffles } from '../../slices/game/reducer';
import { badgeNotification } from '../../helpers/BadgeNotification';
import { useHistory } from 'react-router';
import { ProfileRoute } from '../../constants/Routes';

interface StormPhaserGame extends Phaser.Game {
  scene: StormGameScene;
}

interface StormGameScene extends Phaser.Scenes.SceneManager {
  keys: StormGameKeys;
}

interface StormGameKeys {
  Game?: Game;
}

const useGame: (
  gameRef: React.MutableRefObject<StormPhaserGame | undefined>
) => [
  Config,
  string,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.Dispatch<React.SetStateAction<boolean>>,
  () => void
] = (gameRef) => {
  const dispatch = useDispatch();
  const [secondsRemaining, formattedTime, setSecondsForNextLevel, isPaused, setIsPaused] = useCountdownTimer(() =>
    gameRef.current?.scene.keys.Game?.gameOver(GameOverType.TimeUp)
  );

  const {
    levelCompleted,
    showNextLevelModal,
    totalScore,
    totalSecondsRemaining,
    levelScore,
    shufflesRemaining,
    hintsRemaining,
    awaitingBonus,
    showGameOverModal,
    requiresSaveToApi,
    totalNumberOfLevels,
  } = useSelector((state: RootState) => state.game);
  const user = useSelector((state: RootState) => state.user);
  const { windowWidth } = useWindowDimensions();
  const config = getConfig(windowWidth);
  const history = useHistory();
  const getActiveLevel = () => (levelCompleted === totalNumberOfLevels ? 1 : levelCompleted + 1);

  const saveUserToLocalStorage = (
    score?: number,
    level?: number,
    totalSeconds?: number,
    hints?: number,
    shuffles?: number
  ) => {
    try {
      const localStorageUser: UserBaseDto = {
        ...user,
        currentScore: score === undefined ? totalScore : score,
        totalSecondsRemaining: totalSeconds === undefined ? totalSecondsRemaining : totalSeconds,
        lastLevelPlayed: level === undefined ? levelCompleted : level,
        isNew: false,
        hintsRemaining: hints === undefined ? hintsRemaining : hints,
        shufflesRemaining: shuffles === undefined ? shufflesRemaining : shuffles,
        newBadgeNotification: null,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(localStorageUser));
    } catch {}
  };
  // On initial mount create the game
  useEffect(() => {
    gameRef.current = new Phaser.Game(config);
    gameRef.current.scene.add('Game', Game);
    const startingConfig: SceneConfig = {
      level: getActiveLevel(),
      windowWidth,
      totalNumberOfLevels,
    };
    gameRef.current.scene.start('Game', startingConfig);
    setSecondsForNextLevel(levels[getActiveLevel()].timeInSeconds);
    if (showGameOverModal || showNextLevelModal) {
      setShowModal(false);
    }
    gameRef.current.input.mouse.preventDefaultWheel = false;
    gameRef.current.input.touch.capture = false;
    return () => {
      gameRef.current && gameRef.current.destroy(true);
      dispatch(loaded(false));
      dispatch(showHelp(false));
    };
  }, []);

  useEffect(() => {
    if (showGameOverModal) {
      if (!isPaused) {
        setIsPaused(true);
      }
      callApi<any>(`api/app/user/game-over/${user.id}`, 'POST', {});
      saveUserToLocalStorage(0, 0, 0, startingHints, startingShuffles);
    }
  }, [showGameOverModal]);

  // On level completed add time remaining bonus
  useEffect(() => {
    if (awaitingBonus) {
      setIsPaused(true);
      const completionTime = levels[levelCompleted].timeInSeconds - secondsRemaining;
      const bonusPoints = secondsRemaining * levelCompleted;
      dispatch(addTimeBonus(secondsRemaining, formatTime(completionTime), bonusPoints));
    }
  }, [awaitingBonus]);

  // After adding time bonus - update timer for next level and call api with level completion
  useEffect(() => {
    if (showNextLevelModal) {
      if (requiresSaveToApi) {
        const nextLevel = levels[getActiveLevel()];
        setSecondsForNextLevel(nextLevel.timeInSeconds);
        dispatch(sentToApi());
        dispatch(apiCallStart());
        callApi<EntryCountDto>('api/app/game-round/completed-round', 'POST', {
          userId: user.id,
          secondsRemaining,
          score: levelScore,
          level: levelCompleted,
          playthroughScore: totalScore,
          playthroughSecondsRemaining: totalSecondsRemaining,
          hintsRemaining: hintsRemaining,
          shufflesRemaining: shufflesRemaining,
        })
          .then(({ data }) => {
            dispatch(setEntryCount(data));
            dispatch(apiCallEnd());
            badgeNotification(data.awardedBadges, data.nearlyThereNotifications, () => history.push(ProfileRoute));
          })
          .catch(() => {
            dispatch(apiCallEnd());
          });
        saveUserToLocalStorage();
      }
      if (levelCompleted === totalNumberOfLevels) {
        trackPageViewGoogle(gameCompleteModal, '/game/complete');
        if (requiresSaveToApi) {
          trackEvent('Game Interaction', 'Final Game Round Complete');
        }
      } else {
        trackPageViewGoogle(nextLevelModal, '/game/nextlevel');
        if (requiresSaveToApi) {
          trackEvent('Game Interaction', 'Game Level Complete', `'Level ${levelCompleted}`);
        }
      }
    }
  }, [showNextLevelModal]);

  const [showModal, setShowModal] = useState(true);

  const restartWithAutoStart = () => {
    dispatch(restartGame());
    setSecondsForNextLevel(levels[1].timeInSeconds);
    const startingConfig: SceneConfig = {
      level: 1,
      windowWidth,
      totalNumberOfLevels,
      autoStart: true,
    };
    gameRef.current?.scene.start('Game', startingConfig);
    setIsPaused(false);
    setShowModal(false);
  };

  return [config, formattedTime, showModal, setShowModal, setIsPaused, restartWithAutoStart];
};

export default useGame;
