import Phaser from 'phaser';

export interface Config extends Phaser.Types.Core.GameConfig {
  width: number;
  height: number;
}

const getConfig: (windowWidth: number) => Config = (windowWidth) => {
  const getPixelRatio = () => {
    if (windowWidth <= 767) {
      return window.devicePixelRatio || 2;
    }
    if (windowWidth < 1750) {
      return 2;
    }
    return 1;
  };

  const tilePadding = (windowWidth > 767 ? 8 : 4) * getPixelRatio();
  const maxColumns = windowWidth > 767 ? 8 : 6;

  function getCanvasWidth() {
    switch (true) {
      // Big Screens
      case windowWidth >= 1550:
        return 900;

      // Desktop
      case windowWidth >= 1280:
        return windowWidth - 648;
      // Tablets
      case windowWidth >= 768:
        return 630;

      // Tablets
      case windowWidth >= 450:
        return 400;
      // Small phones
      default:
        return windowWidth - 10;
    }
  }

  const tileSize = ((getCanvasWidth() - tilePadding * (maxColumns - 1)) / maxColumns) * getPixelRatio();

  return {
    width: getCanvasWidth() + tileSize * 2,
    height: 200,
    devicePixelRatio: getPixelRatio(),
    tileSize: tileSize,
    tilePadding: tilePadding,
    type: Phaser.AUTO,
    parent: 'phaser-game',
    scale: {
      mode: Phaser.Scale.NONE,
    },
    render: {
      transparent: true,
    },
    input: {
      touch: {
        capture: true,
      },
    },
  };
};

export default getConfig;
