import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ProfileDto } from '../types';
import FacebookButton from './Sharing/FacebookButton';
import PinterestButton from './Sharing/PinterestButton';
import TwitterButton from './Sharing/TwitterButton';

interface IShareProps {
  userData: ProfileDto;
}
const Share: React.FC<IShareProps> = ({ userData }) => {
  return (
    <Row className="share-container pl-0">
      <Col xs={12} className="pl-0">
        <span className="share-header-copy">Share Your Progress</span>
        <div className="">
          <TwitterButton {...userData} />
          <FacebookButton {...userData} />
          <PinterestButton {...userData} />
        </div>
      </Col>
    </Row>
  );
};
export default Share;
