/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { formatTime } from '../../helpers/index';
import { useSelector } from 'react-redux';
import RootState from '../../types/index';

const useCountdownTimer: (
  onTimeUp: () => void
) => [
  number,
  string,
  React.Dispatch<React.SetStateAction<number>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] = (onTimeUp) => {
  const [isPaused, setIsPaused] = useState<boolean>(true);

  const [secondsRemaining, setSecondsRemaining] = useState<number>(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const isManuallyPaused = useSelector((state: RootState) => state.game.isManuallyPaused);

  const tick = () => {
    if (!isPaused) {
      const newTime = secondsRemaining - 1;
      setSecondsRemaining(newTime);
      if (newTime === 0) {
        onTimeUp();
      }
    }
  };

  useEffect(() => {
    if (secondsRemaining > 0 && !isPaused) {
      timeoutRef.current = setTimeout(tick, 1000);
    }
  }, [secondsRemaining]);

  useEffect(() => {
    if (isPaused) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      tick();
    }
  }, [isPaused]);

  useEffect(() => {
    if (isManuallyPaused && !isPaused) {
      setIsPaused(true);
    }
  }, [isManuallyPaused]);

  return [secondsRemaining, formatTime(secondsRemaining), setSecondsRemaining, isPaused, setIsPaused];
};

export default useCountdownTimer;
