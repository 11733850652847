import { SweetAlertOptions } from 'sweetalert2';

const notificationTime = 8000;

export const notificationBaseOptions: SweetAlertOptions<any, any> = {
  backdrop: true,
  toast: true,
  position: 'top-end',
  timer: notificationTime,
  timerProgressBar: true,
  showConfirmButton: false,
};
