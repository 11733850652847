import React from 'react';

function IconTwitter() {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0535329 0L8.54748 11.0311L0 20H1.92385L9.40732 12.1475L15.4535 20H22L13.028 8.34848L20.984 0H19.0601L12.1685 7.23182L6.6 0H0.0535329ZM2.88263 1.37629H5.89005L19.1705 18.6237H16.1631L2.88263 1.37629Z"
        fill="white"
      />
    </svg>
  );
}

export default IconTwitter;
