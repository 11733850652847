import React from 'react';

const ArrowRight = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`${className} arrow-line-right`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.66749 12L21.3342 12" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.6675 18.6667L21.3342 12L14.6675 5.33337"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
