import { SetSweepstakes } from '../sweepstakes/actionTypes';
import { LoginAction, SetEntryCount } from '../users/actionTypes';

export const SETSCOREONLEVELCOMPLETE = 'SETSCOREONLEVELCOMPLETE';
export const SETSCORE = 'SETSCORE';
export const ADDTIMEBONUS = 'ADDTIMEBONUS';
export const STARTLEVEL = 'STARTLEVEL';
export const UPDATESHUFFLECOUNT = 'UPDATESHUFFLECOUNT';
export const UPDATEHINTCOUNT = 'UPDATEHINTCOUNT';
export const RESTARTGAME = 'RESTARTGAME';
export const SETNOPOSSIBLEMOVES = 'SETNOPOSSIBLEMOVES';
export const GAMEOVER = 'GAMEOVER';
export const TOGGLESOUND = 'TOGGLESOUND';
export const TOGGLEPAUSED = 'TOGGLEPAUSED';
export const LOADED = 'LOADED';
export const SHOWHELP = 'SHOWHELP';
export const SENTTOAPI = 'SENTTOAPI';
export const APICALLSTART = 'APICALLSTART';
export const APICALLEND = 'APICALLEND';

export interface SetScoreOnLevelCompleteAction {
  type: typeof SETSCOREONLEVELCOMPLETE;
  levelCompleted: number;
  levelScoreBeforeBonus: number;
  totalScoreBeforeBonus: number;
}

export interface SetScore {
  type: typeof SETSCORE;
  totalScore: number;
}

export interface AddTimeBonus {
  type: typeof ADDTIMEBONUS;
  secondsRemaining: number;
  completionTime: string;
  timeBonus: number;
}

export interface StartLevelAction {
  type: typeof STARTLEVEL;
}

export interface UpdateShuffleCountAction {
  type: typeof UPDATESHUFFLECOUNT;
  increment: boolean;
}

export interface UpdateHintCountAction {
  type: typeof UPDATEHINTCOUNT;
  increment: boolean;
}

export interface RestartGameAction {
  type: typeof RESTARTGAME;
}

export interface SetNoPossibleMovesAction {
  type: typeof SETNOPOSSIBLEMOVES;
}

export interface GameOverAction {
  type: typeof GAMEOVER;
  gameOverMessage: string;
}

export interface ToggleSoundAction {
  type: typeof TOGGLESOUND;
  on: boolean;
}

export interface TogglePausedAction {
  type: typeof TOGGLEPAUSED;
  paused: boolean;
}

export interface SetLoadedAction {
  type: typeof LOADED;
  loaded: boolean;
}

export interface ShowHelpAction {
  type: typeof SHOWHELP;
  showHelp: boolean;
}

export interface SentToApiAction {
  type: typeof SENTTOAPI;
}

export interface ApiCallStartAction {
  type: typeof APICALLSTART;
}

export interface ApiCallEndAction {
  type: typeof APICALLEND;
}

export type GameActionTypes =
  | SetScoreOnLevelCompleteAction
  | AddTimeBonus
  | StartLevelAction
  | LoginAction
  | SetScore
  | UpdateShuffleCountAction
  | UpdateHintCountAction
  | RestartGameAction
  | SetNoPossibleMovesAction
  | GameOverAction
  | ToggleSoundAction
  | TogglePausedAction
  | SetLoadedAction
  | ShowHelpAction
  | SentToApiAction
  | SetSweepstakes
  | SetEntryCount
  | ApiCallStartAction
  | ApiCallEndAction;
