import React from 'react';

function FacebookIcon() {
  return (
    <svg id="mono-icon-facebook" viewBox="0 0 10 19" width="10" height="19" preserveAspectRatio="xMidYMid meet">
      <path
        fillRule="evenodd"
        d="M2.707 18.25V10.2H0V7h2.707V4.469c0-1.336.375-2.373 1.125-3.112C4.582.62 5.578.25 6.82.25c1.008 0 1.828.047 2.461.14v2.848H7.594c-.633 0-1.067.14-1.301.422-.188.235-.281.61-.281 1.125V7H9l-.422 3.2H6.012v8.05H2.707z"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
