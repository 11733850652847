import './App.css';

import { ConnectedRouter } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import HallmarkFooter from './components/Hallmark/HallmarkFooter';
import HallmarkHeader from './components/Hallmark/HallmarkHeader';
import AnimatedBanner from './components/Ads/AnimatedBanner';
import FixedAd from './components/Ads/FixedAd';
import { NavBar } from './components/Layout';
import Disclaimer from './components/Layout/Disclaimer';
import { shouldDisplayL } from './infrastructure/freewheel/breakpoints';
import { history } from './infrastructure/redux/configureStore';
import { isIE } from 'react-device-detect';
import RootState, { UserDto, EntryCountDto, SweepsDetailsDto } from './types';
import Routes from './infrastructure/routes/routes';
import callApi from './infrastructure/api/CallApi';
import { localStorageKey, localStorageModalViewedKey } from './constants';
import { login, viewedModal, setEntryCount } from './slices/users/actions';
import { setSweepstakes } from './slices/sweepstakes/actions';
import { addIsWebview } from './infrastructure/isWebView/actions';
import Loader from './components/Loader';
import PageContainer from './components/Layout/PageContainer';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';
import { getUrlParams, saveToSessionStorage, getFromSessionStorage } from './helpers/index';
import { setInviteCode } from './slices/sharing/actions';
import { GameConfigDto } from './types/index';
import { setConfig } from './slices/gameconfig/actions';
import { selectIsCurrentSweepstake } from './slices/sweepstakes/selectors';
import NavBarMobile from 'components/Layout/NavBar/NavBarMobile';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // eslint-disable-next-line
  const [showErrorView, setShowErrorView] = useState(false);
  const dispatch = useDispatch();
  const { webView: isWebView } = useSelector((state: RootState) => state.isWebView);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  const checkUserData = () => {
    try {
      const userData = localStorage.getItem(localStorageKey);
      const configPromise = callApi<GameConfigDto>('api/app/game-config/game-config', 'GET').then(({ data }) => {
        dispatch(setConfig(data));
      });
      const promises: Array<Promise<void>> = [configPromise];
      if (userData) {
        const userDto = JSON.parse(userData) as UserDto;
        dispatch(login(userDto));
        const entryPromise = callApi<EntryCountDto>(
          `api/app/form-entry/enter-existing-user/${userDto.id}`,
          'POST'
        ).then(({ data }) => {
          dispatch(setEntryCount(data));
        });
        promises.push(entryPromise);
      } else {
        const sweepstakesPromise = callApi<SweepsDetailsDto>(
          `api/app/sweepstake-countdown/current-sweepstake-status/`,
          'GET'
        ).then(({ data }) => {
          dispatch(setSweepstakes(data));
        });
        promises.push(sweepstakesPromise);
      }
      Promise.all(promises)
        .catch(() => {
          setShowErrorView(true);
          try {
            localStorage.removeItem(localStorageKey);
          } catch {}
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.location.href.toLowerCase().includes('hmc=1')) {
      dispatch(addIsWebview(true));
    }
    const shareCode = getUrlParams('sharecode', window.location.search.toLowerCase()) || getFromSessionStorage();
    if (shareCode) {
      saveToSessionStorage(shareCode);
      dispatch(setInviteCode(shareCode));
    }
    checkUserData();

    try {
      const modalViewed = localStorage.getItem(localStorageModalViewedKey);
      if (modalViewed) {
        dispatch(viewedModal());
      }
    } catch {}

    const ieClass = isIE ? 'isIE' : 'notIE';
    document.body.classList.add(ieClass);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <ConnectedRouter history={history}>
      <div id="content-wrap" className="theme-christmas">
        <HallmarkHeader isWebView={isWebView} />
        <PageContainer>
          {showErrorView ? (
            <React.Fragment>
              <div className="error">
                <Container>
                  <h5>Something went wrong! Please try again.</h5>
                  <Button onClick={() => window.location.reload(false)} variant="primary" size="lg">
                    Reload
                  </Button>
                </Container>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavBar />
              <NavBarMobile />
              <FixedAd shouldDisplayL={shouldDisplayL} />
              <div className="routes-container">
                <Routes />
              </div>
            </React.Fragment>
          )}
          {isCurrentSweepstake && <Disclaimer />}
        </PageContainer>
      </div>
      <AnimatedBanner />
      <div id="footer">
        <HallmarkFooter shouldDisplay={!shouldDisplayL} isWebView={isWebView} />
      </div>
    </ConnectedRouter>
  );
};

export default App;
