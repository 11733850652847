/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import Button from './Layout/Button';
import Loader from './Loader';
import CallApi from '../infrastructure/api/CallApi';
import usePageView from '../infrastructure/hooks/usePageView';
import { leaderboardWeeklyPage, leaderboardOverallPage, leaderboardGroupPage } from '../types/pageNameContants';
import { trackEvent, trackPageView } from '../infrastructure/tracking/GoogleAnalytics';
import RootState, { ILeaderboardItem } from '../types/index';
import classNames from 'classnames';
import getProfileImage from '../helpers/GetProfileImage';
import { GroupDetailPageRoute, UrlNameKeyWithColon } from 'constants/Routes';
import { iconGroupMembers } from '../images/icons';

type LeaderboardType = 'overall' | 'weekly' | 'group';

const apiPaths: Map<LeaderboardType, string> = new Map<LeaderboardType, string>([
  ['overall', ''],
  ['weekly', 'weekly-'],
  ['group', 'group-'],
]);
const LeaderboardPage = () => {
  const history = useHistory();
  const [leaderboardItems, setLeaderboardItems] = useState<Array<ILeaderboardItem>>([]);
  const [activeLeaderboard, setActiveLeaderboard] = useState<'overall' | 'weekly' | 'group'>('weekly');
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const [userGroupCodes, setUserGroupCodes] = useState<Array<string>>([]);
  const initialPageViewTrackedRef = useRef<boolean>(false);

  usePageView(leaderboardWeeklyPage, '/leaderboard');

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user?.id) {
      CallApi<Array<string>>(`api/app/group/user-group-invite-codes/${user.id}`, 'GET').then(({ data }) => {
        setUserGroupCodes(data);
      });
    }
  }, [user]);

  useEffect(() => {
    setIsDisabled(true);
    CallApi<Array<ILeaderboardItem>>(
      `api/app/leaderboard/${apiPaths.get(activeLeaderboard)}leaderboard/${
        activeLeaderboard !== 'group' ? user.id : ''
      }`,
      'GET'
    )
      .then(({ data }) => {
        setLeaderboardItems(data);
        setIsLoading(false);
        setIsDisabled(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });

    const pageName =
      activeLeaderboard === 'group'
        ? leaderboardGroupPage
        : activeLeaderboard === 'overall'
        ? leaderboardOverallPage
        : leaderboardWeeklyPage;
    trackPageView(pageName, '/leaderboard', initialPageViewTrackedRef.current);

    initialPageViewTrackedRef.current = true;
  }, [activeLeaderboard]);

  return (
    <div className="page page-leaderboard">
      <Container className="leaderboard-container">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="leaderboard-header">
              <div className="leaderboard-header_button">
                <div className="group-buttons d-flex">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    iconBefore
                    className={classNames('leaderboard', { active: activeLeaderboard !== 'group' }, 'weekly')}
                    onClick={() => {
                      if (activeLeaderboard === 'group') {
                        setActiveLeaderboard('weekly');
                        trackEvent('Individual', 'Individual Selected', 'Leaderboard');
                      }
                    }}
                  >
                    Individual
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    iconBefore
                    className={classNames({ active: activeLeaderboard === 'group' }, 'overall')}
                    onClick={() => {
                      if (activeLeaderboard !== 'group') {
                        setActiveLeaderboard('group');
                        trackEvent('Group', 'Group Selected', 'Leaderboard');
                      }
                    }}
                  >
                    Group
                  </Button>
                </div>
                <div className="button-group d-flex d-lg-none">
                  {activeLeaderboard !== 'group' && (
                    <>
                      <Button
                        variant="outline-primary white"
                        size="sm"
                        className={classNames({ active: activeLeaderboard === 'weekly' }, 'weekly')}
                        onClick={() => {
                          setActiveLeaderboard('weekly');
                          trackEvent('Weekly', 'Weekly Selected', 'Leaderboard');
                        }}
                        disabled={isDisabled}
                      >
                        Weekly
                      </Button>
                      <Button
                        variant="outline-primary white"
                        size="sm"
                        className={classNames({ active: activeLeaderboard !== 'weekly' }, 'overall')}
                        onClick={() => {
                          setActiveLeaderboard('overall');
                          trackEvent('Overall', 'Overall Selected', 'Leaderboard');
                        }}
                        disabled={isDisabled}
                      >
                        Overall
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className="leaderboard-header_header">
                <h1>Leaderboard</h1>
              </div>

              <div className="leaderboard-header_toggle d-none d-lg-inline">
                <div className="button-group">
                  {activeLeaderboard !== 'group' && (
                    <>
                      <Button
                        variant="outline-primary white"
                        size="sm"
                        className={classNames('leaderboard', { active: activeLeaderboard === 'weekly' }, 'weekly')}
                        onClick={() => {
                          setActiveLeaderboard('weekly');
                          trackEvent('Weekly', 'Weekly Selected', 'Leaderboard');
                        }}
                        disabled={isDisabled}
                      >
                        Weekly
                      </Button>
                      <Button
                        variant="outline-primary white"
                        size="sm"
                        className={classNames('leaderboard', { active: activeLeaderboard !== 'weekly' }, 'overall')}
                        onClick={() => {
                          setActiveLeaderboard('overall');
                          trackEvent('Overall', 'Overall Selected', 'Leaderboard');
                        }}
                        disabled={isDisabled}
                      >
                        Overall
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            {isError && (
              <div style={{ textAlign: 'center' }}>
                <h4>Something went wrong. Please try again.</h4>
              </div>
            )}
            {leaderboardItems && (
              <table className="leaderboard-table">
                <thead>
                  <tr>
                    <th className="leaderboard-table_user-rank">
                      <p className="bold link">Rank</p>
                    </th>
                    <th>
                      <p className="bold link leaderboard-table_name">Name</p>
                    </th>

                    <th className="header-group-member">
                      {activeLeaderboard !== 'group' && (
                        <>
                          <p className="d-none d-sm-block">Group Member</p>
                          <p className="d-sm-none"></p>
                        </>
                      )}
                    </th>

                    <th className="header-points">
                      <p className="bold link">Points</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leaderboardItems.map((userScore) => (
                    <tr
                      onClick={() => {
                        const redirectTo = userScore.inviteCode
                          ? GroupDetailPageRoute.replace(UrlNameKeyWithColon, userScore.inviteCode)
                          : null;
                        if (redirectTo) {
                          history.push(redirectTo);
                        }
                      }}
                    >
                      <td className="leaderboard-table_user-rank">
                        <p className="bold">{userScore.rank}</p>
                      </td>
                      <td className="profile-info">
                        {userScore.profileImage !== undefined ? (
                          <Image
                            src={getProfileImage(userScore.profileImage)}
                            alt="Profile"
                            className="img-fluid leaderboard-image"
                          />
                        ) : null}
                        <p>{userScore.name}</p>
                      </td>
                      <td className="points-total">
                        {userScore.isGroupMember && activeLeaderboard !== 'group' ? (
                          <Image src={iconGroupMembers} alt="Profile" className="img-fluid leaderboard-image" />
                        ) : null}
                      </td>
                      <td className="points-total">
                        <p>{userScore.score}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default LeaderboardPage;
