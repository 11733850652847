import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState, { UserDto } from '../../../types/index';
import { selectIsCurrentSweepstake } from '../../../slices/sweepstakes/selectors';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../Layout/Button';
import { trackEvent } from '../../../infrastructure/tracking/GoogleAnalytics';
import { FormFeedback } from 'reactstrap';
import useLinkTarget from '../../../infrastructure/isWebView/useLinkTarget';
import { useState } from 'react';
import callApi from '../../../infrastructure/api/CallApi';
import { localStorageKey } from '../../../constants';
import { login } from '../../../slices/users/actions';
import Swal from 'sweetalert2';

const apiUrl = 'api/app/user/agree-to-rules';

const onEnterClick = (userId: string) =>
  trackEvent('Enter Now ', 'Enter Now Selected', userId ? 'Logged In User' : 'Logged Out User');

const handleError = (response: any) => {
  let alertDescription = 'Something went wrong! Please try again.';
  if (response && response.data && response.data.error && response.data.error.code === 'CustomError') {
    alertDescription = response.data.error.message;
  }
  Swal.fire({
    title: 'Oops!',
    text: alertDescription,
    backdrop: false,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
};

const LandingPageEnterButon: React.FC = () => {
  const { id: userId, acceptedRules } = useSelector((state: RootState) => state.user);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);
  const linkTarget = useLinkTarget();
  const dispatch = useDispatch();
  const history = useHistory();
  const [formSubmissionAttempted, setFormSubmissionAttempted] = useState<boolean>(false);
  const [acceptedRulesChecked, setAcceptedRulesChecked] = useState<boolean>(false);
  const [ageConfirmationChecked, setAgeConfirmationChecked] = useState<boolean>(false);
  const [acceptedSponsorChecked, setAcceptedSponsorChecked] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onRulesSubmit = () => {
    setFormSubmissionAttempted(true);
    if (acceptedRulesChecked && ageConfirmationChecked) {
      setIsSubmitting(true);
      callApi<UserDto>(apiUrl, 'POST', {
        id: userId,
        rules: acceptedRulesChecked,
        cruiseSponsorOptIn: acceptedSponsorChecked,
      })
        .then(({ data }) => {
          dispatch(login(data));
          try {
            localStorage.setItem(localStorageKey, JSON.stringify(data));
          } catch {}
          history.push('/game');
        })
        .catch(({ response }: any) => {
          handleError(response);
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  // Show if
  // - there's no sweeps to agree to rules for
  // - no user is signed in
  // - user is signed in but has agreed to rules
  if (!isCurrentSweepstake || !userId || acceptedRules) {
    const linkTo = userId ? '/game' : '/signup';
    return (
      <Link to={linkTo} className="landing-enter-now-signed-in">
        <Button variant="primary" size="lg" withArrow onClick={() => onEnterClick(userId)}>
          <span>Enter now!</span>
        </Button>
      </Link>
    );
  }

  return (
    <>
      <Button
        className="landing-enter-now-signed-out"
        variant="primary"
        size="lg"
        withArrow
        onClick={onRulesSubmit}
        disabled={isSubmitting}
      >
        <span>Enter now!</span>
      </Button>
      <div className="landing-header__checkboxes">
        <label htmlFor="ageConfirmation" className="checkbox-container landing">
          <input
            name="ageConfirmation"
            type="checkbox"
            id="ageConfirmation"
            checked={ageConfirmationChecked}
            onChange={(e) => setAgeConfirmationChecked(e.target.checked)}
          />
          <span className="checkmark" />
          <p style={{ textAlign: 'left' }}>
            I am at least 21 years old or the age of majority in my place of residence.
            <span className="red">*</span>
          </p>
          {formSubmissionAttempted && !ageConfirmationChecked && (
            <FormFeedback tooltip valid={false}>
              Please check this box to continue.
            </FormFeedback>
          )}
        </label>
        <label htmlFor="acceptedRules" className="checkbox-container landing">
          <input
            name="acceptedRules"
            type="checkbox"
            id="acceptedRules"
            checked={acceptedRulesChecked}
            onChange={(e) => setAcceptedRulesChecked(e.target.checked)}
          />
          <span className="checkmark" />
          <p style={{ textAlign: 'left' }}>
            I agree to the Sweepstakes{' '}
            <a href="/rules" target={linkTarget} rel="noopener noreferrer">
              Official Rules
            </a>
            , as well as Hallmark Media's{' '}
            <a
              href="https://www.hallmarkchannel.com/crown-media-family-networks-terms-of-use/?utm_source=matching_momements&utm_medium=entry_form&utm_campaign=loveuary_sweepstakes"
              target={linkTarget}
              rel="noopener noreferrer"
            >
              Terms&nbsp;of&nbsp;Use
            </a>{' '}
            and{' '}
            <a
              href="https://www.hallmarkchannel.com/privacy-policy/?utm_source=matching_momements&utm_medium=entry_form&utm_campaign=loveuary_sweepstakes"
              target={linkTarget}
              rel="noopener noreferrer"
            >
              Privacy&nbsp;Policy
            </a>
            .<span className="red">*</span>
          </p>
          {formSubmissionAttempted && !acceptedRulesChecked && (
            <FormFeedback tooltip valid={false}>
              Please check this box to continue.
            </FormFeedback>
          )}
        </label>
        <label htmlFor="cruiseSponsorOptIn" className="checkbox-container landing">
          <input
            name="cruiseSponsorOptIn"
            type="checkbox"
            id="cruiseSponsorOptIn"
            checked={acceptedSponsorChecked}
            onChange={(e) => setAcceptedSponsorChecked(e.target.checked)}
          />
          <span className="checkmark" />
          <p style={{ textAlign: 'left' }}>
            I would like to receive marketing and promotional email messages from{' '}
            <a
              href="https://www.princess.com?utm_source=matching_momements&utm_campaign=loveuary_sweepstakes"
              rel="noreferrer noopener"
              target="_blank"
              onClick={() => trackEvent('Princess Cruises Opt In', 'Princess Cruises Click', 'Form')}
            >
              Princess Cruise Lines, Ltd
            </a>
            . (not required for Sweepstakes entry).
          </p>
        </label>
      </div>
    </>
  );
};

export default LandingPageEnterButon;
