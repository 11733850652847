import React from 'react';
import { Image } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { getLimitedEditionBadgeUrlFromKey } from '../../types/UserProfile';

interface IGameModalBadgeBodyProps {
  badgeDescription: string;
  badgeKey: string;
}

const GameModalBadgeBody: React.FC<IGameModalBadgeBodyProps> = ({ badgeDescription, badgeKey }) => (
  <>
    <Modal.Header>
      <h3>New Badge Available!</h3>
      <h5>{badgeDescription}</h5>
    </Modal.Header>
    <Modal.Body className="modal-new-badge">
      <Image src={getLimitedEditionBadgeUrlFromKey(badgeKey)} alt="" className="modal-newbadge-image img-fluid" />
    </Modal.Body>
  </>
);

export default GameModalBadgeBody;
