import { coord } from '../types';

const getRadians = (degrees: number) => degrees * (Math.PI / 180);

export const hideLines = (lines: Phaser.GameObjects.Group) => {
  let child = lines.getChildren() as Array<Phaser.GameObjects.Sprite>;
  let total = lines.getLength();
  for (let i = 0; i < total; i++) {
    child[i].setVisible(false);
  }
};

export const showLines = (
  route: Array<coord>,
  lines: Phaser.GameObjects.Group,
  startX: number,
  startY: number,
  size: any,
  tilePadding: number,
  playSound: () => void
) => {
  playSound();
  hideLines(lines);
  let line = lines.getChildren();
  let prev;
  let cur;
  for (let i = 0; i < route.length; i++) {
    let p = line[i] as Phaser.GameObjects.Sprite;
    p.setVisible(true);
    p.setPosition(
      startX + size.width * route[i].x + tilePadding * route[i].x,
      startY + size.height * route[i].y + tilePadding * route[i].y
    );
    p.displayWidth = size.width + tilePadding;
    p.displayHeight = size.height + tilePadding;

    if (i < route.length - 1) {
      //Next
      //
    }
    if (i === 0) {
      //First
      if (route[i].x === route[i + 1].x) {
        if (route[i].y > route[i + 1].y) {
          cur = 'up';
        } else {
          cur = 'down';
        }
      } else {
        if (route[i].x > route[i + 1].x) {
          cur = 'left';
        } else {
          cur = 'right';
        }
      }
    } else if (i < route.length - 1) {
      //Lines
      if (route[i].x === route[i + 1].x) {
        if (route[i].y > route[i + 1].y) {
          //UP
          cur = 'up';
          if (prev === 'up') {
            p.setFrame(1);
          } else if (prev === 'down') {
            //cur = 'down';
          } else if (prev === 'left') {
            p.setFrame(2);
            p.setRotation(getRadians(180));
          } else if (prev === 'right') {
            p.setFrame(2);
            p.setRotation(getRadians(90));
          }
        } else {
          //DOWN
          cur = 'down';
          if (prev === 'up') {
            //p.setFrame(1);
          } else if (prev === 'down') {
            p.setFrame(1);
            //cur = 'down';
          } else if (prev === 'left') {
            p.setFrame(2);
            p.setRotation(getRadians(270));
          } else if (prev === 'right') {
            p.setFrame(2);
            p.setRotation(getRadians(0));
          }
        }
      } else {
        if (route[i].x > route[i + 1].x) {
          //LEFT
          cur = 'left';
          if (prev === 'up') {
            p.setFrame(2);
            p.setRotation(getRadians(0));
          } else if (prev === 'down') {
            p.setFrame(2);
            p.setRotation(getRadians(90));
          } else if (prev === 'left') {
            p.setFrame(1);
          } else if (prev === 'right') {
            //p.setFrame(1);
          }
        } else {
          //RIGHT
          cur = 'right';
          if (prev === 'up') {
            p.setFrame(2);
            p.setRotation(getRadians(270));
          } else if (prev === 'down') {
            p.setFrame(2);
            p.setRotation(getRadians(180));
          } else if (prev === 'left') {
            //p.setFrame(1);
          } else if (prev === 'right') {
            p.setFrame(1);
          }
        }
      }
    }
    if (i === route.length - 1) {
      //Last
      if (route[i].x === route[i - 1].x) {
        if (route[i].y > route[i - 1].y) {
          cur = 'up';
        } else {
          cur = 'down';
        }
      } else {
        if (route[i].x > route[i - 1].x) {
          cur = 'left';
        } else {
          cur = 'right';
        }
      }
      p.setFrame(0);
    }
    //Set rotation
    // @ts-ignore
    if (p.frame.name === 0) {
      if (cur === 'up') {
        p.setRotation(getRadians(270));
      } else if (cur === 'down') {
        p.setRotation(getRadians(90));
      } else if (cur === 'left') {
        p.setRotation(getRadians(180));
      } else if (cur === 'right') {
        p.setRotation(getRadians(0));
      }
      // @ts-ignore
    } else if (p.frame.name === 1) {
      if (cur === 'up' || cur === 'down') {
        p.setRotation(getRadians(90));
      } else {
        p.setRotation(getRadians(0));
      }
    }
    prev = cur;
  }
};
