import GroupInviteState from './GroupInviteState';
import { GroupInviteActionTypes, SETCODE, CLEARCODE } from './actionTypes';

const initialState: GroupInviteState = {
  code: '',
};

const groupInviteReducer: (state: GroupInviteState, action: GroupInviteActionTypes) => GroupInviteState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SETCODE:
      const newState: GroupInviteState = {
        code: action.code,
      };
      return newState;
    case CLEARCODE:
      return initialState;
    default:
      return state;
  }
};

export default groupInviteReducer;
