import React from 'react';
import { Row } from 'react-bootstrap';
import { Image } from 'react-bootstrap';

import { StandardBadgeImageDictionary, StandardBadgeDetailsDto } from '../../types/UserProfile';

interface IStandardBadgesProps {
  badges: Array<StandardBadgeDetailsDto>;
}
const StandardBadges: React.FC<IStandardBadgesProps> = ({ badges }) => {
  return (
    <div className="profile-badges">
      <h2 className="profile-sectionheader">Badges</h2>
      <Row>
        {badges.map((badge) => (
          <React.Fragment key={badge.key}>
            <div className="badge-container">
              <Image
                src={StandardBadgeImageDictionary[badge.key]}
                alt=""
                className="img-fluid"
                style={{ opacity: badge.hasEarned ? '100%' : '20%' }}
              />
              <p>{badge.description}</p>
            </div>
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
};

export default StandardBadges;
