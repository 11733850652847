import React from 'react';
import IconFacebook from '../../images/social/footer_social_facebook';
import IconInstagram from '../../images/social/footer_social_instagram';
import IconPinterest from '../../images/social/footer_social_pinterest';
import IconTwitter from '../../images/social/footer_social_twitter';
import IconYoutube from '../../images/social/footer_social_youtube';
import HallmarkChannelLogo from '../../images/HallmarkChannelLogo.svg';
import FooterAd from '../Ads/FooterAd';

const renderIcons = {
  facebook: IconFacebook,
  twitter: IconTwitter,
  instagram: IconInstagram,
  pinterest: IconPinterest,
  youtube: IconYoutube,
};

function SocialIcon({ link, name, isWebView }) {
  const Icon = renderIcons[name];

  return (
    <a
      className="SocialLink media-socialLinks-item-link"
      rel="noreferrer"
      href={link}
      target={isWebView ? '' : '_blank'}
      data-social-service={name}
    >
      <Icon />
      <span className="sr-only">{name}</span>
    </a>
  );
}

const HallmarkFooter = ({ shouldDisplay, isWebView }) => {
  return (
    <>
      <footer className="Page-footer">
        <div className="Page-footer-Ad">
          <FooterAd shouldDisplay={shouldDisplay} classNames="banner-footer" />
        </div>

        <div className="Page-footer-container">
          <div className="Page-footer-containerTop">
            <div className="Page-footer-logo">
              <a aria-label="home page" href="https://www.hallmarkchannel.com/">
                <img className="PageLogo-image" src={HallmarkChannelLogo} alt="Hallmark Channel Logo" />
              </a>
            </div>

            <div className="Page-footer-social">
              <div className="SocialBar">
                <div className="SocialBar-heading">Follow Us</div>

                <ul className="SocialBar-items">
                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://twitter.com/hallmarkchannel" name="twitter" isWebView={isWebView} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon
                      link="https://www.instagram.com/hallmarkchannel/"
                      name="instagram"
                      isWebView={isWebView}
                    />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon
                      link="https://www.youtube.com/hallmarkchannelusa"
                      name="youtube"
                      isWebView={isWebView}
                    />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon
                      link="https://www.pinterest.com/hallmarkchannel"
                      name="pinterest"
                      isWebView={isWebView}
                    />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.facebook.com/hallmarkchannel" name="facebook" isWebView={isWebView} />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="Page-footer-containerBottom">
            <div className="Page-footer-navigation">
              <nav className="FooterNavigation" aria-label="footer-navigation">
                <ul className="FooterNavigation-items">
                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a className="FooterNavigationItem-text-link" href="https://www.hallmarkmystery.com/">
                          Hallmark Mystery
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a className="FooterNavigationItem-text-link" href="https://www.hallmarkfamily.com/">
                          Hallmark Family
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkplus.com"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Hallmark+
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/about-us"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          About Us
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/contact-us"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/faq"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          FAQ
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://corporate.crownmedia.com/#/careers"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Careers
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/advertising"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Advertising
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.crownmediainternational.com/"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          International
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://corporate.crownmedia.com/"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Corporate
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://www.hallmarkchannelpress.com/?SiteID=142&amp;NodeID=144"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Press
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/channel-locator"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Channel Locator
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/newsletter"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Newsletter
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmark.com/privacy"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmark.com/terms-of-use"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Terms of Use
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmark.com/privacy-notice"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          CA Privacy Notice
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link d-flex align-items-center"
                          href="https://care.hallmark.com/s/privacy-form"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://1240369105.rsc.cdn77.org/matchingmoments/staticassets/build/ccpa-privacy-options-icon.png"
                            width="27"
                            height="24"
                            alt=""
                            className="h-auto mr-1 position-relative"
                            style={{ top: '-1px' }}
                          />
                          <span>Your Privacy Choices</span>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings FooterNavigationItem-text-link">
                          Cookie Preferences
                        </button>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://www.hallmark.com/"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Hallmark Cards
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/accessibility-notice"
                          target={isWebView ? '' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          Accessibility
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="Page-footer-disclaimer">
              <div className="cms-textAlign-center">Copyright © 2024 Hallmark Media, all rights reserved</div>
            </div>

            <div className="Page-footer-social">
              <div className="SocialBar">
                <div className="SocialBar-heading">Follow Us</div>

                <ul className="SocialBar-items">
                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://twitter.com/hallmarkchannel" name="twitter" isWebView={isWebView} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon
                      link="https://www.instagram.com/hallmarkchannel/"
                      name="instagram"
                      isWebView={isWebView}
                    />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon
                      link="https://www.youtube.com/hallmarkchannelusa"
                      name="youtube"
                      isWebView={isWebView}
                    />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon
                      link="https://www.pinterest.com/hallmarkchannel"
                      name="pinterest"
                      isWebView={isWebView}
                    />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.facebook.com/hallmarkchannel" name="facebook" isWebView={isWebView} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HallmarkFooter;
