import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import callApi from 'infrastructure/api/CallApi';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'types';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { FormRoute, ProfileRoute, UrlNameKey } from 'constants/Routes';
import Swal from 'sweetalert2';
import { GroupIndexRoute } from '../constants/Routes';
import { clearCode, setCode } from 'slices/groupinvite/actions';
import { IGroupBaseModel } from 'types/Groups';
import { badgeNotification } from 'helpers/BadgeNotification';

const GroupInvitePage = () => {
  // usePageView(PageData.GroupInvitePageData);
  const history = useHistory();
  const dispatch = useDispatch();
  const inviteCode = useParams<{ [UrlNameKey]: string }>()[UrlNameKey]!;
  const [inviteProcessing, setInviteProcessing] = useState<boolean>(true);
  const { id: userId } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (userId) {
      callApi<IGroupBaseModel>('api/app/group/join', 'POST', {
        inviteCode,
        userId,
      })
        .then(({ data }) => {
          dispatch(clearCode());
          setInviteProcessing(false);
          if (data.awardedBadges.length > 0) {
            // notify user of new badge
            badgeNotification(data.awardedBadges, [], () => history.push(ProfileRoute));
          }
        })
        .catch((e) => {
          handleError(e);
        });
    } else {
      dispatch(setCode(inviteCode));
      setInviteProcessing(false);
    }
  }, []);

  const handleError = (error: any) => {
    const response = error.response;
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.code === 'CustomError'
    ) {
      Swal.fire({
        title: 'Oops!',
        text: response.data.error.message,
        backdrop: false,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
    history.push(GroupIndexRoute);
  };

  if (inviteProcessing) {
    return <Loader />;
  }

  return <Redirect to={userId ? GroupIndexRoute : FormRoute} />;
};

export default GroupInvitePage;
