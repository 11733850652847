import Game from '../Scenes/Game';
import GetConfig from '../../helpers/GetConfig';
import store from '../../infrastructure/redux/configureStore';
import RootState from '../../types';

const preload = (game: Game) => {
  const { gameAssetUrl } = GetConfig();

  const { tiles } = (store.getState() as RootState).gameConfig;
  game.load.image('sign', `${gameAssetUrl}sign.png`);
  game.load.spritesheet('lines', `${gameAssetUrl}linesyellow.png`, { frameWidth: 108, frameHeight: 108 });
  for (let i = 0; i < tiles.length; i++) {
    game.load.image(`obj${i + 1}`, tiles[i].url);
  }
  game.load.audio('click', `${gameAssetUrl}click.mp3`);
  game.load.audio('connected', `${gameAssetUrl}connected.mp3`);
  game.load.audio('itemclick', `${gameAssetUrl}itemclick.mp3`);
  game.load.audio('gameover', `${gameAssetUrl}gameover.mp3`);
  game.load.audio('nomatch', `${gameAssetUrl}nomatch.mp3`);
  game.load.audio('completed', `${gameAssetUrl}completed.mp3`);
  game.load.audio('hint', `${gameAssetUrl}hint.mp3`);
  game.load.audio('shuffle', `${gameAssetUrl}shuffle.mp3`);
};

export default preload;
