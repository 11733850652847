import React from 'react';
import Button from 'components/Layout/Button';

interface IPaginationButtonProps {
  isSelected: boolean;
  pageNumber: number;
  index: number;
  onClick: () => void;
}
const PaginationButton: React.FC<IPaginationButtonProps> = ({ isSelected, pageNumber, index, onClick }) => {
  return (
    <li className="page-item number">
      <Button
        key={`li${pageNumber}${index}`}
        onClick={onClick}
        className={`${isSelected ? 'active' : ''} pagination page-link`}
      >
        <p>{pageNumber}</p>
      </Button>
    </li>
  );
};

export default PaginationButton;
