import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { Modal } from 'react-bootstrap';
import Button from '../Layout/Button';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import ModalTuneIn from './ModalTuneIn';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import levels from '../../game/Helpers/levels';
import useWindowDimensions from '../../infrastructure/hooks/useWindowDimensions';
import { numberWithCommas } from '../../helpers/index';
import { selectPromotedItem } from '../../slices/promotion/selectors';
import timeIcon from '../../images/game/time.svg';
import scoreIcon from '../../images/game/points.svg';

interface NextLevelModalProps {
  show: boolean;
  hideModal: () => void;
}

const NextLevelModal: React.FC<NextLevelModalProps> = ({ show, hideModal }) => {
  const [showTotalScore, setShowTotalScore] = useState<boolean>(false);
  const {
    totalScore: score,
    totalScoreBeforeBonus,
    completionTime: time,
    levelCompleted,
    loading,
  } = useSelector((state: RootState) => state.game);
  const { awardEntry } = useSelector(selectPromotedItem);
  const { hasVideoEntryAwarded } = useSelector((state: RootState) => state.user);
  const completionMessage = levels[levelCompleted]?.levelCompleteMessage;
  const { windowWidth } = useWindowDimensions();
  const [videoEntryAwarded, setVideoEntryAwarded] = useState<boolean>(false);
  useEffect(() => {
    setVideoEntryAwarded(hasVideoEntryAwarded);

    if (!show || windowWidth < 768) {
      setShowTotalScore(false);
    }
    const timer = setTimeout(() => setShowTotalScore(true), 1800);

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => hideModal()}
      className="level-modal"
    >
      <Modal.Header>
        <h3>Level Cleared!</h3>

        <div className="modal-time-score-container">
          <div className="modal-time-score">
            <h4 className="modal-time-score__title">COMPLETION TIME</h4>
            <div className="modal-time-score__content">
              <img src={timeIcon} alt="Time" className="red-blur" />
              <span className="modal-time-score__value">{time}</span>
            </div>
          </div>

          <div className="modal-time-score">
            <h4 className="modal-time-score__title">TOTAL SCORE</h4>
            <div className="modal-time-score__content score-table_total-score">
              <img src={scoreIcon} alt="Time" className="red-blur" />
              <span className="modal-time-score__value">
                <span className="">
                  {/* {show && showTotalScore && ( */}
                  <CountUp end={score} start={totalScoreBeforeBonus} duration={2} delay={0} separator="," />
                  {/* )} */}
                </span>
                {/* {show && ( */}
                <div className="time-bonus">
                  <p>+{numberWithCommas(score - totalScoreBeforeBonus)}</p>
                </div>
                {/* )} */}
              </span>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {(!awardEntry || videoEntryAwarded) && completionMessage && <p>{completionMessage}</p>}
        {awardEntry && !videoEntryAwarded && (
          <p>
            Watch this movie preview to earn an additional entry <br />
            into the Grand Prize and Weekly Prize this week!
          </p>
        )}
        <Button
          variant="primary"
          size="lg"
          withArrow
          onClick={() => {
            trackEvent('Go To Next Level', 'Next Level Selected', 'Next Level Modal');
            hideModal();
          }}
          disabled={loading}
        >
          Next Level
        </Button>
      </Modal.Body>
      <ModalTuneIn label="Next Level Modal" />
    </Modal>
  );
};

export default NextLevelModal;
