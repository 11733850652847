import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import { selectIsCurrentSweepstake } from '../../slices/sweepstakes/selectors';

interface SecuredRouteProps extends RouteProps {
  component: any;
}

const SecuredRoute: React.FC<SecuredRouteProps> = ({ component: Component, path }) => {
  const { id: userId, acceptedRules } = useSelector((state: RootState) => state.user);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  return (
    <Route
      path={path}
      render={(props) => {
        // If logged in and no sweeps or user has accepted sweeps rules - continue to game component
        if (userId && (!isCurrentSweepstake || acceptedRules)) {
          return <Component {...props} />;
        }
        // If no user logged in return to sign up page
        // Otherwise return to landing page where the checkbox will be displayed
        return <Redirect to={!userId ? '/signup' : '/'} />;
      }}
    />
  );
};

export default SecuredRoute;
