import { LimitedEditionBadgeDto } from '../../types/UserProfile';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SEENMODAL = 'SEENMODAL';
export const SETENTRYCOUNT = 'SETENTRYCOUNT';
export const SETHIGHSCORE = 'SETHIGHSCORE';
export const SETPROFILEIMAGE = 'SETPROFILEIMAGE';

export interface LoginAction {
  type: typeof LOGIN;
  id: string;
  emailAddress: string;
  name: string;
  entryCount: number;
  grandPrizeCount: number;
  lastLevelPlayed: number;
  currentScore: number;
  highScore: number;
  shareCode: string;
  shufflesRemaining?: number;
  hintsRemaining?: number;
  newFormEntryAdded: boolean;
  currentSweepstakeEnd: string | null;
  currentSweepstakeName: string | null;
  nextSweepstakeName: string | null;
  nextSweepstakeStart: string | null;
  acceptedRules: boolean;
  totalSecondsRemaining: number;
  badgeCount: number;
  isNew: boolean;
  profileImage: number;
  hasVideoEntryAwarded: boolean;
}

export interface SetEntryCount {
  type: typeof SETENTRYCOUNT;
  entryCount: number;
  grandPrizeCount: number;
  newFormEntryAdded: boolean;
  acceptedRules: boolean;
  currentSweepstakeEnd: string | null;
  currentSweepstakeName: string | null;
  nextSweepstakeName: string | null;
  nextSweepstakeStart: string | null;
  badgeCount: number;
  newBadgeNotification: LimitedEditionBadgeDto | null;
  profileImage: number;
  hasVideoEntryAwarded: boolean;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface SeenModalAction {
  type: typeof SEENMODAL;
}

export interface SetHighScoreAction {
  type: typeof SETHIGHSCORE;
  score: number;
}

export interface SetProfileImage {
  type: typeof SETPROFILEIMAGE;
  profileImage: number;
}

export type UserActionTypes =
  | LoginAction
  | LogoutAction
  | SeenModalAction
  | SetEntryCount
  | SetHighScoreAction
  | SetProfileImage;
