import React from 'react';
import classNames from 'classnames';
import logoStacked from 'images/loveuary2024/princess-logo-landing-stacked.svg';
import logoHorizontal from 'images/loveuary2024/princess-logo-landing-horizontal.svg';
import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';

const SponsorLogo = ({ type }: { type: 'stacked' | 'horizontal' }) => {
  const isStacked = type === 'stacked';

  return (
    <div className={classNames('landing-header-sponsor-logo', isStacked ? 'stacked' : 'horizontal')}>
      <p>Presented by</p>
      <a
        href="https://www.princess.com?utm_source=matching_momements&utm_campaign=loveuary_sweepstakes"
        rel="noreferrer noopener"
        target="_blank"
        onClick={() => trackEvent('Princess Cruises Logo', 'Princess Cruises Click', 'Landing')}
      >
        <img src={logoStacked} alt="Princess Cruises" className="stacked" />
        <img src={logoHorizontal} alt="Princess Cruises" className="horizontal" />
      </a>
    </div>
  );
};

export default SponsorLogo;
