import {
  ADDTIMEBONUS,
  GAMEOVER,
  LOADED,
  RESTARTGAME,
  SENTTOAPI,
  SETNOPOSSIBLEMOVES,
  SETSCORE,
  SETSCOREONLEVELCOMPLETE,
  SHOWHELP,
  STARTLEVEL,
  TOGGLEPAUSED,
  TOGGLESOUND,
  UPDATEHINTCOUNT,
  UPDATESHUFFLECOUNT,
  GameActionTypes,
  APICALLSTART,
  APICALLEND,
} from './actionTypes';

// Called by the game once lvl is completed to save score to state - GamePage will add the time remaining after this action has completed
export function setScoreOnLevelComplete(levelScore: number, totalScore: number, level: number): GameActionTypes {
  return {
    type: SETSCOREONLEVELCOMPLETE,
    levelScoreBeforeBonus: levelScore,
    levelCompleted: level,
    totalScoreBeforeBonus: totalScore,
  };
}

export function updateScoreDuringGame(totalScore: number): GameActionTypes {
  return { type: SETSCORE, totalScore };
}

export function addTimeBonus(secondsRemaining: number, completionTime: string, timeBonus: number): GameActionTypes {
  return { type: ADDTIMEBONUS, secondsRemaining, timeBonus, completionTime };
}

export function startLevel(): GameActionTypes {
  return { type: STARTLEVEL };
}

export function updateShuffleCount(increment: boolean = false): GameActionTypes {
  return { type: UPDATESHUFFLECOUNT, increment: increment };
}

export function updateHintCount(increment: boolean = false): GameActionTypes {
  return { type: UPDATEHINTCOUNT, increment: increment };
}

export function restartGame(): GameActionTypes {
  return { type: RESTARTGAME };
}

export function setNoPossibleMoves(): GameActionTypes {
  return { type: SETNOPOSSIBLEMOVES };
}

export function gameOver(gameOverMessage: string): GameActionTypes {
  return { type: GAMEOVER, gameOverMessage };
}

export function toggleSound(on: boolean): GameActionTypes {
  return { type: TOGGLESOUND, on };
}

export function togglePaused(paused: boolean): GameActionTypes {
  return { type: TOGGLEPAUSED, paused };
}

export function loaded(loaded: boolean = true): GameActionTypes {
  return { type: LOADED, loaded };
}

export function showHelp(showHelp: boolean = true): GameActionTypes {
  return { type: SHOWHELP, showHelp };
}

export function sentToApi(): GameActionTypes {
  return { type: SENTTOAPI };
}

export function apiCallStart(): GameActionTypes {
  return { type: APICALLSTART };
}

export function apiCallEnd(): GameActionTypes {
  return { type: APICALLEND };
}
