import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loader from './Loader';
import CreateGroupForm from './Groups/CreateGroupForm';
import { IGroupBaseModel } from 'types/Groups';
import UserGroupListItem from './Groups/UserGroupListItem';
import callApi from 'infrastructure/api/CallApi';
import { useSelector } from 'react-redux';
import RootState from 'types';
import { Link } from 'react-router-dom';
import Button from 'components/Layout/Button';
import { GroupIndexRoute, GroupListPageRoute, ProfileRoute } from 'constants/Routes';
import PublicGroupListItem from './Groups/PublicGroupListItem';
import { badgeNotification } from 'helpers/BadgeNotification';
import { useHistory } from 'react-router';
import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';
import { groupIndexPage } from 'types/pageNameContants';
import usePageView from 'infrastructure/hooks/usePageView';

const GroupIndexPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userGroups, setUserGroups] = useState<Array<IGroupBaseModel>>([]);
  const [publicGroups, setPublicGroups] = useState<Array<IGroupBaseModel>>([]);
  const { id: userId } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  usePageView(groupIndexPage, GroupIndexRoute);

  useEffect(() => {
    const userGroupPromise = callApi<Array<IGroupBaseModel>>(`api/app/group/users-groups/${userId}`, 'GET');
    const publicGroupPromise = callApi<Array<IGroupBaseModel>>('api/app/group/public-short-list', 'GET');

    Promise.all([userGroupPromise, publicGroupPromise]).then(([{ data: userGroups }, { data: publicGroups }]) => {
      setUserGroups(userGroups);
      setPublicGroups(publicGroups);
      setIsLoading(false);
    });
  }, [userId]);

  const onNewGroup = (group: IGroupBaseModel) => {
    if (group.awardedBadges.length > 0) {
      // notify user of new badge
      badgeNotification(group.awardedBadges, [], () => history.push(ProfileRoute));
    }

    setUserGroups([...userGroups, group]);

    setPublicGroups(
      publicGroups.map((publicGroup) => {
        if (publicGroup.id === group.id) {
          return {
            ...publicGroup,
            memberCount: publicGroup.memberCount + 1,
          };
        }
        return publicGroup;
      })
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-groups">
      <Container className="leaderboard-container group">
        <h3 className="pretitle mb-3 mb-md-5">Play with your friends and other Hallmark viewers!</h3>
        {userGroups.length > 0 && (
          <>
            <h1>Your Groups</h1>
            <div className="row">
              <Col sm={12}>
                <div className="groups-table">
                  {userGroups.map((group, index) => (
                    <UserGroupListItem index={index} key={group.id} group={group} />
                  ))}
                </div>
              </Col>
            </div>
          </>
        )}
        <Row>
          <Col sm={12}>
            <h1>Create a Group</h1>
            <CreateGroupForm onGroupCreation={onNewGroup} />
          </Col>
        </Row>

        <Row className="group-header">
          <Col xs={12}>
            {/* FOR SEO PURPOSES THIS SHOULD BE A DIFFERENT HEADER VALUE */}
            <h1>Public Groups</h1>
          </Col>
        </Row>
        <Row className="public-groups">
          {publicGroups.map((x) => (
            <PublicGroupListItem
              key={x.id}
              group={x}
              onGroupJoinSuccess={onNewGroup}
              isMember={userGroups.map((y) => y.id).includes(x.id)}
            />
          ))}
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs={12}>
            <Link to={GroupListPageRoute}>
              <Button
                variant="primary"
                size="sm"
                className="view-all-button m-auto"
                onClick={() => trackEvent('View All Groups', 'View All Groups Click', 'Group Index Page')}
              >
                View All
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GroupIndexPage;
