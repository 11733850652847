import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

const NotFound404 = () => (
  <React.Fragment>
    <div className="error">
      <Container>
        <h5>Oops, looks like we can't find the page you were looking for.</h5>
        <Link to="/">
          <Button variant="primary" size="lg">
            Back to homepage
          </Button>
        </Link>
      </Container>
    </div>
  </React.Fragment>
);

export default NotFound404;
