import React from 'react';
import useLinkTarget from '../../infrastructure/isWebView/useLinkTarget';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { togglePaused } from '../../slices/game/actions';
import { RulesRoute, GameRoute, LandingRoute } from '../../constants/Routes';
import RootState from '../../types';
import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';

type SweepsDatesDictionary = {
  [key: string]: { startTime: string; endTime: string; startDate: string; endDate: string };
};

const sweepDates: SweepsDatesDictionary = {
  'Week 1': { startTime: '12:00 PM', startDate: '3/28/22', endTime: '11:59 PM', endDate: '4/3/22' },
  'Week 2': { startTime: '12:00 AM', startDate: '4/4/22', endTime: '11:59 PM', endDate: '4/10/22' },
  'Week 3': { startTime: '12:00 AM', startDate: '4/11/22', endTime: '11:59 PM', endDate: '4/17/22' },
  'Week 4': { startTime: '12:00 AM', startDate: '4/18/22', endTime: '11:59 PM', endDate: '4/24/22' },
  'Week 5': { startTime: '12:00 AM', startDate: '4/25/22', endTime: '11:59 PM', endDate: '5/1/22' },
  'Week 6': { startTime: '12:00 AM', startDate: '5/2/22', endTime: '11:59 PM', endDate: '5/8/22' },
};

const Disclaimer = () => {
  const linkTarget = useLinkTarget();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentSweepstakeName } = useSelector((state: RootState) => state.sweepstake);

  if (!currentSweepstakeName) {
    return <Redirect to={LandingRoute} />;
  }

  if (location.pathname.includes(RulesRoute)) {
    return <></>;
  }

  const onRulesClick = () => {
    if (location.pathname.includes(GameRoute)) {
      dispatch(togglePaused(true));
    }
  };

  const dates = sweepDates[currentSweepstakeName];

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="disclaimer">
            <p>
              <strong>NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN.</strong> Open to legal residents of the 50
              U.S./D.C., age 21+. Void outside the 50 U.S./D.C. and where prohibited. Sweepstakes starts at 10:00:00 AM
              ET on 01/29/24; ends at 11:59:59 PM ET on 02/25/24. Odds of winning will depend on the number of eligible
              entries received for each drawing. Total ARV of all prizes: $8,245. For full Official Rules, entry periods
              and entry limits{' '}
              <a href={RulesRoute} target={linkTarget} rel="noopener noreferrer" onClick={onRulesClick}>
                <strong>click here</strong>
              </a>
              . Sponsor: Hallmark Media United States, LLC, 12700 Ventura Boulevard, Studio City, CA 91604.
            </p>
            <p className="center">
              ©2024,{' '}
              <a
                href="https://www.princess.com?utm_source=matching_momements&utm_campaign=loveuary_sweepstakes"
                rel="noreferrer noopener"
                target="_blank"
                onClick={() => trackEvent('Princess Cruises Disclaimer', 'Princess Cruises Click', 'Disclaimer')}
              >
                Princess Cruise Lines, Ltd., Princess®
              </a>
              , and the Princess logo are trademarks of Princess Cruise Lines, Ltd. All rights reserved. Ships of
              Bermudan and British registry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
