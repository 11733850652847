export const localStorageKey = 'HallmarkMatchingMomentsAuth';
export const localStorageModalViewedKey = 'HallmarkMatchingMomentsModalViewed';
export const localStorageSavedTotalKey = 'HallmarkMatchingMomentsTotal';
export const localStorageShowStarAnimationKey = 'HallmarkMatchingMomentsShowStarAnimation';
export const localStorageLandingModalViewedKey = 'HallmarkMatchingMomentsLandingModalViewed';
export const localStorageBadgeNotificationKey = 'HallmarkMatchingMomentsLastBadgeNotifictionViewed';

export const pageNameFormPage = "Hallmark Channel's Matching Moments - Sign up";
export const pageNameDeedsListPage = "Hallmark Channel's Matching Moments - Deeds List";
export const pageNameHowItWorksPage = "Hallmark Channel's Matching Moments - How It Works";
export const pageNameIntroPage = "Hallmark Channel's Matching Moments - Intro Modal";
