import { LoginAction, LogoutAction, SetEntryCount } from '../users/actionTypes';
export const SETSWEEPSTAKES = 'SETSWEEPSTAKES';

export interface SetSweepstakes {
  type: typeof SETSWEEPSTAKES;
  currentSweepstakeEnd: string | null;
  currentSweepstakeName: string | null;
  nextSweepstakeName: string | null;
  nextSweepstakeStart: string | null;
}

export type SweepstakesActionTypes = SetSweepstakes | LoginAction | SetEntryCount | LogoutAction;
