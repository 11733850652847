import React from 'react';
// import { Clock } from '../../../images/icons';

import './SweepstakesCallout.scss';
import { useSelector } from 'react-redux';
import RootState from '../../../types/index';
import dontMissOutCopy from '../../../images/loveuary2024/dont-miss-out.webp';

interface SweepstakesCalloutProps {
  withButton?: boolean;
}

const SweepstakesCallout = ({ withButton }: SweepstakesCalloutProps) => {
  // const clockColorsSweepstakes = ['#0E5432', '#FFFFFF', '#EE2D24', '#D2E1D7'];
  // const clockColors = ['#0E5432', '#FFFFFF', '#EE2D24', '#D2E1D7', '#D2E1D7']; // TODO: do we need different colors?
  const landingPageText = useSelector((state: RootState) => state.gameConfig.landingPageText);

  return (
    <div className="sweepstakes-callout">
      {/* <Clock colors={isCurrentSweepstake ? clockColorsSweepstakes : clockColors} /> */}
      <div className="sweepstakes-callout_content">
        <div className="sweepstakes-callout_title">
          <img src={dontMissOutCopy} alt="Don't Miss Out!" className="dont-miss-out-copy" />
          {landingPageText && <div className="message" dangerouslySetInnerHTML={{ __html: landingPageText }} />}
        </div>
      </div>
    </div>
  );
};

export default SweepstakesCallout;
