import ShareState from './ShareState';
import { ShareActionTypes, SETINVITECODE } from './actionTypes';
const initialState: ShareState = {
  shareCode: null,
};

const shareReducer = (state = initialState, action: ShareActionTypes) => {
  switch (action.type) {
    case SETINVITECODE:
      return {
        shareCode: action.shareCode,
      };
    default:
      return state;
  }
};

export default shareReducer;
