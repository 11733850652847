const OPTIONS = [
  'Amazon',
  'Apple TV',
  'Cox',
  'DIRECTV',
  'DISH',
  'FrndlyTV',
  'Frontier',
  'FuboTV',
  'Hulu',
  'LG',
  'Optimum',
  'Peacock',
  'Philo',
  'Roku',
  'Samsung',
  'Sling TV',
  'Spectrum',
  'U-verse',
  'Verizon FiOS',
  'Vizio',
  'Xfinity',
  'YouTube TV',
  'Other',
];

const PROVIDERS = OPTIONS.map((provider) => ({
  value: provider,
  label: provider,
}));

export default PROVIDERS;
