import { LOGIN, LOGOUT, SETENTRYCOUNT, UserActionTypes } from '../users/actionTypes';
import { SETSWEEPSTAKES, SweepstakesActionTypes } from './actionTypes';

import SweepstakeState from './SweepstakeState';

const initialState: SweepstakeState = {
  entryCount: 0,
  grandPrizeCount: 0,
  hasVideoEntryAwarded: false,
  newFormEntryAdded: false,
  currentSweepstakeEnd: null,
  currentSweepstakeName: null,
  nextSweepstakeName: null,
  nextSweepstakeStart: null,
};

const sweepstakeReducer = (state = initialState, action: SweepstakesActionTypes) => {
  switch (action.type) {
    case LOGIN:
      return {
        id: action.id,
        name: action.name,
        emailAddress: action.emailAddress,
        hasSeenModal: true,
        entryCount: action.entryCount,
        grandPrizeCount: action.grandPrizeCount,
        hasVideoEntryAwarded: action.hasVideoEntryAwarded,
        highScore: action.highScore,
        shareCode: action.shareCode,
        newFormEntryAdded: action.newFormEntryAdded,
        currentSweepstakeName: action.currentSweepstakeName,
        currentSweepstakeEnd: action.currentSweepstakeEnd,
        nextSweepstakeName: action.nextSweepstakeName,
        nextSweepstakeStart: action.nextSweepstakeStart,
      };
    case SETENTRYCOUNT:
      return {
        ...state,
        ...action,
      };
    case SETSWEEPSTAKES:
      return {
        ...state,
        currentSweepstakeEnd: action.currentSweepstakeEnd,
        currentSweepstakeName: action.currentSweepstakeName,
        nextSweepstakeName: action.nextSweepstakeName,
        nextSweepstakeStart: action.nextSweepstakeStart,
      };
    case LOGOUT:
      return {
        ...state,
        entryCount: 0,
        newFormEntryAdded: false,
      };
      return initialState;
    default:
      return state;
  }
};

export default sweepstakeReducer;
