import {
  SETSCOREONLEVELCOMPLETE,
  STARTLEVEL,
  SETSCORE,
  GameActionTypes,
  UPDATESHUFFLECOUNT,
  UPDATEHINTCOUNT,
  RESTARTGAME,
} from './actionTypes';
import { LOGIN } from '../users/actionTypes';
import GameState from './GameState';
import {
  ADDTIMEBONUS,
  SETNOPOSSIBLEMOVES,
  GAMEOVER,
  TOGGLESOUND,
  TOGGLEPAUSED,
  LOADED,
  SHOWHELP,
  SENTTOAPI,
  APICALLSTART,
  APICALLEND,
} from './actionTypes';

export const startingShuffles = 7;
export const startingHints = 5;

const initialState: GameState = {
  awaitingBonus: false,
  activeScore: 0,
  completionTime: '',
  gameOverMessage: '',
  hintsRemaining: startingHints,
  isManuallyPaused: false,
  levelCompleted: 0,
  levelScore: 0,
  levelScoreBeforeBonus: 0,
  loading: true,
  noPossibleMoves: false,
  requiresSaveToApi: false,
  secondsRemaning: 0,
  showGameOverModal: false,
  showHelpModal: false,
  showNextLevelModal: false,
  shufflesRemaining: startingShuffles,
  soundOn: true,
  totalSecondsRemaining: 0,
  totalScore: 0,
  totalScoreBeforeBonus: 0,
  totalNumberOfLevels: 11,
  apiCallInProgress: false,
};

const userReducer: (state: GameState, action: GameActionTypes) => GameState = (
  state = initialState,
  action: GameActionTypes
) => {
  let numberOfLevels: number;
  switch (action.type) {
    case SETSCOREONLEVELCOMPLETE:
      return {
        ...state,
        totalScoreBeforeBonus: action.totalScoreBeforeBonus,
        levelScoreBeforeBonus: action.levelScoreBeforeBonus,
        levelCompleted: action.levelCompleted,
        awaitingBonus: true,
      };
    case STARTLEVEL:
      return {
        ...state,
        showNextLevelModal: false,
      };
    case SETSCORE:
      return {
        ...state,
        activeScore: action.totalScore,
      };
    case ADDTIMEBONUS:
      const totalScore = state.totalScoreBeforeBonus + action.timeBonus;
      return {
        ...state,
        totalScore: totalScore,
        activeScore: totalScore,
        levelScore: state.levelScoreBeforeBonus + action.timeBonus,
        secondsRemaining: action.secondsRemaining,
        totalSecondsRemaining: action.secondsRemaining + state.totalSecondsRemaining,
        completionTime: action.completionTime,
        showNextLevelModal: true,
        awaitingBonus: false,
        requiresSaveToApi: true,
      };
    case LOGIN:
      numberOfLevels = 11;
      return {
        ...state,
        totalScore: action.currentScore,
        activeScore: action.currentScore,
        levelCompleted: action.lastLevelPlayed,
        totalSecondsRemaining: action.totalSecondsRemaining || 0,
        hintsRemaining:
          action.hintsRemaining && action.lastLevelPlayed < numberOfLevels ? action.hintsRemaining : startingHints,
        shufflesRemaining:
          action.shufflesRemaining && action.lastLevelPlayed < numberOfLevels
            ? action.shufflesRemaining
            : startingShuffles,
        totalNumberOfLevels: numberOfLevels,
      };
    case UPDATESHUFFLECOUNT:
      return {
        ...state,
        shufflesRemaining: action.increment ? state.shufflesRemaining + 1 : state.shufflesRemaining - 1,
        noPossibleMoves: false,
      };
    case UPDATEHINTCOUNT:
      return {
        ...state,
        hintsRemaining: action.increment ? state.hintsRemaining + 1 : state.hintsRemaining - 1,
      };
    case SETNOPOSSIBLEMOVES:
      return {
        ...state,
        noPossibleMoves: true,
      };
    case GAMEOVER:
      return {
        ...state,
        showGameOverModal: true,
        gameOverMessage: action.gameOverMessage,
      };
    case TOGGLESOUND:
      return {
        ...state,
        soundOn: action.on,
      };
    case TOGGLEPAUSED:
      return {
        ...state,
        isManuallyPaused: action.paused,
        showHelpModal: !action.paused ? false : state.showHelpModal,
      };
    case LOADED:
      return {
        ...state,
        loading: !action.loaded,
      };
    case SHOWHELP:
      return {
        ...state,
        showHelpModal: action.showHelp,
        isManuallyPaused: action.showHelp && state.isManuallyPaused,
      };
    case RESTARTGAME:
      return { ...initialState, soundOn: state.soundOn };
    case SENTTOAPI:
      return {
        ...state,
        requiresSaveToApi: false,
      };
    case APICALLSTART:
      return {
        ...state,
        apiCallInProgress: true,
      };
    case APICALLEND:
      return {
        ...state,
        apiCallInProgress: false,
      };
    default:
      return state;
  }
};

export default userReducer;
