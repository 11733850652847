import GameLine1 from './game-line-1.png';
import GameLine2 from './game-line-2.png';
import GameLine3 from './game-line-3.png';
import GameLine4 from './game-line-4.png';
import GameLine5 from './game-line-5.png';

import TutorialStep0 from './tutorial-step-0.png';
import TutorialStep1 from './tutorial-step-1.png';
import TutorialStep2 from './tutorial-step-2.png';
import TutorialStep3 from './tutorial-step-3.png';
import TutorialStepSweepstakes0 from './tutorial-step-0-sweepstakes.png';
import TutorialStepSweepstakes1 from './tutorial-step-1-sweepstakes.png';
import TutorialStepSweepstakes2 from './tutorial-step-2-sweepstakes.png';
import TutorialStepSweepstakes3 from './tutorial-step-3-sweepstakes.png';

import SnsBackgroundFair from './background-fair.svg';
import SnsBackgroundPeople from './background-people.svg';
import SnsBackgroundStalls from './background-stalls.svg';

export { default as HalftoneTextureBottom } from './HalftoneTextureBottom';
export { default as HalftoneTextureTop } from './HalftoneTextureTop';

export { default as BgLeft } from './bg-left.jpg';
export { default as BgRight } from './bg-right.jpg';
export { default as BgPresent } from './background-present.png';
export { default as BgTree } from './background-tree.png';
export { default as cardLevel } from './card-level11.jpg';
export { default as cardLevelSweepstakes } from './card-level11-sweepstakes.jpg';
export { default as cardProfile } from './card-profile.jpg';
export { default as cardProfileSweepstakes } from './card-profile-sweepstakes.jpg';
export { default as cardBadge } from './card-badges.jpg';
export { default as cardBadgeSweepstakes } from './card-badges-sweepstakes.jpg';
export { default as cardLeaderboard } from './card-leaderboard.jpg';
export { default as cardLeaderboardSweepstakes } from './card-leaderboard-sweepstakes.jpg';

export { default as HintArrow } from './arrow.png';
export { default as IsometricTiles } from './isometric-tiles.png';
export { default as IsometricTilesPlum } from './isometric-tiles-plum.webp';

const GameLines = [GameLine1, GameLine2, GameLine3, GameLine4, GameLine5];

export const SNSBackground = {
  Fair: SnsBackgroundFair,
  People: SnsBackgroundPeople,
  Stalls: SnsBackgroundStalls,
};

export const TutorialStepsSweepstakes = [
  TutorialStepSweepstakes0,
  TutorialStepSweepstakes1,
  TutorialStepSweepstakes2,
  TutorialStepSweepstakes3,
];
export const TutorialSteps = [TutorialStep0, TutorialStep1, TutorialStep2, TutorialStep3];

export default GameLines;
