import './NavBarMobile.scss';
import React from 'react';
import { getNavbarIcon, getNavbarUrl } from './utils';
import { Link, useLocation } from 'react-router-dom';
import { GameRoute, LeaderboardRoute, ProfileRoute } from 'constants/Routes';
import { useSelector } from 'react-redux';
import RootState from '../../../types/index';

import classNames from 'classnames';

const NavBarMobile = () => {
  const pathname = useLocation().pathname;
  const { name: username } = useSelector((state: RootState) => state.user);

  return username ? (
    <div className="nav-bar-mobile">
      <NavBarMobileLink title="Play" isCurrentRoute={pathname === GameRoute} />
      <NavBarMobileLink title="Leaderboard" isCurrentRoute={pathname === LeaderboardRoute} />
      <NavBarMobileLink title="Groups" isCurrentRoute={pathname.includes('group') || pathname.includes('groups')} />
      <NavBarMobileLink title="Profile" isCurrentRoute={pathname === ProfileRoute} />
    </div>
  ) : (
    <></>
  );
};

const NavBarMobileLink = ({ title, isCurrentRoute }: { title: string; isCurrentRoute: boolean }) => {
  return (
    <Link to={getNavbarUrl(title)} className={classNames('nav-bar-mobile-link', isCurrentRoute ? 'active' : '')}>
      {isCurrentRoute ? <div className="nav-bar-mobile-indicator" /> : ''}
      {getNavbarIcon(title)}
      <span className="nav-bar-mobile-link-title">{title}</span>
    </Link>
  );
};

export default NavBarMobile;
