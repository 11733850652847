import React from 'react';
import GameNavIcons from '../../images/game/index';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import { selectActiveLevel } from '../../slices/game/selectors';
import { numberWithCommas } from '../../helpers/index';

interface GameNavProps {
  formattedTime: string;
}

const GameNav: React.FC<GameNavProps> = ({ formattedTime }) => {
  const { activeScore, showNextLevelModal, levelCompleted, totalNumberOfLevels } = useSelector(
    (state: RootState) => state.game
  );
  const currentLevel = useSelector(selectActiveLevel);

  const levelToShow = showNextLevelModal && levelCompleted === totalNumberOfLevels ? totalNumberOfLevels : currentLevel;

  return (
    <div className="game-navigation-left">
      <div className="navigation__level">
        <span>Level {levelToShow}</span>
      </div>
      <div className="navigation__time-score-container">
        <div className="navigation__time-score">
          <img src={GameNavIcons.Time} alt="Time" />
          <span className="navigation__time-value">{formattedTime}</span>
        </div>
        <div className="navigation__time-score navigation__score">
          <img src={GameNavIcons.Points} alt="Score" />
          <span className="game-nav__btn-content game-nav__btn-content_score">{numberWithCommas(activeScore)}</span>
        </div>
      </div>
    </div>
  );
};

export default GameNav;
