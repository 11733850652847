import React from 'react';
import Button from '../Layout/Button';
import GameNavIcons from '../../images/game';
import Game from '../../game/Scenes/Game';
import { useSelector, useDispatch } from 'react-redux';
import RootState from '../../types';
import { selectActiveLevel } from '../../slices/game/selectors';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import { showHelp } from '../../slices/game/actions';

interface GameButtonsProps {
  gameScene: Game | undefined;
  pauseTimer: () => void;
  showTooltips?: boolean;
}

const GameButtons: React.FC<GameButtonsProps> = ({ gameScene, pauseTimer, showTooltips }) => {
  const dispatch = useDispatch();
  const { shufflesRemaining, hintsRemaining, noPossibleMoves, soundOn } = useSelector((state: RootState) => state.game);
  const activeLevel = useSelector(selectActiveLevel);
  const trackClick = (action: string, includeLevel: boolean = true) =>
    trackEvent('Game Interaction', action, includeLevel ? `Level ${activeLevel}` : undefined);

  const onPauseClick = () => {
    trackClick('Game Paused');
    pauseTimer();
    gameScene?.pauseGame();
  };

  const onHelpClick = () => {
    trackClick('Help Selected');
    dispatch(showHelp());
    pauseTimer();
    gameScene?.pauseGame();
  };

  return (
    <div style={{ background: 'white', borderRadius: '0.5rem' }}>
      <div className="game-navigation-right">
        <Button
          onClick={() => {
            trackClick('Shuffle Selected');
            gameScene?.shuffleBoard();
          }}
          className={`navigation-right__button navigation-button-img-${noPossibleMoves ? 'show' : 'hide'}`}
          variant="game"
          disabled={shufflesRemaining === 0}
        >
          <div className="navigation-button-icon">
            <img src={GameNavIcons.Shuffle} alt="Shuffle" className="navigation-right__icon" />
          </div>

          <span className="navigation-button__text">RESHUFFLE</span>

          <div className="navigation-button__value">
            <span>{shufflesRemaining}</span>
          </div>
        </Button>

        <Button
          onClick={() => {
            if (!noPossibleMoves) {
              trackClick('Hint Selected');
              gameScene?.useHint();
            }
          }}
          className="navigation-right__button"
          variant="game"
          disabled={noPossibleMoves || hintsRemaining === 0}
        >
          <div className="navigation-button-icon">
            <img src={GameNavIcons.Hint} alt="Hint" className="navigation-right__icon" />
          </div>

          <span className="navigation-button__text">HINT</span>

          <div className="navigation-button__value">
            <span>{hintsRemaining}</span>
          </div>
        </Button>

        <Button
          onClick={() => {
            trackClick(`Game Sound Turned ${soundOn ? 'off' : 'on'}`, false);
            gameScene?.toggleSound();
          }}
          className="navigation-right__button"
          variant="game"
        >
          <div className="navigation-button-icon">
            <img
              src={soundOn ? GameNavIcons.Unmute : GameNavIcons.Mute}
              alt="Toggle Sound"
              className="navigation-right__icon"
            />
          </div>
          <span className="navigation-button__text" style={{ width: '75px' }}>
            {soundOn ? 'SOUND OFF' : 'SOUND ON'}
          </span>
        </Button>

        <Button onClick={onPauseClick} className="navigation-right__button" variant="game">
          <div className="navigation-button-icon">
            <img src={GameNavIcons.Pause} alt="Pause" className="navigation-right__icon" />
          </div>
          <span className="navigation-button__text">PAUSE</span>
        </Button>

        {/* <Button onClick={onHelpClick} className="navigation-button" variant="game">
        <div className="navigation-button-icon">
          <img src={GameNavIcons.Help} alt="Help" />
        </div>
      </Button> */}
      </div>
    </div>
  );
};

export default GameButtons;
