const GetRouteName: (pathname: string, showHelp: boolean) => string = (pathname, showHelp) => {
  const pathNameWithoutSlash = pathname.replace(/\/$/, '');
  switch (pathNameWithoutSlash) {
    case '/game':
      return showHelp ? 'Game Help Modal' : 'Game Screen';
    case '/leaderboard':
      return 'Leaderboard';
    case '/rules':
      return 'Rules';
    default:
      return 'Landing';
  }
};

export default GetRouteName;
