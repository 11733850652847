import React from 'react';
import { useLocation } from 'react-router-dom';
import { HalftoneTextureBottom, HalftoneTextureTop } from '../../images/backgrounds';

interface PageContainerProps {
  children: React.ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => {
  const location = useLocation();

  const landingRoutes = ['/', '/signup'];
  const isLanding = landingRoutes.includes(location.pathname);
  const textureFillColor = isLanding ? '#F9F7FF' : '#F9F7FF';
  return (
    <div className={`page-background ${isLanding ? 'page-background-landing' : 'page-background-game'}`}>
      <HalftoneTextureTop color={textureFillColor} />
      {/* <HalftoneTextureBottom color={textureFillColor} /> */}
      {children}
    </div>
  );
};

export default PageContainer;
