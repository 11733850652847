/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from '../Layout/Button';
import ModalTuneIn from './ModalTuneIn';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import trackPageViewGoogle from '../../infrastructure/tracking';
import { gameOverTimeUpModal, gameOverNoMoreMovesModal } from '../../types/pageNameContants';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';

interface GameOverModalProps {
  hideModal: () => void;
}

const GameOverModal: React.FC<GameOverModalProps> = ({ hideModal }) => {
  const { showGameOverModal: show, gameOverMessage } = useSelector((state: RootState) => state.game);

  useEffect(() => {
    if (show) {
      const timeUp = gameOverMessage.includes('time');
      const pagePath = `/game/gameover/${timeUp ? 'timeup' : 'nomoremoves'}`;
      const pageTitle = timeUp ? gameOverTimeUpModal : gameOverNoMoreMovesModal;
      trackPageViewGoogle(pageTitle, pagePath);
    }
  }, [show]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => hideModal()}
      className="level-modal game-over-modal"
    >
      <Modal.Header>
        <h3>Game Over</h3>
        <h5>{gameOverMessage}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="btn-row">
          <Link to="/leaderboard" className="px-1">
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                trackEvent('Go To Leaderboard', 'Leaderboard Button Selected', 'Game Over Modal');
              }}
            >
              Leaderboard
            </Button>
          </Link>
          <Button
            variant="outline-primary"
            size="sm"
            className="px-1"
            onClick={() => {
              trackEvent('User Game Restarted', 'Start Again Button Selected', 'Game Over Modal');
              hideModal();
            }}
          >
            Start Again
          </Button>
        </div>
      </Modal.Body>
      <ModalTuneIn label="Game Over Modal" />
    </Modal>
  );
};

export default GameOverModal;
