/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row } from 'react-bootstrap';
import ProfileStat from './ProfileStat';
import {
  circlePlay,
  circleFinished,
  circleStreak,
  circleTick,
  circleClock,
  circleLeaderboard,
} from '../../images/icons';
import { ProfileStatsDto } from '../../types/UserProfile';

const ProfileStats: React.FC<ProfileStatsDto> = ({
  gamesStarted,
  gamesFinished,
  currentStreak,
  levelsCompleted,
  totalPlayTime,
  highestPositionWeekly,
  highestPositionAllTime,
}) => {
  return (
    <div className="profile-stats">
      <Row>
        <ProfileStat title="Games Started" value={gamesStarted} image={circlePlay} type="SINGLE" />
        <ProfileStat title="Games Finished" value={gamesFinished} image={circleFinished} type="SINGLE" />
        <ProfileStat title="Current Streak" value={currentStreak} image={circleStreak} type="SINGLE" />
        <ProfileStat title="Levels Completed" value={levelsCompleted} image={circleTick} type="SINGLE" />
        <ProfileStat title="Total Play Time (Mins)" value={totalPlayTime} image={circleClock} type="SINGLE" />
        <ProfileStat
          title="Highest Position"
          value={highestPositionWeekly}
          value2={highestPositionAllTime}
          image={circleLeaderboard}
          subheader1="Weekly"
          subheader2="Overall"
          type="DOUBLE"
        />
      </Row>
    </div>
  );
};

export default ProfileStats;
