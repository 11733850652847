import React, { useEffect } from 'react';
import AdSlot from './AdSlot';
import { withRouter } from 'react-router-dom';
import useFreewheel from '../../infrastructure/freewheel/useFreewheel';
import { useSelector } from 'react-redux';
import { selectActiveLevel } from '../../slices/game/selectors';

const FooterAd = ({ location, shouldDisplay, classNames }) => {
  const freewheelRequest = useFreewheel();
  const activeLevel = useSelector(selectActiveLevel);

  useEffect(() => {
    if (shouldDisplay) {
      freewheelRequest(location.pathname, activeLevel);
    }
  }, [location, freewheelRequest, shouldDisplay, activeLevel]);
  if (!shouldDisplay) {
    return null;
  }
  return <AdSlot width={300} height={250} slotId="fixed_ad" classNames={classNames} />;
};

export default withRouter(FooterAd);
