import Swal from 'sweetalert2';
import { notificationBaseOptions } from './NotificationOptions';
import withReactContent from 'sweetalert2-react-content';

const reactSwal = withReactContent(Swal);

export const inappropriateLanguageNotification = () => {
  return reactSwal.fire({
    ...notificationBaseOptions,
    icon: 'error',
    iconColor: '#FF0000',
    showConfirmButton: true,
    confirmButtonText: 'OK',
    html: 'Sorry, your group name contains inappropriate language.',
  });
};
