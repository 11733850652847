import { coord, Level, PieceSprite, BoardPosition } from '../types';
import { findWay } from './gameLogic';

export const hint: (
  array: Array<Array<BoardPosition>>,
  level: Level,
  pieces: Phaser.GameObjects.Group
) => Array<coord> | null = (array: Array<Array<BoardPosition>>, level: Level, pieces: Phaser.GameObjects.Group) => {
  let total = pieces.getLength();
  let child = pieces.getChildren() as PieceSprite[];
  for (let i = 0; i < total; i++) {
    let piece = child[i];
    let src = searchMatch(piece.pos!, array, level);
    if (src) {
      return [piece.pos!, src];
    }
  }
  return null;
};

export const searchMatch: (pos: coord, array: any, level: Level) => coord | null = (
  pos: coord,
  array: any,
  level: Level
) => {
  let color = array[pos.y][pos.x].color;
  for (let y = 0; y < level.numberOfRows(); y++) {
    for (let x = 0; x < level.numberOfColumns(); x++) {
      if (y === pos.y && x === pos.x) {
        //
      } else if (array[y][x].filled) {
        //array[y][x].available
        if (array[y][x].color === color) {
          let way = findWay(pos, { x: x, y: y }, array, level);
          if (way) {
            return { x: x, y: y };
          }
        }
      }
    }
  }
  return null;
};
