import React, { useEffect, useState } from 'react';

import { Col, Container, Row, Image } from 'react-bootstrap';
import Button from 'components/Layout/Button';
import RootState from 'types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { GroupIndexRoute, UrlNameKey } from 'constants/Routes';
import UnlockedIcon from 'images/icons/unlocked.svg';
import LockedIcon from 'images/icons/locked.svg';
import iconGroupRank from 'images/icons/groupRank.svg';
import PadlockImage from 'images/icons/padlock-image.webp';
import callApi from 'infrastructure/api/CallApi';
import { useSelector } from 'react-redux';
import { IGroupBaseModel, IGroupModel } from 'types/Groups';
import Loader from './Loader';
import getProfileImage from 'helpers/GetProfileImage';
import InviteLink from './Groups/InviteLink';
import { groupDetailsPage } from 'types/pageNameContants';
import { trackEvent, trackPageView } from 'infrastructure/tracking/GoogleAnalytics';

const GroupPage: React.FC = () => {
  const [group, setGroup] = useState<IGroupModel | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const urlName = useParams<{ [UrlNameKey]: string }>()[UrlNameKey]!;
  const showMobileContent = false;
  const history = useHistory();
  const { id: userId } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    callApi<IGroupModel>(`api/app/group?userId=${userId}&inviteCode=${urlName}`, 'GET').then(({ data }) => {
      setGroup(data);
      trackPageView(groupDetailsPage, `/group/${urlName}`);
      if (data?.groupType === 'private' && !data?.isMember) {
        history.replace(GroupIndexRoute);
      }
    });
  }, []);

  const onJoinClick = () => {
    if (!group || group.isMember || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    callApi<IGroupBaseModel>('api/app/group/join', 'POST', {
      userId,
      inviteCode: group.inviteCode,
    })
      .then(() => {
        trackEvent('Join Group', 'Join Group Success', 'Group Page ' + group.inviteCode);
        setGroup((currentGroup) => (currentGroup !== null ? { ...currentGroup, isMember: true } : null));
        callApi<IGroupModel>(`api/app/group?userId=${userId}&inviteCode=${urlName}`, 'GET').then(({ data }) => {
          setGroup(data);
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  if (!group) {
    return <Loader />;
  }
  return (
    <Container className="py-5 group-detail-page-container">
      <section>
        <Container className="leaderboard-container">
          <Row className="row-tight align-items-center">
            <Col xs={12} md={4} className="back-button">
              <Link to={GroupIndexRoute}>
                <Button
                  variant="outline-primary"
                  iconBefore
                  withArrow
                  size="sm"
                  className="back-to-groups-button"
                  onClick={() => trackEvent('Back to groups', 'Back to groups click', 'Groups Page ' + urlName)}
                >
                  {showMobileContent ? 'Back' : 'Back to groups'}
                </Button>
              </Link>
            </Col>
            <Col className="leaderboard-header-joined" xs={12} md={4}>
              <h1 className="mb-0">{group.name}</h1>
            </Col>
            <Col xs={12} md={4}>
              <div className="group-status-container">
                <div className="group-status">
                  {group.groupType === 'public' ? (
                    <>
                      <Image src={UnlockedIcon} className="img-fluid icon" />
                      <span>Public Group</span>
                    </>
                  ) : (
                    <>
                      <Image src={LockedIcon} className="img-fluid icon" />
                      <span>Private Group</span>
                    </>
                  )}
                </div>
                {!group.isMember && (
                  <Button size="sm" className="btn-primary" onClick={onJoinClick}>
                    Join
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-leaderboard">
        <Container className="leaderboard-container">
          {group.isMember && (
            <Container className="invite-link-container">
              {' '}
              <Row>
                <Col xs={12}>
                  <h3 className="mb-2 share-link-header">
                    {showMobileContent
                      ? 'Copy the below link and invite friends to your group!'
                      : 'Share the below link to invite friends to your group!'}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="share-link-label">Share link:</div>
                  <InviteLink inviteCode={group.inviteCode} groupName={group.name} />
                </Col>
              </Row>
            </Container>
          )}
          {(group.isMember || group.groupType === 'public') && (
            <Row>
              <Col sm={12}>
                {group.groupMembers && (
                  <table className="leaderboard-table">
                    <thead>
                      <tr>
                        <th className="table-index"></th>
                        <th className="table-name">
                          <p className="bold link leaderboard-table_name">Name</p>
                        </th>
                        <th className="leaderboard-table_user-rank">
                          <p className="bold link">Rank</p>
                        </th>
                        <th className="table-points">
                          <p className="bold link">Points</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.groupMembers.map((userScore, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td className="profile-info">
                            {userScore.profileImage !== undefined ? (
                              <Image
                                src={getProfileImage(userScore.profileImage)}
                                alt="Profile"
                                className="img-fluid leaderboard-image"
                              />
                            ) : null}
                            <p>{userScore.name}</p>
                          </td>
                          <td className="leaderboard-table_user-rank">
                            <div className="d-flex justify-content-center align-items-center">
                              <img className="mr-2" src={iconGroupRank} alt="Group Rank" />
                              <p>{userScore.rank}</p>
                            </div>
                          </td>
                          <td className="points-total">
                            <p>{userScore.score}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Col>
            </Row>
          )}
          {!group.isMember && group.groupType === 'private' && (
            <Row>
              <div className="private-group-message">
                <Image src={PadlockImage} className="img-fluid icon" />
                <h1>This is a private group.</h1>
                <p>To join, ask for an invite link from the group creator.</p>
              </div>
            </Row>
          )}
        </Container>
      </section>
    </Container>
  );
};

export default GroupPage;
