/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TutorialSteps } from '../../images/backgrounds';
import { TutorialStepsSweepstakes } from '../../images/backgrounds';
import { selectIsCurrentSweepstake } from '../../slices/sweepstakes/selectors';

import './Modal.scss';

const InteractiveTutorial = () => {
  const [animationStep, setAnimationStep] = useState<number>(0);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  useEffect(() => {
    const timeout = setTimeout(
      () => setAnimationStep(animationStep < 3 ? animationStep + 1 : 0),
      animationStep === 0 ? 1000 : 3000
    );
    return () => clearTimeout(timeout);
  }, [animationStep]);

  return (
    <React.Fragment>
      <div className={`interactive-game-tutorial interactive-game-tutorial-step-${animationStep}`}>
        <div className="interactive-game-tutorial-step-img">
          <img
            src={isCurrentSweepstake ? TutorialStepsSweepstakes[0] : TutorialSteps[0]}
            alt=""
            style={{ opacity: animationStep === 0 ? 1 : 0 }}
          />
          <img
            src={isCurrentSweepstake ? TutorialStepsSweepstakes[1] : TutorialSteps[1]}
            alt=""
            style={{ opacity: animationStep === 1 ? 1 : 0 }}
          />
          <img
            src={isCurrentSweepstake ? TutorialStepsSweepstakes[2] : TutorialSteps[2]}
            alt=""
            style={{ opacity: animationStep === 2 ? 1 : 0 }}
          />
          <img
            src={isCurrentSweepstake ? TutorialStepsSweepstakes[3] : TutorialSteps[3]}
            alt=""
            style={{ opacity: animationStep === 3 ? 1 : 0 }}
          />
        </div>
        <div className="interactive-game-tutorial-steps">
          <ol>
            <li className={animationStep === 1 ? 'interactive-game-tutorial-step-active' : ''}>
              <p>Tap on identical tiles to create connecting paths between them</p>
            </li>
            <li className={animationStep === 2 ? 'interactive-game-tutorial-step-active' : ''}>
              <p>Connecting paths cannot have more than 3 lines</p>
            </li>
            <li className={animationStep === 3 ? 'interactive-game-tutorial-step-active' : ''}>
              <p>Connecting paths must be unobstructed by other tiles</p>
            </li>
            <li>
              <p>You receive more points for completing a round faster</p>
            </li>
          </ol>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InteractiveTutorial;
