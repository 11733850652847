export const getFromLocalStorage = (key: string, defaultValue: string = '') => {
  try {
    const item = window.localStorage.getItem(key);
    return item ?? defaultValue;
  } catch {
    return defaultValue;
  }
};

export const setOnLocalStorage = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value);
  } catch {}
};
