import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from './Layout/Button';
import { GameModal, PauseModal, NextLevelModal, Confetti } from './Modals';
import RootState from '../types/index';
import { startLevel } from '../slices/game/actions';
import GameCompletedModal from './Modals/GameCompletedModal';
import GameButtons from './Game/GameButtons';
import GameOverModal from './Modals/GameOverModal';
import GameNav from './Game/GameNav';
import { CloseIcon } from '../images/icons';
import { selectActiveLevel } from '../slices/game/selectors';
import useGame from '../infrastructure/hooks/useGame';
import { StormPhaserGame } from '../game/types';
import { trackEvent } from '../infrastructure/tracking/GoogleAnalytics';
import trackPageViewGoogle from '../infrastructure/tracking';
import { gamePage, gameHelpModal } from '../types/pageNameContants';
import { InteractiveTutorial } from './Modals';

const GamePage = () => {
  const gameRef = useRef<StormPhaserGame>();
  const gameNavRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const activeLevel = useSelector(selectActiveLevel);
  const {
    showNextLevelModal,
    levelCompleted,
    totalScore,
    showGameOverModal,
    isManuallyPaused,
    showHelpModal,
    totalNumberOfLevels,
  } = useSelector((state: RootState) => state.game);

  const showHelp = isManuallyPaused && showHelpModal;

  const [config, formattedTime, showInitialModal, setShowInitialModal, setIsPaused, restartWithAutoStart] =
    useGame(gameRef);

  useEffect(() => {
    if (!showNextLevelModal && !showInitialModal) {
      const scrollTo = (gameNavRef.current?.getBoundingClientRect().top || 0) + window.scrollY;
      window.scrollTo(0, scrollTo);
    }
  }, [showNextLevelModal, showInitialModal]);

  useEffect(() => {
    if (!showNextLevelModal && !showInitialModal && !showGameOverModal && !isManuallyPaused) {
      trackPageViewGoogle(gamePage, '/game');
    }
  }, [showNextLevelModal, showInitialModal, showGameOverModal, isManuallyPaused]);

  useEffect(() => {
    if (showHelp) {
      trackPageViewGoogle(gameHelpModal, '/game/help');
    }
  }, [showHelp]);

  const resume = () => {
    trackEvent('Exit Tutorial', 'Exit Tutorial Selected', 'Game Help Modal');
    gameRef.current?.scene.keys.Game?.resumeGame();
    setIsPaused(false);
  };

  return (
    <>
      <div className="game">
        <div className="game-navigation">
          <div ref={gameNavRef}>
            <GameNav formattedTime={formattedTime} />
          </div>
          <GameButtons
            gameScene={gameRef.current?.scene.keys.Game}
            pauseTimer={() => setIsPaused(true)}
            showTooltips={showHelp}
          />
        </div>
        <div
          style={{ maxWidth: `${config.width}px` }}
          className={`game-container ${showHelp ? 'game-container-with-help' : ''}`}
        >
          <div id="phaser-game" style={{ overflow: 'visible', opacity: showHelp ? 0 : 1 }} />
          {showHelp && (
            <div className="game-overlay-container">
              <InteractiveTutorial />
              <Button variant="tutorial-exit" size="sm" iconBefore onClick={() => resume()}>
                <CloseIcon />
                Exit tutorial
              </Button>
            </div>
          )}
        </div>
      </div>
      <GameModal
        show={showInitialModal}
        showRestart={activeLevel > 1}
        restart={restartWithAutoStart}
        hideModal={() => {
          gameRef.current?.scene.keys.Game?.startGame(activeLevel > 1 ? totalScore : 0);
          setIsPaused(false);
          setShowInitialModal(false);
        }}
      />
      <NextLevelModal
        show={showNextLevelModal && levelCompleted < totalNumberOfLevels}
        hideModal={() => {
          dispatch(startLevel());
          gameRef.current?.scene.keys.Game?.startGame(activeLevel > 1 ? totalScore : 0);
          setIsPaused(false);
        }}
      />
      <GameCompletedModal
        show={showNextLevelModal && levelCompleted === totalNumberOfLevels}
        hideModal={restartWithAutoStart}
      />
      <GameOverModal hideModal={restartWithAutoStart} />
      <PauseModal
        gameScene={gameRef.current?.scene.keys.Game}
        resumeTimer={() => setIsPaused(false)}
        formattedTime={formattedTime}
      />
      {showNextLevelModal && <Confetti repeat />}
    </>
  );
};

export default GamePage;
