import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import isWebViewReducer from '../isWebView/reducer';
import userReducer from '../../slices/users/reducer';
import gameReducer from '../../slices/game/reducer';
import sweepstakeReducer from '../../slices/sweepstakes/reducer';
import shareReducer from '../../slices/sharing/reducer';
import promotionReducer from '../../slices/promotion/reducer';
import gameConfigReducer from '../../slices/gameconfig/reducer';
// import adReducer from '../../slices/ads/reducer';
import groupInviteReducer from '../../slices/groupinvite/reducer';

const rootReducer = (history) =>
  combineReducers({
    isWebView: isWebViewReducer,
    router: connectRouter(history),
    user: userReducer,
    game: gameReducer,
    sweepstake: sweepstakeReducer,
    share: shareReducer,
    promotion: promotionReducer,
    gameConfig: gameConfigReducer,
    groupInvite: groupInviteReducer,
    // ads: adReducer,
  });

export default rootReducer;
