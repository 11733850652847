import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from '../Layout/Button';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import useWindowDimensions from '../../infrastructure/hooks/useWindowDimensions';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import CountUp from 'react-countup';
import { numberWithCommas } from '../../helpers/index';
import ModalTuneIn from './ModalTuneIn';
import { selectIsCurrentSweepstake } from '../../slices/sweepstakes/selectors';
import timeIcon from '../../images/game/time.svg';
import scoreIcon from '../../images/game/points.svg';

interface GameCompletedModalProps {
  show: boolean;
  hideModal: () => void;
}

const GameCompletedModal: React.FC<GameCompletedModalProps> = ({ show, hideModal }) => {
  const [showTotalScore, setShowTotalScore] = useState<boolean>(false);
  const {
    totalScore: score,
    totalScoreBeforeBonus,
    completionTime: time,
    loading,
  } = useSelector((state: RootState) => state.game);
  const { windowWidth } = useWindowDimensions();

  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);
  useEffect(() => {
    if (!show || windowWidth < 768) {
      setShowTotalScore(false);
    }
    const timer = setTimeout(() => setShowTotalScore(true), 1800);

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  // const imagesLoaded = useImages([SNSLogoHorizontal, SNSBackground.Fair, SNSBackground.People, SNSBackground.Stalls]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => hideModal()}
      className="level-modal"
    >
      <Modal.Header>
        <h3>Congratulations!</h3>
        <div className="modal-time-score-container">
          <div className="modal-time-score">
            <h4 className="modal-time-score__title">COMPLETION TIME</h4>
            <div className="modal-time-score__content">
              <img src={timeIcon} alt="Time" className="red-blur" />
              <span className="modal-time-score__value">{time}</span>
            </div>
          </div>

          <div className="modal-time-score">
            <h4 className="modal-time-score__title">TOTAL SCORE</h4>
            <div className="modal-time-score__content score-table_total-score">
              <img src={timeIcon} alt="Time" className="red-blur" />
              <span className="modal-time-score__value">
                <span className="">
                  {/* {show && showTotalScore && ( */}
                  <CountUp end={score} start={totalScoreBeforeBonus} duration={2} delay={0} separator="," />
                  {/* )} */}
                </span>
                {show && (
                  <div className="time-bonus">
                    <p>+{numberWithCommas(score - totalScoreBeforeBonus)}</p>
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {isCurrentSweepstake && (
          <p className="completed">Play again tomorrow to earn even more entries, or now just for fun!</p>
        )}
        <Button
          variant="primary"
          size="lg"
          withArrow
          className="mb-3"
          onClick={() => {
            trackEvent('Play Again', 'Play Again Selected', 'Game Complete Modal');
            hideModal();
          }}
          disabled={loading}
        >
          Play Again
        </Button>
        <div className="btn-row">
          <Link to="/leaderboard" className="px-1">
            <Button
              variant="outline-primary"
              onClick={() => {
                trackEvent('Go To Leaderboard', 'Leaderboard Button Selected', 'Game Complete Modal');
              }}
            >
              Leaderboard
            </Button>
          </Link>

          <Link to="/profile" className="px-1">
            <Button
              variant="outline-primary"
              onClick={() => {
                trackEvent('Play Again', 'Play Again Selected', 'Game Complete Modal');
                hideModal();
              }}
              disabled={loading}
            >
              Profile
            </Button>
          </Link>
        </div>
      </Modal.Body>
      <ModalTuneIn label="Game Complete Modal" showInvite />
    </Modal>
  );
};

export default GameCompletedModal;
