import Cookies from 'js-cookie';

const cookieKey = '_sweepdata';
const emailPropertyKey = 'emailAddress';

const getJsonFromCookie: () => { [key: string]: string } = () => {
  let cookieData: string | undefined = undefined;
  try {
    cookieData = Cookies.get(cookieKey);
    if (cookieData) {
      try {
        return JSON.parse(cookieData);
      } catch (e) {
        Cookies.remove(cookieKey, { domain: getDomain(), secure: true });
      }
    }
  } catch {}
  return {};
};

const mergeData = (newData: { [key: string]: string }, previousData: { [key: string]: string }) => {
  // If email address is different then overwrite the previous data
  if (!previousData[emailPropertyKey] || previousData[emailPropertyKey] !== newData[emailPropertyKey]) return newData;
  return { ...previousData, ...newData };
};

// This should be called on the initial state and set on form
export const setInitialFromStateFromValuesOnCookie: <T extends { [key: string]: any }>(initialState: T) => T = (
  initialState
) => {
  const cookieData = getJsonFromCookie();
  Object.keys(initialState).forEach((key) => {
    if (cookieData[key]) (initialState as { [key: string]: any })[key] = cookieData[key];
  });
  return initialState;
};

// This should be called on the submit of the form with the form data
export const setFormValuesOnCookie = (formValues: object) => {
  const stringsOnly = Object.entries(formValues)
    .filter(([_, v]) => {
      return typeof v === 'string';
    })
    .reduce((newObject: { [key: string]: string }, [k, v]) => {
      newObject[k] = v;
      return newObject;
    }, {});
  const existingData = getJsonFromCookie();
  try {
    Cookies.set(cookieKey, JSON.stringify(mergeData(stringsOnly, existingData)), {
      domain: getDomain(),
      expires: 365,
      secure: true,
    });
  } catch {}
};

const getDomain = () => window.config?.cookieDomain || '.hallmarkchannel.com';
