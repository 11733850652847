import React from 'react';

const LeaderboardIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 21.8H17.5V24H6.5V21.8ZM21.9 3.1H18.2942C18.1026 2.76666 17.8268 2.48955 17.4943 2.29647C17.1619 2.1034 16.7845 2.00116 16.4 2H7.6C7.21554 2.00116 6.83814 2.1034 6.50567 2.29647C6.1732 2.48955 5.89735 2.76666 5.7058 3.1H2.1C1.80826 3.1 1.52847 3.21589 1.32218 3.42218C1.11589 3.62847 1 3.90826 1 4.2V8.6C0.999938 8.76496 1.03698 8.92783 1.10838 9.07654C1.17979 9.22525 1.28372 9.356 1.4125 9.4591L6.83 13.7964C7.84323 15.073 9.29297 15.9295 10.9 16.201V17.4H8.7C8.40826 17.4 8.12847 17.5159 7.92218 17.7222C7.71589 17.9285 7.6 18.2083 7.6 18.5V20.7H16.4V18.5C16.4 18.2083 16.2841 17.9285 16.0778 17.7222C15.8715 17.5159 15.5917 17.4 15.3 17.4H13.1V16.201C14.707 15.9295 16.1568 15.073 17.17 13.7964L22.5919 9.4591C22.7199 9.35556 22.823 9.22462 22.8936 9.07594C22.9642 8.92725 23.0006 8.76461 23 8.6V4.2C23 3.90826 22.8841 3.62847 22.6778 3.42218C22.4715 3.21589 22.1917 3.1 21.9 3.1ZM3.2 8.072V5.3H5.4V9.7C5.4 9.7462 5.4066 9.7913 5.4066 9.8375L3.2 8.072ZM20.8 8.072L18.5934 9.8375C18.5934 9.7913 18.6 9.7462 18.6 9.7V5.3H20.8V8.072Z"
        fill="#652B82"
      />
      <defs>
        <linearGradient id="gradient" x1="20" y1="22" x2="3.3939" y2="9.70365" gradientUnits="userSpaceOnUse">
          <stop stop-color="#BB0F8B" />
          <stop offset="1" stop-color="#FFBC39" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeaderboardIcon;
