import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import usePageView from '../infrastructure/hooks/usePageView';
import RootState from '../types';
import { rulesPage } from '../types/pageNameContants';
import { LandingRoute } from '../constants/Routes';
import useLinkTarget from '../infrastructure/isWebView/useLinkTarget';
import { Link } from 'react-router-dom';

type SweepsDatesDictionary = {
  [key: string]: { start: string; end: string };
};

const sweepDates: SweepsDatesDictionary = {
  'Week 1': { start: '6:00 AM ET on October 20, 2022', end: '11:59 PM ET on October 28, 2022' },
  'Week 2': { start: '12:00 AM ET on October 29, 2022', end: '11:59 PM ET on November 4, 2022' },
  'Week 3': { start: '12:00 AM ET on November 5, 2022', end: '11:59 PM ET on November 11, 2022' },
  'Week 4': { start: '12:00 AM ET on November 12, 2022', end: '11:59 PM ET on November 18, 2022' },
  'Week 5': { start: '12:00 AM ET on November 19, 2022', end: '11:59 PM ET on November 25, 2022' },
  'Week 6': { start: '12:00 AM ET on November 26, 2022', end: '11:59 PM ET on December 2, 2022' },
  'Week 7': { start: '12:00 AM ET on December 3, 2022', end: '11:59 PM ET on December 9, 2022' },
  'Week 8': { start: '12:00 AM ET on December 10, 2022', end: '11:59 PM ET on December 16, 2022' },
  'Week 9': { start: '12:00 AM ET on December 17, 2022', end: '11:59 PM ET on December 23, 2022' },
  'Week 10': { start: '12:00 AM ET on December 24, 2022', end: '6:00 AM ET on January 1, 2023' },
};

const RulesPage = () => {
  usePageView(rulesPage, '/rules');
  const linkTarget = useLinkTarget();

  const { currentSweepstakeName } = useSelector((state: RootState) => state.sweepstake);

  if (!currentSweepstakeName) {
    return <Redirect to={LandingRoute} />;
  }

  const dates = sweepDates[currentSweepstakeName];
  return (
    <Container className="rules">
      <Row>
        <Col xs={12}>
          <h4>
            <strong>HALLMARK CHANNEL’S LOVEUARY MATCHING MOMENTS SWEEPSTAKES</strong>
            <br />
            OFFICIAL RULES
          </h4>
          <p>
            <strong>
              NO PURCHASE NECESSARY TO ENTER OR WIN. MAKING A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING.
            </strong>
          </p>
          <p>
            <strong>
              ALL DISPUTES WILL BE RESOLVED SOLELY BY BINDING ARBITRATION AND ENTRANTS WAIVE THE ABILITY TO BRING CLAIMS
              IN A CLASS ACTION FORMAT.
            </strong>
          </p>

          <p>
            <strong>SWEEPSTAKES ENTRY PERIODS: Hallmark Channel’s Loveuary Matching Moments Sweepstakes</strong> (the
            “Sweepstakes”) begins at 10:00:00 AM Eastern Time (“ET”) on Monday, January 29, 2024 and ends at 11:59:59 PM
            ET on Sunday, February 25, 2024 (the “Sweepstakes Period”). Within the Sweepstakes Period, there are four
            (4) entry periods (each, an “Entry Period”). Each Entry Period will have a corresponding random drawing for
            the prize(s) listed as defined in the “Entry Periods & Drawings Schedule” below. At the end of the
            Sweepstakes Period, one (1) random drawing will be conducted from among all eligible entries received
            throughout the Sweepstakes Period to determine one (1) Grand Prize winner (the “Grand Prize Drawing”).
            Entries must be received during an Entry Period to be eligible for that Entry Period’s drawing and prize.
            Non-winning entries for an Entry Period will not roll over to subsequent Entry Periods. Sponsor’s computer
            is the official timekeeping device for this Sweepstakes.
          </p>

          <h5 className="table_heading">
            <strong>ENTRY PERIODS & DRAWINGS SCHEDULE</strong>
          </h5>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>Entry Period # </th>
                <th colSpan={2}>
                  ENTRY PERIODS <br /> All eligible entries must be received between:{' '}
                </th>
                <th rowSpan={2}>Drawing Date: on or around:</th>
                <th rowSpan={2}># of Prizes per Entry Period</th>
                <th rowSpan={2}>Entry Period Prize Available to be won:</th>
              </tr>
              <tr>
                <th>Entry Period Start:</th>
                <th>
                  Entry Period End: <br /> 11:59:59 PM ET on:
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>1</th>
                <td>10:00:00 AM ET on 01/29/24</td>
                <td>02/04/24</td>
                <td>02/07/24</td>
                <td>1</td>
                <td>Period 1 Prize</td>
              </tr>
              <tr>
                <th>2</th>
                <td>12:00:00 AM ET on 02/05/24</td>
                <td>02/11/24</td>
                <td>02/14/24</td>
                <td>1</td>
                <td>Period 2 Prize </td>
              </tr>
              <tr>
                <th>3</th>
                <td>12:00:00 AM ET on 02/12/24</td>
                <td>02/18/24</td>
                <td>02/21/24</td>
                <td>5</td>
                <td>Period 3 Prize </td>
              </tr>
              <tr>
                <th>4</th>
                <td>12:00:00 AM ET on 02/19/24</td>
                <td>02/25/24</td>
                <td>02/28/24</td>
                <td>5</td>
                <td>Period 4 Prize </td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>ELIGIBILITY:</strong> The Sweepstakes is open to legal residents of the 50 United States, including
            the District of Columbia, who are age 21 or older at the time of entry. Void in Puerto Rico, Guam, the U.S.
            Virgin Islands, outside the U.S., and wherever else prohibited by law. Employees of Hallmark Media United
            States LLC (the "Sponsor"), US Sweepstakes & Fulfillment Company (the “Sweepstakes Administrator”), Princess
            Cruise Lines, Ltd. and their respective subsidiaries, affiliates, advertising and promotion agencies, and
            the immediate family members (spouses, parents, children, and siblings and their spouses) of, and/or those
            living in the same household of each, are not eligible to enter. Sweepstakes is subject to all applicable
            federal, state and local laws and regulations.
          </p>

          <p>
            <strong>HOW TO ENTER:</strong> There are three (3) methods of earning entries:
          </p>

          <p>
            <strong className="underline">Method #1 – Registration Entry:</strong> During an Entry Period, participants
            must visit <Link to={LandingRoute}>https://matchingmoments.hallmarkchannel.com/</Link> (the “Website”),
            follow the instructions to read and accept these Official Rules, the{' '}
            <a href="https://www.hallmarkchannel.com/terms-of-use" target="_blank" rel="noreferrer noopener">
              Terms of Use
            </a>{' '}
            and{' '}
            <a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank" rel="noreferrer noopener">
              Privacy Policy
            </a>{' '}
            and click the Enter Now button, then complete the entry form in its entirety and click the Start Playing
            button to register for the Sweepstakes (the “Registration”) and receive one (1) entry into the drawing for
            that Entry Period and one (1) entry into the Grand Prize Drawing (the “Registration Entry”).{' '}
            <strong>Limit: One (1) Registration Entry per person throughout the Sweepstakes Period.</strong>
          </p>

          <p>
            <strong className="underline">Method #2 – Returning User Entry:</strong> For any day following the day a
            participant completes a Registration, participant may return to the Website to automatically receive one (1)
            entry into the drawing for that Entry Period and one (1) entry into the Grand Prize drawing (the “Returning
            User Entry”). <strong>Limit: One (1) Returning User Entry per person per day.</strong> IMPORTANT:
            Participants must be logged in to his/her account to earn Returning User Entries. Entries are limited by
            email address so in order to earn his/her Returning User Entry, Participants must be logged with the email
            address they used to register for the Sweepstakes. Logged out Participants must log into the Website by
            completing the entry form again using the same email address they initially registered with in order to
            receive his/her Returning User Entry
          </p>

          <p>
            <strong>Method #3 – Game Entry:</strong> During an Entry Period, participants may return to the Website and
            play the Matching Moments game (the “Game”) per the onscreen instructions. For each level completed in the
            Game, participant will earn one (1) additional entry into the drawing for that Entry Period and one (1)
            entry into the Grand Prize Drawing (the “Game Entry”).{' '}
            <strong>
              Limit: Eleven (11) Game Entries per person per day regardless of the number of levels completed.
            </strong>{' '}
            IMPORTANT: Participants must be logged in to his/her account to earn Game Entries.
          </p>

          <p>
            Entrants will have the opportunity to earn additional entries into the Grand Prize drawing (each, a “Bonus
            Entry”) by watching the movie preview displayed after the first level entrant completes in the Game (each, a
            “Preview”) in its entirety to earn one (1) additional entry into the drawing for that Entry Period and one
            (1) additional entry into the Grand Prize Drawing (the “Bonus Entry”). IMPORTANT: Participants must be
            logged in to his/her account to earn Bonus Entries.{' '}
            <strong>Limit: One (1) Bonus Entry per person per Entry Period.</strong>
          </p>

          <p>
            Registration Entry, Returning User Entry, Game Entry and Bonus Entry collectively referred to herein as
            “Entry” or “Entries”.
          </p>

          <p>
            Entrants are subject to all notices posted online including but not limited to the Sponsor’s Privacy Policy,
            which can be found at{' '}
            <a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank" rel="noreferrer noopener">
              Privacy Policy
            </a>
            . All entries become the property of the Sponsor and will not be acknowledged or returned.
          </p>

          <p>
            <strong>PRIZES/APPROXIMATE RETAIL VALUE (“ARV”)/ODDS:</strong>
          </p>

          <p>
            <strong>Grand Prize:</strong> There is one (1) Grand Prize available to be won. Grand Prize includes one (1)
            $3,100 Princess Cruises promotional card that may be used towards a 4- to 7-day domestic cruise to Alaska,
            California Coast/Mexico, Caribbean or Europe for up to two people on a Princess Cruise and $2,100 awarded in
            the form of a check winner may use towards transportation to/from the point of embarkment/disembarkment for
            cruises, lodging or any other expenses. Cruises are subject to availability. Promotional card may not be
            used toward transportation to/from the point of embarkment/disembarkment for cruises. Winner and his/her
            guest are solely responsible for coordinating transportation. Promotional card expires March 31, 2025 and
            any cruise booked using such card must be completed by such date. If for any reason, a winner does not use
            their promotional card by March 31, 2025, then such promotional card shall be void and the winner will
            forfeit their right to the prize. The difference between cruise, travel, and all other costs, fees and
            expenses and the promotional card shall be the sole responsibility of the winner.{' '}
            <strong>The ARV of the Grand Prize is: $5,200.</strong>
          </p>

          <p>
            <strong>Period 1 Prize:</strong> There is one (1) Period 1 Prize available to be won. Period 1 Prize
            includes:
          </p>

          <ul className="text-left">
            <li>One (1) Jane Austen Penguin House Clothbound set of 7 books;</li>
            <li>
              One (1) Paging Mr. Darcy prop package including one (1) tea pot set with cups and saucers, one (1) copy of
              the book Prize and Prejudice and one (1) hand fan; and
            </li>
            <li>One (1) package of Bissinger’s chocolates.</li>
            <li>
              <strong>ARV of the Period 1 Prize is $325.</strong>
            </li>
          </ul>

          <p>
            <strong>Period 2 Prize:</strong> There is one (1) Period 2 Prize available to be won. Period 2 Prize
            includes:
          </p>

          <ul className="text-left">
            <li>One (1) Jane Austen Penguin House Clothbound set of 7 books;</li>
            <li>One (1) Little Free Library kit (includes assembled library structure and post); and</li>
            <li>One (1) package of Bissinger’s chocolates.</li>
            <li>
              <strong>ARV of the Period 2 Prize is $525.</strong>
            </li>
          </ul>

          <p>
            <strong>Period 3 Prizes:</strong> There are five (5) Period 3 Prizes available to be won. Each Period 3
            Prize includes:
          </p>

          <ul className="text-left">
            <li>One (1) Jane Austen-themed accessory pouch;</li>
            <li>One (1) Jane Austen book design handbag;</li>
            <li>One (1) Pride and Prejudice-themed scarf; and</li>
            <li>One (1) package of Bissinger’s chocolates.</li>
            <li>
              <strong>ARV of the Period 3 Prize is $202 each.</strong>
            </li>
          </ul>

          <p>
            <strong>Period 4 Prizes:</strong> There are five (5) Period 4 Prizes available to be won. Each Period 4
            Prize includes:
          </p>

          <ul className="text-left">
            <li>One (1) 2024 Mahogany calendar;</li>
            <li>One (1) Self Love is the Assignment journal;</li>
            <li>One (1) Valentine’s Day Love Assortment, pack of 4 cards;</li>
            <li>One (1) Valentine’s Day Morning Afternoon, Night Assortment, pack of 3 cards;</li>
            <li>One (1) package of Bissinger’s chocolates; </li>
            <li>One (1) Sense and Sensibility 3 teacups and saucers; and </li>
            <li>One (1) Know Your Own Happiness scented candle.</li>
            <li>
              <strong>ARV of the Period 4 Prize is $237 each.</strong>
            </li>
          </ul>

          <p>
            The total ARV of all prizes available to be won: <strong>$8,245</strong>.
          </p>

          <p>
            Prizes may differ from what is depicted in Sweepstakes advertising or the Website. There is a limit of one
            (1) prize per person per Entry Period. An individual who is a winner of a prize during Entry Periods 1-4 is
            still eligible to win the Grand Prize. Odds of winning will depend upon the total number of eligible entries
            received for each drawing.
          </p>

          <p>
            <strong>RANDOM DRAWING:</strong> The potential Period 1–4 Prize winners will be selected in a series of
            random drawings from among all eligible entries received within the corresponding Entry Period on or about
            the corresponding Random Drawing Date indicated in the Entry Periods & Drawings Schedule in the “Sweepstakes
            Entry Periods” section of these Official Rules.{' '}
          </p>

          <p>
            The potential Grand Prize will be selected in a random drawing on or about{' '}
            <strong>Wednesday, February 28, 2024</strong> from among all eligible entries received for the Grand Prize
            Drawing during the entire Sweepstakes Period.
          </p>

          <p>
            All drawings will be conducted by the Sweepstakes Administrator, an independent representative of the
            Sponsor whose decisions are final.
          </p>

          <p>
            <strong>WINNER NOTIFICATION & VERIFICATION:</strong> Potential Grand Prize winner will be notified via email
            and will be required to sign and return, within seven (7) days of notification, an Affidavit of Eligibility,
            a Liability Waiver, an IRS W-9 Form and where allowable, a Publicity Release (collectively, "the Releases").
            These Releases will require the winner to furnish his/her Social Security Number for the sole purpose of tax
            reporting, as required by law. Noncompliance will result in disqualification and an alternate winner may be
            selected.
          </p>

          <p>
            Potential Period 1-4 Prize winners will be notified by email and required to confirm their mailing address
            within 48 hours via return email. Noncompliance will result in disqualification and an alternate winner may
            be selected.
          </p>

          <p>
            <strong className="underline">
              Prizes will be awarded within approximately 45 days after the winner is verified.
            </strong>
          </p>

          <p>
            If a prize notification or prize is returned as undeliverable, or if winner is found to be ineligible or not
            in compliance with these Official Rules that winner will be disqualified, and the prize may be awarded to an
            alternate winner in a separate random drawing. If Sponsor is unable to determine and verify a potential
            winner after repeated alternate drawings or if Sponsor fails to receive a sufficient number of entries to
            correspond to the number of prizes available to be awarded, Sponsor reserves the right to not award such
            prize(s).
          </p>

          <p>
            <strong>PRIZE CONDITIONS:</strong> Prizes are not redeemable for cash, assignable, transferable and may not
            be substituted except at Sponsor’s sole discretion. Sponsor reserves the right to substitute a prize of
            equal or greater value at its discretion. Any other incidental expenses on prize not specified herein are
            the winner’s sole responsibility.
          </p>

          <p>
            Princess Cruises promotional cards are subject to Princess Cruises’ terms and conditions (including the
            passage contract) and may be used towards cruise fares, which only includes regular onboard meals and
            entertainment, applicable government fees and taxes. Winner can select dates of the cruise, which will be
            subject to the Released Parties’ approval, and may be subject to certain restrictions and blackout dates,
            but must be used by the expiration date listed above. No alternative travel dates will be made available.
            <strong>
              TRANSPORTATION TO AND FROM THE POINT OF EMBARKMENT/DISEMBARKMENT FOR CRUISES IS NOT INCLUDED.
            </strong>{' '}
            No additional transportation or lodging accommodations will be provided by the Released Parties. Each
            winner’s guest (if applicable) must be 21 years of age or older as of the date of cruise departure unless
            the winner is the guest’s parent and/or legal guardian. Any guests who are minors in his or her state of
            residence must be accompanied at all times during the cruise (including, but not limited to, cruise
            activities, excursions and all other cruise-related events) by his/her parent or legal guardian. Released
            Parties are not responsible for any delay or cancellation, delays, diversions, or substitution of the prize
            or any portion of the prize due to unforeseen circumstances, or those outside of Sponsor’s control. In the
            event of delay or cancellation, the prize, or portion thereof, will not be awarded, and Sponsor will not
            provide any compensation or substitution for that portion of the prize.
          </p>

          <p>
            Winner is responsible for satisfying all conditions of travel including reviewing the COVID19 and related
            policy issues on the Princess Cruise website. Prize will be fulfilled by the Sponsor’s designated agent.
          </p>

          <p>
            All expenses and incidental travel costs not covered by the promotional card are the sole responsibility of
            the winner.
          </p>

          <p>
            Prepaid cards are not redeemable for cash and will not be replaced if lost or stolen. Prepaid card usage is
            subject to the issuing company’s complete terms and conditions, including expiration dates.
          </p>

          <p>
            <strong className="underline">
              Winner is responsible for all federal, state, local and income taxes associated with winning prize. Grand
              Prize winner will be required to furnish his/her Social Security Number for the sole purpose of
              preparation of tax forms as required by law.
            </strong>{' '}
            Except where prohibited by law, entry and acceptance of prize constitute permission for the Sponsor to use
            winner's name, prize won, hometown, likeness, video recordings, photographs, and statements for purposes of
            advertising, promotion and publicity (including online posting) in any and all media now or hereafter known
            throughout the world in perpetuity, without additional compensation, notification or permission. Sponsor may
            request that winners share with Sponsor a photograph of the winner with his/her prize or as otherwise
            requested, and/or a statement to use as mentioned herein. Providing such photograph or statement does not
            guarantee its use by Sponsor.
          </p>

          <p>
            Prizes are awarded “as is” with no warranty or guarantee, either express or implied by Sponsor. Winners
            acknowledge that the Sponsor and all other businesses affiliated with this Sweepstakes and their agents do
            not make, nor are in any manner responsible for any warranty, representations, expressed or implied, in fact
            or in law, relative to the quality, conditions, fitness or merchantability of any aspect of the prize being
            offered, except that each merchandise prize shall be subject to its manufacturer’s standard warranty (if
            any). Once the prize has been delivered to the winner’s provided address, the prize is considered awarded.
          </p>

          <p>
            <strong>GENERAL:</strong> Although subsequent attempts to enter during a given Entry Period may be received,
            only the first complete entry received from a particular entrant will be eligible; subsequent attempts by
            the same person to enter, including entries submitted with an alternate email address, will be disqualified.
          </p>

          <p>
            Participating entrants agree to these Official Rules and the decisions of the Sponsor and the Sweepstakes
            Administrator, and release the Sponsor, the Sweepstakes Administrator, Princess Cruise Lines, Ltd., and
            their affiliated companies, and all other businesses involved in this Sweepstakes, as well as the employees,
            officers, directors and agents of each (collectively, the “Released Parties”), from all claims and liability
            relating to their participation in the Sweepstakes, and the acceptance and use/misuse of the prize offered.
            Participating entrants assume all liability for and Released Parties shall be held harmless against any
            liability for any injury, losses or damages of any kind to persons, including personal injury or death, or
            injury to property caused or claimed to be caused in whole or in part, directly or indirectly, by
            participation in this Sweepstakes, acceptance, possession, or use/misuse of the prize, or any claims based
            on publicity rights, defamation or invasion of privacy, or merchandise delivery. Sponsor is not responsible
            for any typographical or other error in the printing of the offer, administration of the Sweepstakes or in
            the announcement of the prize.
          </p>

          <p>
            In the event of a dispute over the identity of an entrant, entry will be deemed submitted by the "Authorized
            Account Holder" of the e-mail address submitted at time of entry. Authorized Account Holder means the
            natural person who is assigned to an e-mail address by an Internet access provider, online service provider,
            or other organization that is responsible for assigning e-mail addresses for the domain associated with the
            submitted e-mail address. Sponsor may ask any entrant or potential winner to provide Sponsor with proof, to
            Sponsor’s satisfaction, that such party is the authorized account holder of the email address associated
            with the entry. Entry materials/data that have been tampered with or altered, or mass entries or entries
            generated by a script, macro or use of automated devices are void. The Released Parties are not responsible
            for: (i) lost, late, misdirected, damaged or illegible entries; or (ii) error, omission, interruption,
            deletion, defect, delay in operations or transmission, theft or destruction or unauthorized access to or
            alterations of entry materials, or for technical, network, telephone equipment, electronic, computer,
            hardware or software malfunctions of any kind, or inaccurate transmission of or failure to receive entry
            information by Sponsor on account of technical problems or traffic congestion on the Internet or at any web
            site or any combination thereof; or (iii) any injury or damage to entrant's or any other person's computer
            related to or resulting from participating in the Sweepstakes. By participating in the Sweepstakes, entrant
            (i) agrees to be bound by these Official Rules, including all eligibility requirements, and (ii) agree to be
            bound by the decisions of Sponsor and the Sweepstakes Administrator, which are final and binding in all
            matters relating to the Sweepstakes. Failure to comply with these Official Rules may result in
            disqualification from the Sweepstakes.
          </p>

          <p>
            Sponsor reserves the right to cancel, suspend and/or modify the Sweepstakes, or any part of it, if any
            fraud, technical failures or any factor beyond Sponsor’s reasonable control impairs the integrity or proper
            functioning of the Sweepstakes, as determined by Sponsor. If, for any reason, the Sweepstakes cannot be run
            as planned, Sponsor may disqualify any suspect entries or individuals from the Sweepstakes and any
            sweepstakes it sponsors and (a) suspend the Sweepstakes and modify the Sweepstakes to address the
            impairment, then resume the Sweepstakes in a manner that best conforms to the spirit of these Official
            Rules; and/or (b) award prizes at random from among the eligible, non-suspect entries received up to the
            time of the impairment.
          </p>

          <p>
            THE RELEASED PARTIES ARE NOT RESPONSIBLE IF THIS SWEEPSTAKES CANNOT BE ADMINISTERED OR CONDUCTED OR ANY
            PRIZE CANNOT BE AWARDED DUE TO CANCELLATIONS, DELAYS, OR INTERRUPTIONS RESULTING OR ARISING FROM ACTS OF
            GOD, WAR, OR TERRORISM, CIVIL UNREST, STRIKES, SUPPLY SHORTAGES, NATURAL DISASTERS, WEATHER, EPIDEMICS,
            COMPLIANCE WITH ANY LAW OR ORDER OF A GOVERNMENTAL AUTHORITY, OR ANY OTHER SIMILAR ACT, EVENT, OR OCCURRENCE
            BEYOND THE REASONABLE CONTROL OF THE SPONSOR. BY PARTICIPATING IN THIS SWEEPSTAKES, PARTICIPANT AGREES THAT
            THE RELEASED PARTIES WILL NOT BE RESPONSIBLE OR LIABLE FOR ANY INJURIES, DAMAGES, OR LOSSES OF ANY KIND,
            INCLUDING DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES TO PERSONS, INCLUDING DEATH, OR
            TO PROPERTY ARISING OUT OF ACCESS TO AND USE OF ANY WEBSITE ASSOCIATED WITH THIS SWEEPSTAKES OR THE
            DOWNLOADING FROM AND/OR PRINTING MATERIAL DOWNLOADED FROM SUCH SITE, AS APPLICABLE. FURTHER, SPONSOR SHALL
            NOT BE RESPONSIBLE FOR ANY CANCELLATIONS, DELAYS, DIVERSIONS, CHANGES IN SERVICE OR ACCOMMODATIONS OR
            SUBSTITUTIONS, OR FOR ANY ACTS OR OMISSIONS BY ANY THIRD PARTIES BEYOND ITS REASONABLE CONTROL, INCLUDING
            AIR CARRIER(S) AND OTHER TRANSPORTATION COMPANIES; LODGING, RESTAURANT OR OTHER HOSPITALITY PROVIDERS;
            ENTERTAINMENT PROVIDERS, VENUES OR ARTISTS; OR OTHER THIRD PARTY PROVIDERS SUPPLYING ANY SERVICES OR
            COMPONENTS OF THE PRIZE(S) TO WINNERS AND/OR THEIR GUESTS, OR FOR ANY RESULTING INJURIES, INCLUDING MONEY
            DAMAGES, COSTS AND EXPENSES, PROPERTY DAMAGES, PERSONAL INJURIES OR DEATH RESULTING THEREFROM.
          </p>

          <p>
            <strong>LEGAL WARNING:</strong> ANY ATTEMPT BY AN INDIVIDUAL, WHETHER OR NOT AN ENTRANT, TO DAMAGE, DESTROY,
            TAMPER OR VANDALIZE THIS WEB SITE OR INTERFERE WITH THE OPERATION OF THE SWEEPSTAKES, IS A VIOLATION OF
            CRIMINAL AND CIVIL LAWS AND SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES AND DILIGENTLY PURSUE ALL REMEDIES
            AGAINST ANY SUCH INDIVIDUAL TO THE FULLEST EXTENT PERMITTED BY LAW.
          </p>

          <p>
            <strong>GOVERNING LAW AND LIMITATION OF LIABILITY:</strong> All issues and questions concerning the
            construction, validity, interpretation and enforceability of these Official Rules or the rights and
            obligations of entrants, Sponsor or the Released Parties in connection with the Sweepstakes will be governed
            by and construed in accordance with the internal laws of the State of California, without giving effect to
            any choice of law or conflict of law rules or provisions that would cause the application of any other laws.
          </p>

          <p>
            BY ENTERING THE SWEEPSTAKES, ENTRANT AGREES THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW: (A) ANY AND ALL
            DISPUTES, CLAIMS AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE SWEEPSTAKES, OR ANY PRIZE
            AWARDED, WILL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION;
          </p>

          <p>
            (B) ANY AND ALL CLAIMS, JUDGMENTS AND AWARDS WILL BE LIMITED TO ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS
            INCURRED (IF ANY) NOT TO EXCEED TEN DOLLARS ($10.00), BUT IN NO EVENT WILL ATTORNEYS’ FEES BE AWARDED OR
            RECOVERABLE; (C) UNDER NO CIRCUMSTANCES WILL ANY ENTRANT BE PERMITTED TO OBTAIN ANY AWARD FOR, AND ENTRANT
            HEREBY KNOWINGLY AND EXPRESSLY WAIVES ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL, CONSEQUENTIAL OR SPECIAL
            DAMAGES, LOST PROFITS AND/OR ANY OTHER DAMAGES, OTHER THAN ACTUAL OUT OF POCKET EXPENSES NOT TO EXCEED TEN
            DOLLARS ($10.00), AND/OR ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED; AND (D) ENTRANTS’
            REMEDIES ARE LIMITED TO A CLAIM FOR MONEY DAMAGES (IF ANY) AND ENTRANT IRREVOCABLY WAIVES ANY RIGHT TO SEEK
            INJUNCTIVE OR EQUITABLE RELIEF. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY,
            SO THE ABOVE MAY NOT APPLY TO YOU.
          </p>

          <p>
            <strong>DISPUTES:</strong> The parties hereto each agree to finally settle all disputes only through
            arbitration; provided, however, the Released Parties shall be entitled to seek injunctive or equitable
            relief in the state and federal courts in Los Angeles County, CA and any other court with jurisdiction over
            the parties. In arbitration, there is no judge or jury and review is limited. The arbitrator’s decision and
            award is final and binding, with limited exceptions, and judgment on the award may be entered in any court
            with jurisdiction. The parties agree that, except as set forth above, any claim, suit, action or proceeding
            arising out of or relating to this Sweepstakes shall be resolved solely by binding arbitration before a sole
            arbitrator under the streamlined Arbitration Rules Procedures of JAMS Inc. (“JAMS”) or any successor to
            JAMS. In the event JAMS is unwilling or unable to set a hearing date within fourteen (14) days of the filing
            of a “Demand for Arbitration”, then either party can elect to have the arbitration administered by the
            American Arbitration Association (“AAA”) or any other mutually agreeable arbitration administration service.
            If an in-person hearing is required, then it will take place in Studio City, CA. The federal or state law
            that applies to these Official Rules will also apply during the arbitration. Disputes will be arbitrated
            only on an individual basis and will not be consolidated with any other proceedings that involve any claims
            or controversy of another party, including any class actions; provided, however, if for any reason any court
            or arbitrator holds that this restriction is unconscionable or unenforceable, then the agreement to
            arbitrate doesn’t apply and the dispute must be brought in a court of competent jurisdiction in Studio City,
            CA. Sponsor agrees to pay the administrative and arbitrator’s fees in order to conduct the arbitration (but
            specifically excluding any travel or other costs of entrant to attend the arbitration hearing). Either party
            may, notwithstanding this provision, bring qualifying claims in small claims court.
          </p>

          <p>
            PRIVACY: As a condition of entering the Sweepstakes, each entrant gives consent for Sponsor to obtain and
            deliver his or her name, address and other information to third parties, including Sweepstakes
            Administrator, for the purpose of administering this Sweepstakes and to comply with applicable laws,
            regulations and rules, including, without limitation, the storing of your personal information for purposes
            of complying with state record retention requirements. Any information entrant provides to Sponsor may be
            used to communicate with entrant in relation to this Sweepstakes or on a Sweepstakes winner’s list. Personal
            information collected from entrants are subject to the Sponsor which can be found at{' '}
            <a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank" rel="noopener noreferrer">
              https://www.hallmarkchannel.com/privacy-policy
            </a>
            .
          </p>

          <p>
            <strong>OFFICIAL RULES REQUEST:</strong> To request a copy of the Official Rules, see{' '}
            <a href="https://matchingmoments.hallmarkchannel.com/" target="_blank" rel="noopener noreferrer">
              https://matchingmoments.hallmarkchannel.com/
            </a>{' '}
            or send a self-addressed, stamped envelope by February 28, 2024, to: Hallmark Channel’s Loveuary Matching
            Moments Sweepstakes <span className="underline">Official Rules Request</span>, PO Box 654, Social Circle, GA
            30025-0654.
          </p>

          <p>
            <strong>WINNER CONFIRMATION REQUEST:</strong> For a written confirmation of the winner (available after
            02/28/24 or once all winners are confirmed), send a stamped, self-addressed envelope (no later than
            03/28/24), to: Hallmark Channel’s Loveuary Matching Moments Sweepstakes{' '}
            <span className="underline">Winner Confirmation Request</span>, PO Box 654, Social Circle, GA 30025-0654.
          </p>

          <p>
            <strong>SPONSOR:</strong> Hallmark Media United States, LLC, 12700 Ventura Boulevard, Studio City, CA 91604.
          </p>

          <p>
            <strong>SWEEPSTAKES ADMINISTRATOR:</strong> US Sweepstakes & Fulfillment Company, 625 Panorama Trail, Suite
            2100, Rochester, NY 14625. 1-800-620-6044
          </p>

          <p>
            <strong>Void outside the US, in Guam, the U.S. Virgin Islands and wherever else prohibited by law.</strong>
          </p>

          <p>
            All trademarks used herein are the property of their respective owners in the United States and abroad. All
            rights reserved.
          </p>

          <p>
            Princess Cruise Lines, Ltd. is a prize provider and is not responsible for sponsoring or administrating this
            Sweepstakes in any way. All questions or concerns shall be directed to Sponsor and not to Princess Cruise
            Lines, Ltd.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default RulesPage;
