import React from 'react';

import { Modal } from 'react-bootstrap';
import { Logo } from '../../images/logos';
import GameTileAnimation from './GameTileAnimation';

interface IGameModalStandardBodyProps {
  newEntry: boolean;
  isCurrentSweepstake: boolean;
  totalNumberOfLevels: number;
}

const GameModalStandardBody: React.FC<IGameModalStandardBodyProps> = ({
  newEntry,
  isCurrentSweepstake,
  totalNumberOfLevels,
}) => (
  <>
    <Modal.Header>
      {newEntry && isCurrentSweepstake && <h3>You've entered!</h3>}

      {isCurrentSweepstake ? (
        <h5>
          Play <span className="bold link">Matching Moments</span> to earn one bonus entry per level, with each level
          getting harder!
        </h5>
      ) : (
        <Logo
          type="stacked"
          className="game-start-modal-logo"
          colors={isCurrentSweepstake ? ['#F08E1D', '#652B82'] : ['#F08E1D', '#652B82']}
        />
      )}
    </Modal.Header>
    <Modal.Body>
      <GameTileAnimation />
      <div className="intro-text-anim-container">
        <p className="intro-text-anim intro-text-anim-step1">
          The goal is to remove all tiles from the puzzle board as quickly as possible!
        </p>
        <p className="intro-text-anim intro-text-anim-step2 intro-text-anim-step3">
          Tap on identical tiles to create connecting paths between them!
        </p>
        <p className="intro-text-anim intro-text-anim-step4  intro-text-anim-step5">
          Connecting paths cannot have more than 3 lines!
        </p>
        <p className="intro-text-anim intro-text-anim-step6 intro-text-anim-step7">
          Clear paths must be made using a maximum of 3 lines!
        </p>
        <p className="intro-text-anim intro-text-anim-step8 intro-text-anim-step9 intro-text-anim-step10 intro-text-anim-step11">
          Complete all {totalNumberOfLevels} rounds for a chance to be on the game leaderboard!
        </p>
      </div>
    </Modal.Body>
  </>
);

export default GameModalStandardBody;
