/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loader from './Loader';
import { Link } from 'react-router-dom';
import Button from '../components/Layout/Button';
import { LeaderboardRoute } from '../constants/Routes';
import callApi from '../infrastructure/api/CallApi';
import usePageView from '../infrastructure/hooks/usePageView';
import { profilePage } from '../types/pageNameContants';
import { trackEvent } from '../infrastructure/tracking/GoogleAnalytics';
import RootState from '../types/index';
import ProfileStats from '../components/Profile/ProfileStats';
import LimitedEditionBadges from './Profile/LimitedEditionBadges';
import StandardBadges from './Profile/StandardBadges';
import { ProfileDto } from '../types/index';
import { localStorageKey } from '../constants';
import { logout } from '../slices/users/actions';
import GetRouteName from '../helpers/GetRouteName';

import { useHistory } from 'react-router';
import ModalProfilePhoto from './Modals/ModalProfilePhoto';
import getProfileImage from '../helpers/GetProfileImage';
import { selectIsCurrentSweepstake } from '../slices/sweepstakes/selectors';
import { iconShield, iconShieldSweepstakes } from '../images/icons';
import tiles3DSweepstakes from '../images/3d-tiles.webp';
import tiles3D from '../images/3d-tiles-default.webp';
import Share from './Share';

const ProfilePage = () => {
  const [showProfileImageModal, setShowProfileImageModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [profileData, setProfileData] = useState<ProfileDto | null>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const showHelpModal = useSelector((state: RootState) => state.game.showHelpModal);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  usePageView(profilePage, '/profile');

  const { name: username, id: userId, profileImage, badgeCount } = useSelector((state: RootState) => state.user);

  const onLogoutClick = () => {
    dispatch(logout());
    try {
      localStorage.removeItem(localStorageKey);
    } catch {}
    const label = GetRouteName(location.pathname, showHelpModal);
    trackEvent('Log out', 'Log out Success', label);
    try {
      if (window.formEntryTimeout) {
        clearTimeout(window.formEntryTimeout);
      }
    } catch {}
    history.push('/');
  };

  useEffect(() => {
    callApi<ProfileDto>(`api/app/profile/profile`, 'POST', { userId })
      .then(({ data }) => {
        setProfileData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="page page-leaderboard">
      <Container className="leaderboard-container">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="py-4 profile-container py-md-5">
            <h1>Your Profile</h1>
            <div className="profile-header d-block">
              <div className="d-flex profile-header-container">
                <div className="d-flex flex-column justify-content-center">
                  {username && (
                    <div className="profile-user d-sm-flex align-items-center">
                      <div className="profile-pic" onClick={() => setShowProfileImageModal(true)}>
                        <img src={getProfileImage(profileImage)} alt="" className="img-fluid" />
                      </div>
                      <div className="mt-2 profile-details d-flex align-items-center justify-content-center">
                        <div className="order-last earned-badge-container order-sm-first">
                          <img
                            src={isCurrentSweepstake ? iconShieldSweepstakes : iconShield}
                            className="img-fluid icon-shield"
                            alt="shield"
                          />
                          <span>{badgeCount}</span>
                        </div>
                        <div className="order-first profile-name order-sm-last">{username}</div>
                      </div>
                    </div>
                  )}
                  <div className="flex-wrap mt-4 profile-links d-flex align-items-center">
                    <a onClick={() => setShowProfileImageModal(true)} className="change-profile-link">
                      <span>Change Profile Picture</span>
                    </a>
                    <a onClick={onLogoutClick} className="logout-button">
                      Log Out
                    </a>
                  </div>
                </div>
                <div className="d-none d-md-block profile-decorative">
                  <img className="hallmark-crown" src={isCurrentSweepstake ? tiles3DSweepstakes : tiles3D} alt="" />
                </div>
              </div>
              <ProfileStats {...profileData!} />
              <div className="profile-header-bottom">
                <Share userData={profileData!} />
                <Link to={LeaderboardRoute} className="btn-leaderboard">
                  <Button
                    variant="primary"
                    size="sm"
                    className="btn-leaderboard btn-leaderboard-right"
                    onClick={() => trackEvent('Go To Leaderboard', 'Leaderboard Button Selected', 'ProfilePage')}
                  >
                    Show Leaderboard
                  </Button>
                </Link>
              </div>
            </div>
            <div className="profile-container">
              <LimitedEditionBadges badges={profileData!.limitedEditionBadges} />
              <StandardBadges badges={profileData!.badges} />

              {isError && (
                <div style={{ textAlign: 'center' }}>
                  <h4>Something went wrong. Please try again.</h4>
                </div>
              )}
            </div>
          </div>
        )}
        <ModalProfilePhoto show={showProfileImageModal} hideModal={() => setShowProfileImageModal(false)} />
      </Container>
    </div>
  );
};

export default ProfilePage;
