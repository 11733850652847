import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { shouldDisplayL, shouldShowMobileNavbar } from '../../infrastructure/freewheel/breakpoints';
import AdSlot from './AdSlot';
import useFreewheel from '../../infrastructure/freewheel/useFreewheel';
import { useSelector } from 'react-redux';
import { selectActiveLevel } from '../../slices/game/selectors';
import classNames from 'classnames';
import RootState from 'types';

const AnimatedBanner = () => {
  const urlRef = useRef<string>('');
  const levelRef = useRef<number>(0);
  const bannerTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const animationTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const location = useLocation();
  const [showBannerAd, setShowBannerAd] = useState<boolean>(true);
  const [startFadeOut, setStartFadeout] = useState<boolean>(false);
  const freewheelRequest = useFreewheel();
  const activeLevel = useSelector(selectActiveLevel);
  const { name: username } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (urlRef.current !== location.pathname || levelRef.current !== activeLevel) {
      setShowBannerAd(true);
      setStartFadeout(false);
      urlRef.current = location.pathname;
      levelRef.current = activeLevel;
      freewheelRequest(location.pathname, activeLevel);
      bannerTimer.current = setTimeout(() => {
        setShowBannerAd(false);
      }, 9900);
      animationTimer.current = setTimeout(() => {
        setStartFadeout(true);
      }, 9000);
    }

    return () => {
      if (bannerTimer.current) {
        clearTimeout(bannerTimer.current);
      }
      if (animationTimer.current) {
        clearTimeout(animationTimer.current);
      }
    };
  }, [freewheelRequest, location.pathname, activeLevel]);

  return showBannerAd ? (
    <AdSlot
      width={shouldDisplayL ? 728 : 320}
      height={shouldDisplayL ? 90 : 100}
      slotId="banner_ad"
      classNames={classNames(
        `banner-bottom`,
        startFadeOut ? 'fadeOutDown' : '',
        shouldShowMobileNavbar && username ? 'banner-bottom-mobile-logged-in' : ''
      )}
    />
  ) : null;
};

export default AnimatedBanner;
