import React, { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';

interface IVideoPlayerProps {
  videoUrl: string;
  playcardUrl: string;
  trackingTitle: string;
  label: string;
  onPlay?: (() => void) | null;
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ videoUrl, playcardUrl, trackingTitle, label, onPlay }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoPlayerRef = useRef<VideoJsPlayer | null>(null);

  const videoJsOptions: VideoJsPlayerOptions = {
    autoplay: false,
    playbackRates: [1],
    width: 1920,
    height: 1080,
    controls: true,
    controlBar: false,
    sources: [
      {
        src: videoUrl,
        type: 'video/mp4',
      },
    ],
    preload: 'none',
  };

  useEffect(() => {
    function disposePlayer() {
      if (videoPlayerRef.current && videoPlayerRef.current.dispose) {
        videoPlayerRef.current.dispose();
      }
    }

    if (videoRef.current) {
      videoPlayerRef.current = videojs(videoRef.current, videoJsOptions);
      videoPlayerRef.current.one('play', () => {
        if (onPlay) onPlay();
        trackEvent(`Promo Video in ${label}`, 'Video Play', trackingTitle);
      });
    }

    return disposePlayer;
    // eslint-disable-next-line
  }, []);

  return (
    <div data-vjs-player>
      <video
        ref={(node) => (videoRef.current = node)}
        className="video-js vjs-default-skin vjs-show-big-play-button-on-pause"
        controls={false}
        preload="auto"
        poster={playcardUrl}
        data-setup='{"fluid": true}'
      />
    </div>
  );
};

export default VideoPlayer;
