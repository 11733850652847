import React from 'react';
import { Image, Row } from 'react-bootstrap';
import { LimitedEditionBadgeDetailsDto, getLimitedEditionBadgeUrlFromKey } from '../../types/UserProfile';

interface ILimitedEditionBadgesProps {
  badges: Array<LimitedEditionBadgeDetailsDto>;
}

const LimitedEditionBadges: React.FC<ILimitedEditionBadgesProps> = ({ badges }) => {
  return (
    <div className="profile-badges">
      <h2 className="profile-sectionheader">Limited Edition Badges</h2>
      <Row>
        {badges.map((badge) => (
          <React.Fragment key={badge.key}>
            <div className="badge-container">
              <Image
                src={getLimitedEditionBadgeUrlFromKey(badge.key)}
                alt=""
                className="img-fluid"
                style={{ opacity: badge.hasEarned ? '100%' : '20%' }}
              />
              <p>{badge.description}</p>
            </div>
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
};

export default LimitedEditionBadges;
