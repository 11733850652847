import { PieceSprite, BoardGravity, Level, BoardPosition } from '../types';
import { hint } from './possibleMoves';
import { is_layer_empty } from './gameLogic';

const changingGravityOptions = [BoardGravity.Left, BoardGravity.Right, BoardGravity.Up, BoardGravity.Down];
let changingGravityIndex = changingGravityOptions.length - 1;
export const board_update = (level: Level, array: any, pieces: Phaser.GameObjects.Group) => {
  for (let y = 0; y < level.numberOfRows(); y++) {
    for (let x = 0; x < level.numberOfColumns(); x++) {
      array[y][x].filled = false;
      array[y][x].color = 0;
      array[y][x].to = null;
    }
  }
  let total = pieces.getLength();
  let child = pieces.getChildren() as PieceSprite[];
  for (let i = 0; i < total; i++) {
    let piece = child[i];
    array[piece.pos!.y][piece.pos!.x].filled = true;
    array[piece.pos!.y][piece.pos!.x].color = piece.color;
  }
};

const afterMoveCompleteCheckValidMovesAvailable = (
  array: Array<Array<BoardPosition>>,
  level: Level,
  pieces: Phaser.GameObjects.Group,
  onCompleted: () => void,
  onNoMovesRemaining: () => void
) => {
  if (!hint(array, level, pieces)) {
    //No possible match
    if (is_layer_empty(array, level)) {
      onCompleted();
    } else {
      onNoMovesRemaining();
    }
  }
};

export const drop = (
  level: Level,
  array: any,
  changeState: (newState: string) => void,
  pieces: Phaser.GameObjects.Group,
  startX: number,
  startY: number,
  tilePadding: number,
  size: any,
  scene: Phaser.Scene,
  onCompleted: () => void,
  onNoMovesRemaining: () => void,
) => {
  let to = level.gravity;
  if (level.changingGravity) {
    changingGravityIndex = (changingGravityIndex + 1) % changingGravityOptions.length;
    to = changingGravityOptions[changingGravityIndex];
  }
  const isHorizontal = to === BoardGravity.Left || to === BoardGravity.Right;
  const ascending = to === BoardGravity.Up || to === BoardGravity.Left;
  const counter = to === BoardGravity.None ? 0 : dropForDirection(array, isHorizontal, ascending, level);
  if (counter) {
    drop_all(
      counter,
      changeState,
      pieces,
      array,
      startX,
      startY,
      tilePadding,
      size,
      scene,
      level,
      onCompleted,
      onNoMovesRemaining
    );
  } else {
    changeState('play');
    afterMoveCompleteCheckValidMovesAvailable(array, level, pieces, onCompleted, onNoMovesRemaining);
  }
};

const dropForDirection = (array: any, isHorizontal: boolean, ascending: boolean, level: Level) => {
  const iteration1 = isHorizontal ? level.numberOfRows() : level.numberOfColumns();
  const iteration2 = isHorizontal ? level.numberOfColumns() : level.numberOfRows();
  let counter = 0;
  for (let a = 0; a < iteration1; a++) {
    let shift = 0;
    for (let b = ascending ? 0 : iteration2 - 1; ascending ? b < iteration2 : b >= 0; ascending ? (b += 1) : (b -= 1)) {
      if (!array[isHorizontal ? a : b][isHorizontal ? b : a].filled) {
        shift = ascending ? shift - 1 : shift + 1;
      } else {
        if (shift !== 0) {
          counter++;
        }
        array[isHorizontal ? a : b][isHorizontal ? b : a].to = {
          x: isHorizontal ? shift : 0,
          y: isHorizontal ? 0 : shift,
        };
      }
    }
  }
  return counter;
};

const drop_all = (
  counter: number,
  changeState: (newState: string) => void,
  pieces: Phaser.GameObjects.Group,
  array: any,
  startX: number,
  startY: number,
  tilePadding: number,
  size: any,
  self: Phaser.Scene,
  level: Level,
  onCompleted: () => void,
  onNoMovesRemaining: () => void
) => {
  changeState('drop');
  let total = pieces.getLength();
  let child = pieces.getChildren() as PieceSprite[];
  for (let i = 0; i < total; i++) {
    let piece = child[i];
    let arr = array[piece.pos!.y][piece.pos!.x];
    if (arr.to.x !== 0 || arr.to.y !== 0) {
      piece.pos!.x += arr.to.x;
      piece.pos!.y += arr.to.y;
      let target = {
        x: startX + size.width * piece.pos!.x + tilePadding * piece.pos!.x,
        y: startY + size.height * piece.pos!.y + tilePadding * piece.pos!.y,
      };
      self.tweens.add({
        targets: piece,
        x: target.x,
        y: target.y,
        duration: 200,
        ease: 'Linear',
        // eslint-disable-next-line no-loop-func
        onComplete: () => {
          counter--;
          if (counter === 0) {
            changeState('play');
            board_update(level, array, pieces);
            afterMoveCompleteCheckValidMovesAvailable(array, level, pieces, onCompleted, onNoMovesRemaining);
          }
        },
      });
    }
  }
};
