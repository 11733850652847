import React, { useRef } from 'react';

interface VimeoPlayerProps {
  vimeoCode: string;
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ vimeoCode }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const src = `https://player.vimeo.com/video/${vimeoCode}`;

  return (
    <div className="vimeo-container" ref={containerRef}>
      <iframe
        title="Movie Trailer"
        src={src}
        width={containerRef.current?.clientWidth || 360}
        height={containerRef.current ? containerRef.current.clientWidth * 0.5625 : 360}
        frameBorder="0"
        allowFullScreen
        allow="fullscreen"
      ></iframe>
    </div>
  );
};

export default VimeoPlayer;
