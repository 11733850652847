import { useRef } from 'react';
import { useFreewheelContext } from './provider';

const useFreewheel = () => {
  const { submitRequest } = useFreewheelContext();
  const sendRequest = (url: string, level: number) => submitRequest(url, level);
  const sendRequestRef = useRef(sendRequest);

  return sendRequestRef.current;
};

export default useFreewheel;
