export const LandingRoute = '/';
export const FormRoute = '/signup';
export const LeaderboardRoute = '/leaderboard';
export const GameRoute = '/game';
export const RulesRoute = '/rules';
export const ProfileRoute = '/profile';
export const GroupIndexRoute = '/groups';
export const GroupListPageRoute = `/group-list`;

export const UrlNameKey = 'urlPath';
export const UrlNameKeyWithColon = `:${UrlNameKey}`;
export const GroupDetailPageRoute = `/group/${UrlNameKeyWithColon}`;
export const GroupInvitePageRoute = `/group-invite/${UrlNameKeyWithColon}`;
