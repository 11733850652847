import React, { ReactElement } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import LandingPage from '../../components/LandingPage';
import FormPage from '../../components/FormPage';
import GamePage from '../../components/GamePage';
import RulesPage from '../../components/RulesPage';
import ProfilePage from '../../components/ProfilePage';
import LeaderBoardPage from '../../components/LeaderBoardPage';
import SecuredRoute from './SecuredRoute';
import AnonymousRoute from './AnonymousRoute';
import CloseWindow from './CloseWindow';
import GenericRoute from './NotFound404';
import posed, { PoseGroup } from 'react-pose';
import useScrollToTop from '../hooks/useScrollToTop';
import {
  LandingRoute,
  FormRoute,
  LeaderboardRoute,
  GameRoute,
  RulesRoute,
  ProfileRoute,
  GroupIndexRoute,
  GroupDetailPageRoute,
  GroupListPageRoute,
} from '../../constants/Routes';
import GroupIndexPage from '../../components/GroupIndexPage';
import GroupPage from 'components/GroupPage';
import { GroupInvitePageRoute } from '../../constants/Routes';
import GroupInvitePage from 'components/GroupInvitePage';
import GroupPublicListPage from 'components/GroupPublicListPage';

interface PageTransitionProps {
  location: any;
  children: ReactElement;
}

const TransitionDiv = posed.div({
  enter: { opacity: 1, delay: 0 },
  exit: { opacity: 0 },
});

const PageTransitions: (props: PageTransitionProps) => ReactElement = ({ children, location }) => {
  return isIE ? (
    children
  ) : (
    <PoseGroup>
      <TransitionDiv key={location.pathname} style={{ position: 'relative', flex: '1' }}>
        {children}
      </TransitionDiv>
    </PoseGroup>
  );
};

const Routes = () => {
  const location = useLocation();
  useScrollToTop(location.pathname);
  return (
    <PageTransitions location={location}>
      <Switch location={location}>
        <Route exact path={LandingRoute} component={LandingPage} key="landing" />
        <AnonymousRoute path={FormRoute} component={FormPage} key="signup" />
        <Route path={LeaderboardRoute} component={LeaderBoardPage} key="leaderboard" />
        <SecuredRoute path={GameRoute} component={GamePage} key="gamepage" />
        <Route path={RulesRoute} component={RulesPage} key="rules" />
        <SecuredRoute path={ProfileRoute} component={ProfilePage} key="profile" />
        <SecuredRoute path={GroupIndexRoute} component={GroupIndexPage} key="groupindexpage" />
        <SecuredRoute path={GroupDetailPageRoute} component={GroupPage} key="groupDetailPage" />
        <SecuredRoute path={GroupListPageRoute} component={GroupPublicListPage} key="groupPublicListPage" />
        <Route path={GroupInvitePageRoute} component={GroupInvitePage} key="groupInvitePage" />
        <Route path="/closewindow" component={CloseWindow} key="closewindow" />
        <Route path="*" component={GenericRoute} key="genericroute" />
      </Switch>
    </PageTransitions>
  );
};

export default Routes;
