import React from 'react';
import Button from '@material-ui/core/Button';
import { FacebookIcon } from '../../images/icons';
import GetConfig from '../../helpers/GetConfig';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import { getUserStats } from '../../helpers';

interface FacebookButtonProps {
  gamesStarted: string;
  gamesFinished: string;
  currentStreak: string;
  levelsCompleted: string;
  totalPlayTime: string;
  highestPositionAllTime: string;
}

const FacebookButton: React.FC<FacebookButtonProps> = ({
  gamesStarted,
  gamesFinished,
  currentStreak,
  levelsCompleted,
  totalPlayTime,
  highestPositionAllTime,
}) => {
  const isWebView = useSelector((state: RootState) => state.isWebView.webView);
  const shareCode = useSelector((state: RootState) => state.user.shareCode);

  const { appUrl, apiShareUrl, facebookAppId } = GetConfig();

  const share = () => {
    const shareUrl = `${apiShareUrl}share/${shareCode}`;
    const quoteParam = `&quote=${encodeURIComponent(
      getUserStats(gamesStarted, gamesFinished, currentStreak, levelsCompleted, totalPlayTime, highestPositionAllTime)
    )}`;

    const redirectUrl = `http://www.facebook.com/dialog/share?app_id=${facebookAppId}&display=popup&href=${shareUrl}${quoteParam}`;
    trackEvent('Share', 'Share Selected', 'Facebook');
    if (isWebView) {
      window.location.href = `${redirectUrl}&redirect_uri=${appUrl}?hmc=1`;
    } else {
      window.open(
        `${redirectUrl}&redirect_uri=${appUrl}closewindow`,
        'FacebookShare',
        'location=0,status=0,width=800,height=650'
      );
    }
  };

  return (
    <Button
      classes={{
        root: 'FacebookBtn ShareBtn',
        label: 'BtnLabel',
      }}
      onClick={share}
    >
      <FacebookIcon />
    </Button>
  );
};

export default FacebookButton;
