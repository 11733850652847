import React from 'react';
import GameLines from '../../images/backgrounds';

import './Modal.scss';

const GameTileAnimation = () => {
  return (
    <div className="game-tutorial">
      <div className="game-tutorial-tile game-tutorial-tile-hallmark-purple" />
      <div className="game-tutorial-tile game-tutorial-tile-hallmark-purple" />
      <div className="game-tutorial-tile game-tutorial-tile-matching-moments-orange" />
      <div className="game-tutorial-tile game-tutorial-tile-matching-moments-purple" />
      <div className="game-tutorial-tile game-tutorial-tile-hallmark-gold" />
      <div className="game-tutorial-tile game-tutorial-tile-matching-moments-purple" />
      <div className="game-tutorial-tile game-tutorial-tile-hallmark-gold" />
      <div className="game-tutorial-tile game-tutorial-tile-matching-moments-orange" />
      <div className="game-tutorial-lines">
        <img src={GameLines[0]} alt="" className="game-tutorial-line" />
        <img src={GameLines[1]} alt="" className="game-tutorial-line" />
        <img src={GameLines[2]} alt="" className="game-tutorial-line" />
        <img src={GameLines[3]} alt="" className="game-tutorial-line" />
        <img src={GameLines[4]} alt="" className="game-tutorial-line" />
      </div>
    </div>
  );
};

export default GameTileAnimation;
