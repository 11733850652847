import React from "react";

interface PinterestProps {
  className?: string;
}

const Pinterest: React.FC<PinterestProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      viewBox="0 0 13 16"
      className={className}
      width="13"
      height="16"
    >
      <path
        fill="#fff"
        d="M3.055 12.295c-.187.823-.115 1.978-.033 2.731l.118.8.519-.57c.378-.633.945-1.669 1.155-2.496l.58-2.262c.304.592 1.19 1.094 2.134 1.094 2.805 0 4.828-2.64 4.828-5.92 0-3.145-2.509-5.498-5.737-5.498C2.604.174.471 2.932.471 5.934c0 1.395.726 3.134 1.888 3.688.177.083.271.046.312-.128.03-.132.188-.779.258-1.08a.292.292 0 00-.064-.272c-.385-.477-.692-1.355-.692-2.173 0-2.099 1.554-4.13 4.201-4.13 2.286 0 3.887 1.593 3.887 3.872 0 2.575-1.272 4.359-2.926 4.359-.913 0-1.597-.772-1.378-1.72.263-1.132.771-2.352.771-3.17 0-.73-.384-1.34-1.177-1.34-.934 0-1.683.988-1.683 2.312 0 .842.278 1.412.278 1.412s-.923 3.988-1.091 4.73z"
      ></path>
    </svg>
  );
};

export default Pinterest;
