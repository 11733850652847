export const SETCODE = 'SETCODE';
export const CLEARCODE = 'CLEARCODE';

export interface SetCodeAction {
  type: typeof SETCODE;
  code: string;
}

export interface ClearCodeAction {
  type: typeof CLEARCODE;
}

export type GroupInviteActionTypes = SetCodeAction | ClearCodeAction;
