import { LevelList, Level, BoardGravity } from '../types';

const levels: LevelList = {
  1: new Level(4, 3, 180, BoardGravity.None, 3, 10, 'Good job! Watch out as each level will get harder!'),
  2: new Level(5, 4, 180, BoardGravity.None, 5, 20, 'Great work! The next level has even more tiles to connect!'),
  3: new Level(6, 4, 180, BoardGravity.None, 6, 30, 'Congratulations! The next level has even more tiles to connect!'),
  4: new Level(6, 5, 180, BoardGravity.None, 8, 40, 'Good job! Watch out as we will add in more tile variety! '),
  5: new Level(6, 5, 180, BoardGravity.None, 9, 50, 'Congratulations! The next level has even more tiles to connect!'),
  6: new Level(8, 4, 180, BoardGravity.None, 10, 60, 'Great work! The next level has even more tiles to connect!'),
  7: new Level(8, 5, 180, BoardGravity.None, 13, 70, 'Watch out! Tiles in the next level might start moving…'),
  8: new Level(
    8,
    5,
    180,
    BoardGravity.Down,
    14,
    80,
    'Great work! Tiles in the next level may start moving in a different direction…'
  ),
  9: new Level(8, 5, 180, BoardGravity.Right, 14, 90, 'Excellent work! Here comes another direction...'),
  // message should not be shown if no level 11
  10: new Level(
    8,
    5,
    180,
    BoardGravity.Left,
    14,
    100,
    "Amazing! You've now unlocked Level 11 - the ultimate challenge!"
  ),
  11: new Level(8, 6, 180, BoardGravity.None, 20, 110, undefined, true),
};

export default levels;
