import { LOGIN, LOGOUT, UserActionTypes, SEENMODAL, SETHIGHSCORE, SETENTRYCOUNT, SETPROFILEIMAGE } from './actionTypes';
import UserState from './UserState';

const initialState: UserState = {
  id: '',
  name: '',
  emailAddress: '',
  feed: false,
  hasSeenModal: false,
  highScore: 0,
  shareCode: '',
  newFormEntryAdded: false,
  hasVideoEntryAwarded: false,
  acceptedRules: false,
  badgeCount: 0,
  isNew: false,
  newBadgeNotification: null,
  profileImage: 0,
};

const userReducer = (state = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case LOGIN:
      return {
        id: action.id,
        name: action.name,
        emailAddress: action.emailAddress,
        hasSeenModal: true,
        entryCount: action.entryCount,
        grandPrizeCount: action.grandPrizeCount,
        highScore: action.highScore,
        shareCode: action.shareCode,
        newFormEntryAdded: action.newFormEntryAdded,
        acceptedRules: action.acceptedRules,
        badgeCount: action.badgeCount,
        isNew: action.isNew,
        profileImage: action.profileImage !== undefined ? action.profileImage : state.profileImage,
        hasVideoEntryAwarded: action.hasVideoEntryAwarded,
      };
    case LOGOUT:
      return {
        ...initialState,
        hasSeenModal: state.hasSeenModal,
      };
    case SEENMODAL:
      return {
        ...state,
        hasSeenModal: true,
        isNew: false,
      };
    case SETHIGHSCORE:
      return {
        ...state,
        highScore: action.score,
      };
    case SETENTRYCOUNT:
      return {
        ...state,
        badgeCount: action.badgeCount,
        newBadgeNotification: action.newBadgeNotification,
        newFormEntryAdded: action.newFormEntryAdded,
        acceptedRules: action.acceptedRules,
        profileImage: action.profileImage !== undefined ? action.profileImage : state.profileImage,
        hasVideoEntryAwarded: action.hasVideoEntryAwarded,
      };
    case SETPROFILEIMAGE:
      return {
        ...state,
        profileImage: action.profileImage,
      };
    default:
      return state;
  }
};

export default userReducer;
