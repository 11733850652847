import React, { useState } from 'react';
import { FormikHelpers, Form, Formik, Field } from 'formik';
import TextField from '../Form/TextField';
import Button from '../Layout/Button';
import callApi from 'infrastructure/api/CallApi';
import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';
import { IGroupBaseModel } from 'types/Groups';
import { useSelector } from 'react-redux';
import RootState from 'types';
import { inappropriateLanguageNotification } from 'helpers/GroupNotification';

interface ICreateGroupFormProps {
  onGroupCreation: (group: any) => void;
}

interface IGroupInputModel {
  name: string;
  isPrivate: boolean;
}

const initialValues: IGroupInputModel = {
  name: '',
  isPrivate: false,
};

const CreateGroupForm: React.FC<ICreateGroupFormProps> = ({ onGroupCreation }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { id: userId } = useSelector((state: RootState) => state.user);

  const onSubmit = (values: IGroupInputModel, { resetForm }: FormikHelpers<IGroupInputModel>) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      callApi<IGroupBaseModel>('api/app/group', 'POST', { ...values, userId })
        .then(({ data }) => {
          onGroupCreation(data);
          resetForm();
          trackEvent('Group Created', 'Group Created Success', 'Group Index Page');
        })
        .catch((message) => {
          if (message?.response?.data?.error?.message === 'Sorry, your group name contains inappropriate language.') {
            inappropriateLanguageNotification();
          }
          trackEvent('Group Created', 'Group Created Failure', 'Group Index Page');
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => (
        <Form id="GroupCreationForm" noValidate>
          <div className="inline-text-field create-group d-flex">
            <div className="create-group-fields">
              <div className="text-field-input">
                <TextField name="name" label="Name your group" id="name" type="text" maxLength={30} required />
              </div>
              <label htmlFor="isPrivate" className="checkbox-container">
                <Field name="isPrivate" type="checkbox" id="isPrivate" checked={values.isPrivate} />
                <span className="checkmark" />
                <div>Make this a private group</div>
              </label>
            </div>
            <Button
              type="submit"
              variant="primary"
              size="sm"
              className="btn-small"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={() => trackEvent('Group Created', 'Group Created Click', 'Group Index Page')}
            >
              Add New Group
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateGroupForm;
