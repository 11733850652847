import React from 'react';

interface CloseProps {
  className?: string;
}

const Close: React.FC<CloseProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className={className}
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.182 0.93934C2.59621 0.353553 1.64646 0.353553 1.06068 0.93934C0.474891 1.52513 0.474891 2.47487 1.06068 3.06066L11.5 13.5L2.06068 22.9394C1.47489 23.5251 1.47489 24.4749 2.06068 25.0607C2.64647 25.6465 3.59621 25.6465 4.182 25.0607L13.6213 15.6213L22.9394 24.9394C23.5251 25.5251 24.4749 25.5251 25.0607 24.9394C25.6465 24.3536 25.6465 23.4038 25.0607 22.818L15.7427 13.5L26.0607 3.18198C26.6465 2.5962 26.6465 1.64645 26.0607 1.06066C25.4749 0.474874 24.5251 0.474874 23.9394 1.06066L13.6213 11.3787L3.182 0.93934Z"
        fill="#E21219"
      />
    </svg>
  );
};

export default Close;
