import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import { selectIsCurrentSweepstake } from '../../slices/sweepstakes/selectors';

interface AnonymousRouteProps extends RouteProps {
  component: any;
}

const AnonymousRoute: React.FC<AnonymousRouteProps> = ({ component: Component }) => {
  const { id: userId, acceptedRules } = useSelector((state: RootState) => state.user);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  return (
    <Route
      render={(props) => {
        // If not logged in - return form
        if (!userId) {
          return <Component {...props} />;
        }
        // If no sweepstakes or user accepted rules = go to game
        // Otherwise return to landing page where the checkbox will be displayed
        return <Redirect to={!isCurrentSweepstake || acceptedRules ? '/game' : '/'} />;
      }}
    />
  );
};

export default AnonymousRoute;