import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RootState from '../../types';
import AdSlot from './AdSlot';
import useFreewheel from '../../infrastructure/freewheel/useFreewheel';
import { selectActiveLevel } from '../../slices/game/selectors';
import { FormRoute, LandingRoute } from 'constants/Routes';
import classNames from 'classnames';

interface FixedAdProps {
  shouldDisplayL: boolean;
  hideAd?: boolean;
}

const FixedAd: React.FC<FixedAdProps> = ({ shouldDisplayL, hideAd }) => {
  const freewheelRequest = useFreewheel();
  const location = useLocation();
  const activeLevel = useSelector(selectActiveLevel);

  const { name: username } = useSelector((state: RootState) => state.user);

  const isNavBarHidden = ['/', '/signup'].includes(location.pathname) && !username;

  const isLandingPage = location.pathname === LandingRoute;
  const isFormPage = location.pathname === FormRoute;

  useEffect(() => {
    if (shouldDisplayL && !hideAd) {
      freewheelRequest(location.pathname, activeLevel);
    }
    // eslint-disable-next-line
  }, [location, freewheelRequest, shouldDisplayL, activeLevel]);

  if (!shouldDisplayL || hideAd) {
    return null;
  }
  return (
    <AdSlot
      width={300}
      height={600}
      slotId="fixed_ad"
      classNames={classNames(
        'banner-right',
        isNavBarHidden ? 'banner-right-no-nav' : 'banner-right-with-nav',
        isLandingPage ? 'banner-right-landing-page' : '',
        isFormPage ? 'banner-right-form-page' : ''
      )}
    />
  );
};

export default FixedAd;
