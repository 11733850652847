import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LandingHeader, SweepstakesCallout } from './Layout';
import tiles3DSweepstakes from '../images/3d-tiles.webp';
import tiles3D from '../images/3d-tiles-default.webp';
import usePageView from '../infrastructure/hooks/usePageView';
import { landingPage } from '../types/pageNameContants';
import { useSelector } from 'react-redux';
import { selectIsCurrentSweepstake } from '../slices/sweepstakes/selectors';
import LandingPageEnterButon from './Layout/LandingHeader/LandingPageEnterButton';
import { selectTotalNumberOfLevels } from '../slices/game/selectors';
import { trackEvent } from '../infrastructure/tracking/GoogleAnalytics';
import classNames from 'classnames';
import RootState from '../types';

const LandingPage = () => {
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);
  const totalNumberOfLevels = useSelector(selectTotalNumberOfLevels);
  const { id: userId, acceptedRules } = useSelector((state: RootState) => state.user);
  const prizeTitle = useSelector((state: RootState) => state.gameConfig.prizeTitle);

  usePageView(landingPage, '/');

  return (
    <div className={`page page-landing ${isCurrentSweepstake ? 'sweepstakes' : 'game'}_page-landing`}>
      <LandingHeader page="landing">
        <LandingPageEnterButon />
      </LandingHeader>
      <Container
        className={classNames(
          !isCurrentSweepstake || !userId || acceptedRules ? '' : 'landing-with-checkboxes-container',
          'landing-container'
        )}
      >
        <div className="landing_tiles-container">
          {isCurrentSweepstake ? (
            <Col xs={12} md={{ span: 6, order: 2 }} className="landing_tiles-text">
              <h4>Grand Prize</h4>

              <p>
                Each completed round will earn you one entry into the sweepstakes for a chance to win a Cruise for Two
                from Princess.
              </p>
              {/* <p>
                Tap to connect the tiles that match. Each completed round will earn you one entry
                <span className="red">*</span> into this week's sweepstakes to win a $500 Visa Gift Card and a Cricut®
                Maker Machine and other goodies from Chex™ cereal!
                <span className="bold link" dangerouslySetInnerHTML={{ __html: prizeTitle }} />
              </p> */}
              {/* <div className="small light landing_tiles-disclaimer">
                <span className="red">*</span> Maximum {totalNumberOfLevels} entries per day for game play
              </div> */}
            </Col>
          ) : (
            <Col className="landing_tiles-text">
              <h4>Tap to connect the tiles that match</h4>
              <p>
                Complete all 11 rounds for a chance to be on the{' '}
                <Link
                  to="/leaderboard"
                  onClick={() => trackEvent('Go To Leaderboard', 'Leaderboard Button Selected', 'Landing Page')}
                >
                  Matching Moments Leaderboard!
                </Link>
              </p>
            </Col>
          )}
          <Col xs={12} md={{ span: 6, order: 1 }}>
            <div className="landing-container_image">
              <img src={isCurrentSweepstake ? tiles3DSweepstakes : tiles3D} alt="" />
            </div>
          </Col>
        </div>
        {isCurrentSweepstake && <SweepstakesCallout />}
      </Container>
    </div>
  );
};

export default LandingPage;
