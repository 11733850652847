import React from 'react';

const ArrowLeft = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`${className} arrow-line-left`}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 9L2 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 14L2 9.00003L7 4.00003" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowLeft;
