/* eslint-disable no-useless-escape */
const shareCodeKey = 'HallmarkMatchingMomentsShareCode';

export const numberWithCommas: (input: number) => string = (input) =>
  input !== undefined ? input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';

export const insertItemAtIndex: <T>(array: Array<T>, item: T, index: number) => Array<T> = (array, item, index) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

export const getRandomItemFromArray: <T>(array: Array<T>) => T = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const formatTime: (seconds: number) => string = (seconds: number) => {
  // Minutes
  const minutes = Math.floor(seconds / 60);
  // Seconds
  const partInSecondsNumber = seconds % 60;
  // Adds left zeros to seconds
  const partInSeconds = partInSecondsNumber.toString().padStart(2, '0');
  // Returns formated time
  return `${minutes}:${partInSeconds}`;
};

export const saveToSessionStorage = (shareCode: string) => {
  try {
    sessionStorage.setItem(shareCodeKey, shareCode);
  } catch (error) {}
};

export const getFromSessionStorage = () => {
  try {
    const shareCode = sessionStorage.getItem(shareCodeKey);
    return shareCode;
  } catch (error) {
    return null;
  }
};

export const getUrlParams = (name: string, url: string) => {
  const adjustedName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + adjustedName + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results || !results[2]) return null;
  return results[2];
};

export const getUserStats = (
  gamesStarted: string,
  gamesFinished: string,
  currentStreak: string,
  levelsCompleted: string,
  totalPlayTime: string,
  highestPositionAllTime: string
) => `Check Out My Game Stats!
    • Games Started: ${gamesStarted}
    • Games Finished: ${gamesFinished}
    • Current Streak: ${currentStreak} days
    • Levels Completed: ${levelsCompleted}
    • Total Play Time: ${totalPlayTime}mins
    • Highest Position: ${highestPositionAllTime}`;

export const removeTrailingSlash = (str: string) => {
  return str.replace(/\/+$/, '');
};

// export const getPageNumbers = (currentPage: number, lastPage: number) => {
//   let startPage = currentPage < 5 ? 1 : currentPage - 4;
//   const targetEndPage = startPage + 8;
//   const endPage = targetEndPage > lastPage ? lastPage : targetEndPage;
//   let length = endPage - startPage + 1;
//   if (length < 9) {
//     const targetStartPage = endPage - 8;
//     startPage = targetStartPage < 1 ? 1 : targetStartPage;
//     length = endPage - startPage + 1;
//   }
//   return Array.from({ length }, (_, i) => i + startPage);
// };

export const getPageNumbers = (currentPage: number, lastPage: number, isMobile: boolean) => {
  if (isMobile) {
    let startPage = currentPage - 1;
    if (currentPage === 1) startPage = 1;
    if (currentPage === lastPage) startPage = lastPage - 2;
    const endPage = startPage + 2 > lastPage ? lastPage : startPage + 2;

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
  } else {
    let startPage = currentPage < 5 ? 1 : currentPage - 4;
    const targetEndPage = startPage + 8;
    const endPage = targetEndPage > lastPage ? lastPage : targetEndPage;
    let length = endPage - startPage + 1;
    if (length < 9) {
      const targetStartPage = endPage - 8;
      startPage = targetStartPage < 1 ? 1 : targetStartPage;
      length = endPage - startPage + 1;
    }

    return Array.from({ length }, (_, index) => index + startPage);
  }
};
