import React, { useEffect, useState } from 'react';
import useLinkTarget from '../../infrastructure/isWebView/useLinkTarget';
import './TuneIn.scss';
import { useDispatch, useSelector } from 'react-redux';
import { EntryCountDto, TuneInType } from '../../types';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import VimeoPlayer from '../Media/VimeoPlayer';
import VideoPlayer from '../Media/VideoPlayer';
import { selectPromotedItem } from '../../slices/promotion/selectors';
import CallApi from '../../infrastructure/api/CallApi';
import { setEntryCount } from '../../slices/users/actions';
import { selectHasVideoEntryAwarded, selectUserId } from '../../slices/users/selectors';
import { selectApiCallInProgress } from '../../slices/game/selectors';

interface ModalTuneInProps {
  showInvite?: boolean;
  label: string;
}

const setVideoView = (promotedItemId: string, userId: string, dispatch: any) => {
  const model = { promotedItemId, userId };

  CallApi<EntryCountDto>(`api/app/promoted-item/viewed`, 'POST', model)
    .then((response) => {
      if (response.data) {
        dispatch(setEntryCount(response.data));
      }
    })
    .catch((e) => console.log(e));
};

const ModalTuneIn: React.FC<ModalTuneInProps> = ({ showInvite, label }) => {
  const dispatch = useDispatch();
  const linkTarget = useLinkTarget();
  const hasVideoEntryAwarded = useSelector(selectHasVideoEntryAwarded);
  const apiCallInProgress = useSelector(selectApiCallInProgress);
  const userId = useSelector(selectUserId);
  const [isPlayed, setIsPlayed] = useState(false);

  const { id, type, externalUrl, imageUrl, title, displayTitle, vimeoCode, logoUrl, tuneIn, videoUrl, awardEntry } =
    useSelector(selectPromotedItem);

  const needToCallApi = isPlayed && awardEntry && hasVideoEntryAwarded === false && apiCallInProgress === false;

  useEffect(() => {
    if (needToCallApi) {
      setVideoView(id, userId, dispatch);
    }
  }, [dispatch, id, needToCallApi, userId]);

  if (type === TuneInType.Invite || showInvite) {
    return null;
  }

  if (type === TuneInType.Video) {
    return (
      <div className="modal-callout modal-tune-in-video">
        <div className="modal-tune-in">
          <div className="movie-title">{displayTitle}</div>
          <div className="tune-in">{tuneIn}</div>
        </div>
        <div className="modal-video">
          <VideoPlayer
            videoUrl={videoUrl}
            playcardUrl={imageUrl}
            trackingTitle={title}
            label={label}
            onPlay={() => setIsPlayed(true)}
          />
        </div>
      </div>
    );
  }

  if (type === TuneInType.Vimeo) {
    return (
      <div className="modal-callout modal-tune-in-video">
        <div className="modal-tune-in">
          <div className="movie-title">
            {/* <img src={TuneInIcon} alt="" className="icon" />  */}
            <img src={logoUrl} alt="" />
          </div>
          <div className="tune-in">{tuneIn}</div>
        </div>
        <div className="modal-video">
          <VimeoPlayer vimeoCode={vimeoCode} />
        </div>
      </div>
    );
  }

  if (type === TuneInType.Image) {
    return (
      <div className="modal-callout modal-tune-in-full modal-tune-in-video">
        <div className="modal-tune-in">
          <div className="movie-title">{displayTitle}</div>

          <div className="tune-in">{tuneIn}</div>
        </div>
        <a
          href={externalUrl}
          target={linkTarget}
          rel="noopener noreferrer"
          onClick={() => trackEvent('Movie Tune In Selected', `Tune In Selected in ${label}`, title)}
        >
          <img src={imageUrl} alt="" />
        </a>
      </div>
    );
  }

  return null;
};

export default ModalTuneIn;
