import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { ButtonProps } from './types';
import ArrowRight from '../../images/chex2023/icons/ArrowRight';

const Button = ({ children, isLoading, iconBefore, className, style, iconOnly, withArrow, ...props }: ButtonProps) => {
  return (
    <React.Fragment>
      <BootstrapButton
        {...props}
        className={`${!iconBefore && !iconOnly ? 'btn-icon-after' : ''} ${
          iconBefore && !iconOnly ? 'btn-icon-before' : ''
        } ${iconOnly ? 'btn-icon-only' : ''} ${withArrow ? 'btn-arrow' : ''} ${className || ''} custom-btn`}
        style={style}
      >
        <span className={`btn-content ${isLoading ? 'btn-content-hidden' : 'btn-content-visible'}`}>
          {withArrow && iconBefore && <ArrowRight />} {children} {withArrow && !iconBefore && <ArrowRight />}
          <div className={`btn-loader ${isLoading ? 'btn-loader-visible' : 'btn-loader-hidden'}`} />
        </span>
      </BootstrapButton>
    </React.Fragment>
  );
};

export default Button;
