import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Layout/Button';
import ModalTuneIn from './ModalTuneIn';
import { useSelector } from 'react-redux';
import RootState from '../../types/index';
import Game from '../../game/Scenes/Game';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import trackPageViewGoogle from '../../infrastructure/tracking';
import { gamePausedModal } from '../../types/pageNameContants';
import timeIcon from '../../images/game/time.svg';

interface PauseModalProps {
  gameScene: Game | undefined;
  resumeTimer: () => void;
  formattedTime: string;
}

const PauseModal: React.FC<PauseModalProps> = ({ gameScene, resumeTimer, formattedTime }) => {
  const show = useSelector((state: RootState) => state.game.isManuallyPaused && !state.game.showHelpModal);
  const resume = () => {
    trackEvent('User Game Continued', 'Continue Button Selected', 'Game Paused Modal');
    gameScene?.resumeGame();
    resumeTimer();
  };

  useEffect(() => {
    if (show) {
      trackPageViewGoogle(gamePausedModal, '/game/pause');
    }
  }, [show]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={resume}
      className="pause-modal"
      backdropClassName="pause-modal-backdrop"
    >
      <Modal.Header>
        <h4>Time Remaining</h4>
        <div className="pause-modal__content">
          <img src={timeIcon} alt="Time" className="red-blur" />
          <span className="bold link">{formattedTime}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Button variant="primary" size="lg" withArrow onClick={resume}>
          Continue
        </Button>
      </Modal.Body>
      <ModalTuneIn showInvite label="Pause Modal" />
    </Modal>
  );
};

export default PauseModal;
