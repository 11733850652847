import UserState from '../slices/users/UserState';
import GameState from '../slices/game/GameState';
import SweepstakeState from '../slices/sweepstakes/SweepstakeState';
import ShareState from '../slices/sharing/ShareState';
import PromotionState from '../slices/promotion/PromotionState';
import GameConfigState from '../slices/gameconfig/GameConfigState';
import { StandardBadgeDto, LimitedEditionBadgeDto } from './UserProfile';
import GroupInviteState from 'slices/groupinvite/GroupInviteState';
import { GroupType } from './Groups';
// import AdState from '../slices/ads/AdState';

export * from './UserProfile';

export default interface RootState {
  game: GameState;
  gameConfig: GameConfigState;
  isWebView: { webView: boolean };
  promotion: PromotionState;
  share: ShareState;
  sweepstake: SweepstakeState;
  user: UserState;
  groupInvite: GroupInviteState;
  // ads: AdState;
}

export interface TrackingPageViewProps {
  trackPageView: (pageTitle: string, pagePath: string) => void;
}

export interface TrackingEventProps {
  trackEvent: (category: string, action: string, label: string) => void;
}

export interface TrackingProps extends TrackingEventProps, TrackingPageViewProps {}

export interface UserBaseDto {
  name: string;
  emailAddress: string;
  id: string;
  isNew: boolean;
  lastLevelPlayed: number;
  currentScore: number;
  highScore: number;
  shareCode: string;
  shufflesRemaining?: number;
  hintsRemaining?: number;
  acceptedRules: boolean;
  totalSecondsRemaining: number;
  newBadgeNotification: LimitedEditionBadgeDto | null;
}

export interface UserDto extends UserBaseDto {
  entryCount: number;
  grandPrizeCount: number;
  hasVideoEntryAwarded: boolean;
  newFormEntryAdded: boolean;
  currentSweepstakeEnd: string | null;
  currentSweepstakeName: string | null;
  nextSweepstakeName: string | null;
  nextSweepstakeStart: string | null;
  timeToNextEntryMs: number;
  badgeCount: number;
  profileImage: number;
}

export interface EntryCountDto {
  newFormEntryAdded: boolean;
  entryCount: number;
  currentSweepstakeEnd: string | null;
  currentSweepstakeName: string | null;
  nextSweepstakeName: string | null;
  nextSweepstakeStart: string | null;
  timeToNextEntryMs: number;
  awardedBadges: Array<StandardBadgeDto | LimitedEditionBadgeDto>;
  nearlyThereNotifications: Array<StandardBadgeDto | LimitedEditionBadgeDto>;
  badgeCount: number;
  newBadgeNotification: LimitedEditionBadgeDto | null;
  profileImage: number;
  grandPrizeCount: number;
  acceptedRules: boolean;
  hasVideoEntryAwarded: boolean;
}

export enum ShareType {
  Generic,
  MyTree,
}

export enum PopUpType {
  Click,
  Feed,
}

export enum TuneInType {
  Vimeo = 'Vimeo',
  Invite = 'Invite',
  Image = 'Image',
  Video = 'Video',
}

export interface PromotedItemDto {
  imageUrl: string;
  logoUrl: string;
  title: string;
  displayTitle: string;
  tuneIn: string;
  type: TuneInType;
  vimeoCode: string;
  externalUrl: string;
  videoUrl: string;
  awardEntry: boolean;
  id: string;
}

export interface TileDto {
  url: string;
}

export interface GameConfigDto {
  landingPageText: string;
  prizeTitle: string;
  promotedItems: PromotedItemDto[];
  tiles: TileDto[];
}

export interface SweepsDetailsDto {
  currentSweepstakeEnd: string | null;
  currentSweepstakeName: string | null;
  nextSweepstakeName: string | null;
  nextSweepstakeStart: string | null;
}

export interface ILeaderboardItem {
  name: string;
  rank: string;
  score: string;
  profileImage?: number;
  groupType: GroupType;
  inviteCode?: string | null;
  isGroupMember: boolean;
}
