/* eslint-disable react-hooks/exhaustive-deps */
import trackPageViewGoogle from '../../infrastructure/tracking';

import { useEffect } from 'react';

const usePageView: (pageTitle: string, pagePath: string) => void = (pageTitle, pagePath) => {
  useEffect(() => {
    trackPageViewGoogle(pageTitle, pagePath);
  }, []);
};

export default usePageView;
