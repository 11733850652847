import { GroupInviteActionTypes, SETCODE } from './actionTypes';

export function setCode(code: string): GroupInviteActionTypes {
  return {
    type: SETCODE,
    code,
  };
}

export function clearCode(): GroupInviteActionTypes {
  return {
    type: 'CLEARCODE',
  };
}
