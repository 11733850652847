import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Image, Container } from 'react-bootstrap';
import Button from '../Layout/Button';
import clsx from 'clsx';
import getProfileImage from '../../helpers/GetProfileImage';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../types/index';
import { setProfileImage } from '../../slices/users/actions';
import callApi from '../../infrastructure/api/CallApi';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import Loader from '../Loader';

const apiUrl = 'api/app/user/update-profile-image';

interface IModalProfilePhotoProps {
  show: boolean;
  hideModal: () => void;
}

// @ts-ignore
const imageNumbers: Array<number> = [...Array(12).keys()];

const ModalProfilePhoto: React.FC<IModalProfilePhotoProps> = ({ show, hideModal }) => {
  const { id, profileImage: currentProfileImage } = useSelector((state: RootState) => state.user);
  const [selectedImage, setSelectedImage] = useState<number>(currentProfileImage);
  const [isLoading, setIsLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const dispatch = useDispatch();

  const onHideModal = () => {
    trackEvent('Profile Image', 'Profile Image Modal Hidden');
    hideModal();
  };

  useEffect(() => {
    if (show) {
      setSelectedImage(currentProfileImage);
      trackEvent('Profile Image', 'Profile Image Modal Shown');
      const timer = setTimeout(() => setIsLoading(false), 8000);
      return () => clearTimeout(timer);
    }
  }, [show]);

  useEffect(() => {
    if (imagesLoaded === imageNumbers.length) {
      setIsLoading(false);
    }
  }, [imagesLoaded]);

  const onConfirm = () => {
    if (currentProfileImage !== selectedImage) {
      dispatch(setProfileImage(selectedImage));
      callApi(apiUrl, 'POST', { id, profileImage: selectedImage });
      trackEvent('Profile Image', 'New Profile Image Confirmed');
    }
    onHideModal();
  };

  return (
    <Modal centered show={show} className="modal-profile-pic" onHide={onHideModal}>
      <Modal.Header>
        <span className="modal-heading">Update Profile Picture</span>
        <button className="modal-close" onClick={onHideModal}></button>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="selection-row">
            {isLoading && <Loader />}
            {imageNumbers.map((x) => (
              <Col xs={3} lg={2} key={`col${x}`} onClick={() => setSelectedImage(x)}>
                <Image
                  src={getProfileImage(x)}
                  onLoad={() => {
                    setImagesLoaded(imagesLoaded + 1);
                  }}
                  alt=""
                  className={clsx('img-fluid', selectedImage === x && 'selected', isLoading ? 'd-none' : 'd-block')}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }}>
              <Button variant="primary" size="lg" withArrow className="modal-confirm" onClick={onConfirm}>
                Confirm
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalProfilePhoto;
