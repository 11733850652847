import React from 'react';
import Button from '../Layout/Button';
import { Link } from 'react-router-dom';
// import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
// import EventTrackingData from 'constants/EventTracking';
// import { iconDelete, iconLeave, iconView } from 'images/groups';
import iconGroupRank from 'images/icons/groupRank.svg';
// import GetConfig from 'helpers/GetConfig';
import classNames from 'classnames';
import { IGroupBaseModel } from 'types/Groups';
// import { groupIndexPage } from 'types/pageNameContants';
// import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';

interface IUserGroupListItemProps {
  group: IGroupBaseModel;
  index: number;
}

const UserGroupListItem: React.FunctionComponent<IUserGroupListItemProps> = ({ index, group }) => {
  return (
    <div className={classNames('entry')}>
      <div className="user-info">
        <div className="username">{group.name}</div>
      </div>
      <div className="group-button-container">
        <div className="group-details">
          <div className="group-count">
            <img src={iconGroupRank} alt="Group Rank" />
            <span>{group.memberCount}</span>
          </div>
          <div className="group-button-container">
            <Link to={`/group/${group.inviteCode}`}>
              <Button
                variant="primary"
                size="sm"
                className="btn-small"
                // onClick={() => trackEventData(EventTrackingData.ViewButtonClick)}
              >
                <span>View</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGroupListItem;
