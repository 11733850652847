import * as Yup from 'yup';

const FormValidationSweepstakesSchema = Yup.object().shape({
  firstName: Yup.string()
    .test('firstName', 'Please enter your first name.', (value) => {
      if (!value) return true;
      const valueWithoutMask = value.replace(/\D+/g, '');
      return valueWithoutMask.length <= 2;
    })
    .max(20, 'Must be 20 characters or fewer.')
    .required('Please enter your first name.'),
  lastName: Yup.string()
    .test('lastName', 'Please enter your last name.', (value) => {
      if (!value) return true;
      const valueWithoutMask = value.replace(/\D+/g, '');
      return valueWithoutMask.length <= 2;
    })
    .max(20, 'Must be 20 characters or fewer.')
    .required('Please enter your last name.'),
  emailAddress: Yup.string()
    .email('Please enter a valid email address.')
    .max(100, 'Must be 100 characters or fewer.')
    .required('Please enter a valid email address.'),
  zipCode: Yup.string().required('Please enter a zip code.').max(5, 'Must be 5 characters or fewer.'),
  provider: Yup.string().required('Please select a provider.'),
  ageConfirmation: Yup.bool()
    .test('ageCheck', 'You must be at least 21 years of age to continue.', (value) => value === true)
    .required('You must be at least 21 years of age to continue.'),
  rules: Yup.bool()
    .test('rules', 'Please check this box to continue.', (value) => value === true)
    .required('Please check this box to continue.'),
});

export default FormValidationSweepstakesSchema;
