import {
  repeatplayer,
  weekwarrior,
  overachiever,
  superspeedy,
  topten,
  weeklytop,
  independent,
  lastlevelboss,
  loyalplayer,
  centurion,
  teamplayer,
  groupchampions,
  groupgamers,
  loyalleague,
  leaderboardlegends,
} from '../images/badges';

// Standard Badges
export const standardBadgeIds = [
  'RepeatPlayer',
  'WeekWarrior',
  'OverAchiever',
  'SuperSpeedy',
  'AllTimeTopTen',
  'WeeklyTopFive',
  'Independent',
  'LastLevelBoss',
  'LoyalPlayer',
  'Centurion',
  'TeamPlayer',
  'GroupChampions',
  'GroupGamers',
  'LoyalLeague',
  'LeaderboardLegends',
] as const;

export type StandardBadgeId = (typeof standardBadgeIds)[number];

type StandardBadgeImageDictionaryMap = {
  [key in StandardBadgeId]: string;
};

export const StandardBadgeImageDictionary: StandardBadgeImageDictionaryMap = {
  RepeatPlayer: repeatplayer,
  WeekWarrior: weekwarrior,
  OverAchiever: overachiever,
  SuperSpeedy: superspeedy,
  AllTimeTopTen: topten,
  WeeklyTopFive: weeklytop,
  Independent: independent,
  LastLevelBoss: lastlevelboss,
  LoyalPlayer: loyalplayer,
  Centurion: centurion,
  TeamPlayer: teamplayer,
  GroupChampions: groupchampions,
  GroupGamers: groupgamers,
  LoyalLeague: loyalleague,
  LeaderboardLegends: leaderboardlegends,
};

//Limited Badges
export const getLimitedEditionBadgeUrlFromKey = (key: string) =>
  `https://1240369105.rsc.cdn77.org/matchingmoments/badges/limited/${key.toLowerCase()}.svg`;

export interface ProfileDto extends ProfileStatsDto {
  badges: StandardBadgeDetailsDto[];
  limitedEditionBadges: LimitedEditionBadgeDetailsDto[];
}

export type BadgeBaseDto = {
  name: string;
  description: string;
};

export type StandardBadgeDto = BadgeBaseDto & {
  key: StandardBadgeId;
  isLimitedEdition: false;
};

export type LimitedEditionBadgeDto = BadgeBaseDto & {
  key: string;
  isLimitedEdition: true;
};

export interface ProfileStatsDto {
  gamesStarted: string;
  gamesFinished: string;
  currentStreak: string;
  levelsCompleted: string;
  totalPlayTime: string;
  highestPositionWeekly: string;
  highestPositionAllTime: string;
}

interface BadgeDetailsDto {
  hasEarned: boolean;
  description: string;
}

export interface LimitedEditionBadgeDetailsDto extends BadgeDetailsDto {
  key: string;
}

export interface StandardBadgeDetailsDto extends BadgeDetailsDto {
  key: StandardBadgeId;
}
