import Swal from 'sweetalert2';
import { StandardBadgeDto, BadgeBaseDto, LimitedEditionBadgeDto } from '../types/index';
import { StandardBadgeImageDictionary, getLimitedEditionBadgeUrlFromKey } from '../types/UserProfile';
import { trackEvent } from '../infrastructure/tracking/GoogleAnalytics';
import withReactContent from 'sweetalert2-react-content';
import React from 'react';
import { Image } from 'react-bootstrap';
import { notificationBaseOptions } from './NotificationOptions';

const reactSwal = withReactContent(Swal);

const getImageUrl = (badge: StandardBadgeDto | LimitedEditionBadgeDto) =>
  badge.isLimitedEdition ? getLimitedEditionBadgeUrlFromKey(badge.key) : StandardBadgeImageDictionary[badge.key];

export const badgeNotification = (
  badges: Array<StandardBadgeDto | LimitedEditionBadgeDto>,
  nearlyThereNotifications: Array<StandardBadgeDto | LimitedEditionBadgeDto>,
  onViewBadgesClick: () => void
) => {
  if (badges.length) {
    const badge = badges[0];
    singleBadgeNotification(getImageUrl(badge), onViewBadgesClick).then((response) => {
      if (response.isConfirmed) {
        trackEvent('Go To ProfilePage', 'View Standard Badges Selected', 'Badge Notification Modal');
        onViewBadgesClick();
      }
      badgeNotification(badges.slice(1, badges.length), nearlyThereNotifications, onViewBadgesClick);
    });
  } else if (nearlyThereNotifications.length) {
    const badge = nearlyThereNotifications[0];
    nearlyThereNotification(getImageUrl(badge), badge, onViewBadgesClick).then((response) => {
      if (response.isConfirmed) {
        trackEvent('Go To ProfilePage', 'View Limited Badges Selected', 'Badge Notification Modal');
        onViewBadgesClick();
      }
      badgeNotification([], nearlyThereNotifications.slice(1, nearlyThereNotifications.length), onViewBadgesClick);
    });
  }
};

const getNearlyThereHtml = (
  image: string,
  onViewBadgesClick: () => void,
  description: string = 'You earned a badge'
) => (
  <>
    <Image src={image} className="badge-image img-fluid" />
    <div>
      <p className="description">{description}</p>
      <div className="swal2-actions">
        <button type="button" className="swal2-confirm swal2-styled" onClick={onViewBadgesClick}>
          View all badges
        </button>
      </div>
    </div>
  </>
);

const singleBadgeNotification = (image: string, onViewBadgesClick: () => void) => {
  return reactSwal.fire({
    ...notificationBaseOptions,
    html: getNearlyThereHtml(image, () => {
      onViewBadgesClick();
      reactSwal.close();
    }),
  });
};

const nearlyThereNotification = (image: string, badge: BadgeBaseDto, onViewBadgesClick: () => void) => {
  return reactSwal.fire({
    ...notificationBaseOptions,
    html: getNearlyThereHtml(
      image,
      () => {
        onViewBadgesClick();
        reactSwal.close();
      },
      badge.description
    ),
  });
};
