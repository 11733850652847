import { PromotedItemDto, TileDto } from '../../types';

export const SETCONFIG = 'SETCONFIG';

export interface SetConfigAction {
  type: typeof SETCONFIG;
  landingPageText: string;
  prizeTitle: string;
  promotedItems: PromotedItemDto[];
  tiles: TileDto[];
}

export type GameConfigActionTypes = SetConfigAction;
