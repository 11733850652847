export interface config {
  apiShareUrl: string;
  apiUrl: string;
  appUrl: string;
  crossDomainGoogleAnalyticsWebPropertyId: string;
  crossDomainGooglev4AnalyticsWebPropertyId: string;
  enableComScore: boolean;
  enableFreewheel: boolean;
  enableGoogleAnalytics: boolean;
  facebookAppId: string;
  googleAnalyticsWebPropertyId: string;
  navBarColor: string;
  navBarFranchise: string;
  navBarLink: string;
  networkId: number;
  profileId: string;
  serverUrl: string;
  siteSectionId: string;
  tweet: string;
  pinterestDescription: string;
  gameAssetUrl: string;
  enableShareEntries: boolean;
  facebookQuote: string;
  cookieDomain: string;
}

declare global {
  interface Window {
    config: config;
    gtag: any;
    COMSCORE: any;
    s: any;
    formEntryTimeout?: ReturnType<typeof setTimeout>;
    tv: any;
  }
}

const GetConfig = () => {
  return window.config;
};

export default GetConfig;
