/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Layout/Button';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import './Modal.scss';
import RootState from '../../types';
import { useSelector } from 'react-redux';
import trackPageViewGoogle from '../../infrastructure/tracking';
import { gameIntroModal, returningUserModal } from '../../types/pageNameContants';
import { selectIsCurrentSweepstake } from '../../slices/sweepstakes/selectors';
import { getFromLocalStorage, setOnLocalStorage } from '../../helpers/LocalStorageHelper';
import { localStorageBadgeNotificationKey } from '../../constants';
import GameModalStandardBody from './GameModalStandardBody';
import GameModalBadgeBody from './GameModalBadgeBody';

interface GameModalProps {
  show: boolean;
  hideModal: () => void;
  restart: () => void;
  showRestart: boolean;
}

const GameModal: React.FC<GameModalProps> = ({ show, hideModal, restart, showRestart }) => {
  const [animationStep, setAnimationStep] = useState<number>(0);
  const { newFormEntryAdded: newEntry, newBadgeNotification, id } = useSelector((state: RootState) => state.user);
  const { loading, totalNumberOfLevels } = useSelector((state: RootState) => state.game);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);
  const lastNotificationShown = getFromLocalStorage(`${localStorageBadgeNotificationKey}${id}`);
  const showBadgeNotification = newBadgeNotification && lastNotificationShown !== newBadgeNotification.key;

  const setBadgeNotificationShown = () => {
    if (showBadgeNotification) {
      setOnLocalStorage(`${localStorageBadgeNotificationKey}${id}`, newBadgeNotification!.key);
    }
  };
  useEffect(() => {
    if (!showBadgeNotification) {
      const timeout = setTimeout(
        () => setAnimationStep(animationStep < 12 ? animationStep + 1 : 0),
        animationStep === 0 ? 1000 : 2000
      );
      return () => clearTimeout(timeout);
    }
  }, [animationStep]);

  useEffect(() => {
    if (show) {
      const pageTitle = !showRestart ? gameIntroModal : returningUserModal;
      const pagePath = !showRestart ? '/game/intro' : '/game/continue';
      trackPageViewGoogle(pageTitle, pagePath);
    }
  }, [show]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        hideModal();
        setBadgeNotificationShown();
      }}
      className={`intro-modal intro-modal-step-${animationStep}`}
    >
      {!showBadgeNotification && (
        <GameModalStandardBody
          newEntry={newEntry}
          isCurrentSweepstake={isCurrentSweepstake}
          totalNumberOfLevels={totalNumberOfLevels}
        />
      )}
      {showBadgeNotification && (
        <GameModalBadgeBody badgeDescription={newBadgeNotification!.description} badgeKey={newBadgeNotification!.key} />
      )}

      <Modal.Footer>
        {showRestart ? (
          <div className="btn-row">
            <Button
              variant="primary"
              size="sm"
              withArrow
              className="mx-sm-1"
              onClick={() => {
                trackEvent('User Game Continued', 'Continue Button Selected', 'Returning User Game Modal');
                hideModal();
                setBadgeNotificationShown();
              }}
              disabled={loading}
            >
              Continue
            </Button>
            <Button
              variant="outline-primary"
              size="sm"
              withArrow
              className="mx-sm-1"
              onClick={() => {
                trackEvent('User Game Restarted', 'Start Again Button Selected', 'Returning User Game Modal');
                restart();
                setBadgeNotificationShown();
              }}
              disabled={loading}
            >
              Start Again
            </Button>
          </div>
        ) : (
          <Button
            variant="primary"
            size="lg"
            withArrow
            onClick={() => {
              trackEvent('Play Now', 'Play Now Selected', 'Game Intro Modal');
              hideModal();
              setBadgeNotificationShown();
            }}
            disabled={loading}
          >
            Start Playing
          </Button>
        )}
        {isCurrentSweepstake && !showBadgeNotification && (
          <p className="modal-disclaimer mt-3 light text-center">Up to 11 bonus entries per day for game play</p>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default GameModal;
