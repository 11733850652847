import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from 'components/Layout/Button';
import GetConfig from '../../helpers/GetConfig';
import { removeTrailingSlash } from 'helpers';
import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';

interface IInviteLinkProps {
  inviteCode: string;
  groupName: string;
}

const { appUrl } = GetConfig();

const InviteLink: React.FC<IInviteLinkProps> = ({ inviteCode, groupName }) => {
  const inviteUrl = `${removeTrailingSlash(appUrl)}/group-invite/${inviteCode}`;
  const [hasCopied, setHasCopied] = useState<boolean>(false);

  return (
    <>
      <div className="copy-link">
        <input type="text" id="shareLink" name="sharelink" value={inviteUrl} readOnly className="share-link__input" />
        <CopyToClipboard
          text={inviteUrl}
          onCopy={() => {
            setHasCopied(true);
          }}
        >
          <Button
            size="sm"
            className="btn-primary"
            onClick={() => trackEvent('Copy link', 'Copy link click', 'Group Page ' + inviteCode)}
          >
            {hasCopied ? <>Copied</> : <>Copy Link</>}
          </Button>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default InviteLink;
