import { IGroupBaseModel } from 'types/Groups';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/Layout/Button';
import React, { useState } from 'react';
import callApi from 'infrastructure/api/CallApi';
import { iconGroupMembers } from 'images/icons';
import { numberWithCommas } from 'helpers';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RootState from 'types';
import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';

interface IPublicGroupListItemProps {
  group: IGroupBaseModel;
  onGroupJoinSuccess: (group: IGroupBaseModel) => void;
  isMember: boolean;
}

const PublicGroupListItem: React.FC<IPublicGroupListItemProps> = ({ group, onGroupJoinSuccess, isMember }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const location = useLocation();
  const { id: userId } = useSelector((state: RootState) => state.user);
  const isPublicListPage = location.pathname.includes('group-list');
  const label = isPublicListPage ? 'Public Group List Page' : 'Group Index Page';

  const trackJoinOrView = (joinOrView: string) => {
    const category = joinOrView === 'join' ? 'Join group' : 'View group';
    const action = category + ' click';
    trackEvent(category, action, label);
  };

  const onJoinClick = () => {
    trackJoinOrView('join');
    if (!isSubmitting && !isMember) {
      setIsSubmitting(true);
      callApi<IGroupBaseModel>('api/app/group/join', 'POST', {
        userId,
        inviteCode: group.inviteCode,
      })
        .then(({ data }) => {
          onGroupJoinSuccess(data);
          trackEvent('Join Group', 'Join Group Success', label);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Col sm={6} className="public-group-list-item">
      <div className="card card-groups">
        <div className="group-name">{group.name}</div>
        <div className="medal-data">
          <img src={iconGroupMembers} alt="icon-group" className="img-fluid" />
          <span className="ticket-count">{numberWithCommas(group.memberCount)}</span>
        </div>
        <Row className="button-group row-tight">
          <Col xl={6}>
            <Link to={`/group/${group.inviteCode}`} onClick={() => trackJoinOrView('view')}>
              <Button variant="outline-primary" size="sm">
                View
              </Button>
            </Link>
          </Col>
          {!isMember && (
            <Col xl={6}>
              <Button
                variant="primary"
                className="join-button"
                size="sm"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={onJoinClick}
              >
                Join
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </Col>
  );
};

export default PublicGroupListItem;
