import PromotionState from './PromotionState';
import { ADDTIMEBONUS } from '../game/actionTypes';
import { PromotionActionTypes } from './actionTypes';
import { SETCONFIG } from '../gameconfig/actionTypes';

const initialState: PromotionState = {
  promotionIndex: 0,
  promotedItems: [],
  count: 0,
};

const promotionReducer = (state = initialState, action: PromotionActionTypes) => {
  switch (action.type) {
    case ADDTIMEBONUS:
      return {
        ...state,
        promotionIndex: state.promotionIndex < state.count - 1 ? state.promotionIndex + 1 : 0,
      };
    case SETCONFIG:
      const newState: PromotionState = {
        ...state,
        promotedItems: action.promotedItems,
        count: action.promotedItems.length,
      };
      return newState;
    default:
      return state;
  }
};

export default promotionReducer;
