import React from 'react';
import './Confetti.scss';

interface ConfettiProps {
  repeat?: boolean;
}

const Confetti: React.FC<ConfettiProps> = ({ repeat }) => {
  return (
    <div className={`wrapper ${repeat ? 'confetti-repeat' : 'confetti-norepeat'}`}>
      <div className="balloon-2">
        <svg width="92" height="340" viewBox="0 0 92 340" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M43.7569 340C43.5883 339.999 43.4223 339.957 43.2743 339.876C43.1262 339.795 43.0008 339.678 42.9093 339.537C42.8179 339.395 42.7633 339.233 42.7507 339.064C42.7381 338.896 42.7677 338.727 42.837 338.574C47.333 328.609 48.3619 318.838 46.0649 307.827C44.0039 297.942 39.537 288.296 34.8099 278.08C27.945 263.266 20.8499 247.942 19.7479 229.489C18.4679 208.061 25.6609 186.737 32.0079 167.921C36.8679 153.514 41.0629 141.074 40.7109 131.185C40.7064 131.052 40.728 130.92 40.7746 130.795C40.8212 130.671 40.8919 130.557 40.9825 130.46C41.0731 130.363 41.182 130.285 41.3029 130.23C41.4237 130.175 41.5542 130.144 41.6869 130.14H41.7239C42.2659 130.14 42.7159 130.568 42.7359 131.113C43.1009 141.369 38.8479 153.971 33.9269 168.563C27.6419 187.202 20.5139 208.327 21.7689 229.368C22.8459 247.441 29.8609 262.587 36.6449 277.236C46.0449 297.534 54.9229 316.71 44.6799 339.402C44.5099 339.778 44.1419 340 43.7569 340V340Z"
              fill="#B3B3B3"
            />
            <path
              d="M37.954 122.755C37.954 122.755 28.695 131.989 32.587 134.711C36.111 137.174 39.448 133.156 43.887 135.821C48.326 138.486 51.57 135.97 51.748 133.944C52.028 130.746 47.492 122.638 47.492 122.638L37.954 122.755V122.755Z"
              fill="#49C6E8"
            />
            <path
              d="M37.954 122.755C37.954 122.755 28.695 131.989 32.587 134.711C36.111 137.174 39.448 133.156 43.887 135.821C48.326 138.486 51.57 135.97 51.748 133.944C52.028 130.746 47.492 122.638 47.492 122.638L37.954 122.755V122.755Z"
              fill="#FC723C"
            />
            <path
              opacity="0.1"
              d="M37.954 122.755C37.954 122.755 28.695 131.989 32.587 134.711C36.111 137.174 39.448 133.156 43.887 135.821C48.326 138.486 51.57 135.97 51.748 133.944C52.028 130.746 47.492 122.638 47.492 122.638L37.954 122.755V122.755Z"
              fill="black"
            />
            <path
              d="M41.7229 131.153C41.7229 131.153 10.8289 108.219 2.15794 76.0498C-5.34206 48.2158 6.21994 0.557817 44.5219 0.0048171C78.2669 -0.480183 96.1389 29.5568 90.4489 63.1148C85.1439 94.4288 68.3759 111.008 41.7229 131.152V131.153Z"
              fill="#FC723C"
            />
            <path
              opacity="0.1"
              d="M90.4539 63.1196C85.1479 94.4296 68.3809 111.009 41.7239 131.153C41.7239 131.153 23.1059 117.328 10.7209 96.0927C25.0129 98.5557 41.1489 94.9456 52.8409 84.8626C80.9319 60.6496 73.2659 9.13965 73.2659 9.13965C87.7309 20.4766 94.1919 41.0336 90.4539 63.1196Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="92" height="340" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="balloon-1">
        <svg width="98" height="275" viewBox="0 0 98 275" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M97.096 275C97.0143 275 96.933 274.989 96.854 274.968C96.731 274.935 84.376 271.445 71.635 262.378C64.14 257.042 57.965 250.846 53.282 243.959C47.422 235.335 43.904 225.609 42.822 215.045C40.906 196.295 46.145 183.32 51.211 170.77C57.249 155.813 63.496 140.345 57.722 114.647C57.6695 114.413 57.7121 114.168 57.8405 113.965C57.9688 113.763 58.1724 113.619 58.4065 113.567C58.6405 113.514 58.8859 113.557 59.0885 113.685C59.2911 113.814 59.4345 114.017 59.487 114.251C65.384 140.496 59.031 156.227 52.888 171.439C47.675 184.353 42.75 196.551 44.62 214.861C45.668 225.112 49.078 234.554 54.758 242.919C59.31 249.626 65.326 255.668 72.633 260.878C85.121 269.776 97.215 273.198 97.335 273.23C97.4491 273.262 97.5559 273.315 97.6493 273.388C97.7427 273.461 97.8209 273.551 97.8793 273.654C97.9377 273.757 97.9753 273.87 97.9898 273.988C98.0043 274.105 97.9956 274.224 97.964 274.338C97.9127 274.529 97.7998 274.697 97.6428 274.817C97.4857 274.937 97.2935 275.001 97.096 275V275Z"
              fill="#B3B3B3"
            />
            <path
              d="M53.124 108.336C53.124 108.336 47.754 118.669 51.8 119.932C55.467 121.077 57.216 116.762 61.714 117.835C66.212 118.903 68.291 115.892 67.894 114.121C67.264 111.333 61.204 105.669 61.204 105.669L53.124 108.336V108.336Z"
              fill="#FC723C"
            />
            <path
              opacity="0.1"
              d="M53.124 108.336C53.124 108.336 47.754 118.669 51.8 119.932C55.467 121.077 57.216 116.762 61.714 117.835C66.212 118.903 68.291 115.892 67.894 114.121C67.264 111.333 61.204 105.669 61.204 105.669L53.124 108.336V108.336Z"
              fill="black"
            />
            <path
              d="M58.6049 114.452C58.6049 114.452 26.0979 103.29 9.99789 78.3092C-3.93311 56.6902 -7.02811 13.1042 25.4079 2.32717C53.9839 -7.16783 77.3379 13.5282 81.6039 43.5582C85.5879 71.5772 75.8189 90.1682 58.6049 114.452V114.452Z"
              fill="#FC723C"
            />
            <path
              opacity="0.1"
              d="M58.63 114.424C58.623 114.431 58.62 114.438 58.616 114.445C58.616 114.449 58.612 114.449 58.609 114.453C58.609 114.453 55.893 113.521 51.641 111.588C51.411 111.484 51.179 111.38 50.941 111.268C50.7181 111.164 50.4947 111.061 50.271 110.959C49.635 110.66 48.973 110.343 48.283 110.005C48.019 109.872 47.748 109.739 47.473 109.602C44.7767 108.255 42.1297 106.811 39.537 105.274C38.312 104.548 37.1005 103.799 35.903 103.029L35.288 102.629C35.082 102.496 34.876 102.359 34.666 102.223C34.4593 102.086 34.2533 101.948 34.048 101.809C33.63 101.529 33.2144 101.246 32.801 100.96C44.381 98.2997 56.913 91.9357 62.958 81.6737C77.873 56.3537 71.574 23.7367 52.325 2.3457C52.328 2.3457 52.332 2.3457 52.335 2.3497C52.524 2.4177 52.715 2.4937 52.915 2.5657C68.002 8.4767 78.835 24.0497 81.607 43.5617C85.588 71.5667 75.827 90.1577 58.63 114.424V114.424Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="98" height="275" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="confetti-25">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" fill="none" viewBox="0 0 14 25">
          <path fill="#6621A9" d="M6.936.997L.462 22.794l6.666 1.982L13.602 2.98 6.936.997z"></path>
        </svg>
      </div>
      <div className="confetti-24">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="53" fill="none" viewBox="0 0 43 53">
          <path
            fill="#E21219"
            d="M22.797 52.15c-2.253 0-4.307-.172-6.152-.51-5.286-.98-7.28-3.1-7.766-3.73-1.549-2.014-2.045-4.385-1.438-6.863.96-3.93 4.892-7.828 9.347-11.122-8.568-.167-13.863-2.849-15.763-7.985-.72-1.944-.575-4.018.421-6C5.522 7.85 25.101 1.814 28.982.686l1.938 6.686c-8.452 2.46-21.258 7.716-23.265 11.706-.163.32-.126.409-.112.45.923 2.497 5.388 3.735 12.254 3.401 6.297-.306 12.305-1.832 12.366-1.85l2.336 6.523c-.046.023-4.979 2.338-10.047 5.554-8.702 5.52-10.051 8.709-10.255 9.553-.084.348-.07.561.083.807.728.534 6.57 4.093 26.6-1.09l1.742 6.737c-7.728 1.981-14.363 2.988-19.825 2.988zm-8.48-8.588c.019.032.042.06.065.093 0 0-.018-.038-.065-.093z"
          ></path>
        </svg>
      </div>
      <div className="confetti-23">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" fill="none" viewBox="0 0 14 25">
          <path fill="#E21219" d="M6.936.997L.462 22.794l6.666 1.982L13.602 2.98 6.936.997z"></path>
        </svg>
      </div>
      <div className="confetti-22">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="49" fill="none" viewBox="0 0 43 49">
          <path
            fill="#6621A9"
            d="M42.81 48.996c-1.202 0-22.534-.213-34.565-14.578-7.066-8.44-9.366-19.84-6.83-33.885l6.844 1.24c-2.152 11.914-.362 21.393 5.318 28.177C23.6 41.92 42.53 42.04 42.814 42.04l.018 6.96c-.004-.005-.014-.005-.023-.005z"
          ></path>
        </svg>
      </div>
      <div className="confetti-21">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="49" fill="none" viewBox="0 0 43 49">
          <path
            fill="#E21219"
            d="M42.81 48.996c-1.202 0-22.534-.213-34.565-14.578-7.066-8.44-9.366-19.84-6.83-33.885l6.844 1.24c-2.152 11.914-.362 21.393 5.318 28.177C23.6 41.92 42.53 42.04 42.814 42.04l.018 6.96c-.004-.005-.014-.005-.023-.005z"
          ></path>
        </svg>
      </div>
      <div className="confetti-20">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" fill="none" viewBox="0 0 14 25">
          <path fill="#6621A9" d="M6.936.997L.462 22.794l6.666 1.982L13.602 2.98 6.936.997z"></path>
        </svg>
      </div>
      <div className="confetti-19">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" fill="none" viewBox="0 0 14 25">
          <path fill="#6621A9" d="M6.936.997L.462 22.794l6.666 1.982L13.602 2.98 6.936.997z"></path>
        </svg>
      </div>
      <div className="confetti-18">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="26" fill="none" viewBox="0 0 43 26">
          <path
            fill="#F5C730"
            d="M33.18 25.746c-7.83 0-15.578-2.232-21.55-6.398C5.097 14.787 1.17 8.408.27.9L7.175.07c.83 6.927 5.045 11.2 8.434 13.566 6.764 4.724 16.427 6.357 25.213 4.264l1.609 6.77a39.563 39.563 0 01-9.25 1.076z"
          ></path>
        </svg>
      </div>
      <div className="confetti-17">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" fill="none" viewBox="0 0 14 25">
          <path fill="#E21219" d="M6.936.997L.462 22.794l6.666 1.982L13.602 2.98 6.936.997z"></path>
        </svg>
      </div>
      <div className="confetti-16">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="53" fill="none" viewBox="0 0 43 53">
          <path
            fill="#E21219"
            d="M22.797 52.15c-2.253 0-4.307-.172-6.152-.51-5.286-.98-7.28-3.1-7.766-3.73-1.549-2.014-2.045-4.385-1.438-6.863.96-3.93 4.892-7.828 9.347-11.122-8.568-.167-13.863-2.849-15.763-7.985-.72-1.944-.575-4.018.421-6C5.522 7.85 25.101 1.814 28.982.686l1.938 6.686c-8.452 2.46-21.258 7.716-23.265 11.706-.163.32-.126.409-.112.45.923 2.497 5.388 3.735 12.254 3.401 6.297-.306 12.305-1.832 12.366-1.85l2.336 6.523c-.046.023-4.979 2.338-10.047 5.554-8.702 5.52-10.051 8.709-10.255 9.553-.084.348-.07.561.083.807.728.534 6.57 4.093 26.6-1.09l1.742 6.737c-7.728 1.981-14.363 2.988-19.825 2.988zm-8.48-8.588c.019.032.042.06.065.093 0 0-.018-.038-.065-.093z"
          ></path>
        </svg>
      </div>
      <div className="confetti-15">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="20" fill="none" viewBox="0 0 43 20">
          <path fill="#49C6E8" d="M39.958.015L.147 12.955l2.148 6.619 39.81-12.94L39.959.016z"></path>
        </svg>
      </div>
      <div className="confetti-14">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="20" fill="none" viewBox="0 0 43 20">
          <path fill="#F5C730" d="M40.695.11L.885 13.05l2.148 6.62 39.81-12.94L40.696.11z"></path>
        </svg>
      </div>
      <div className="confetti-13">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="49" fill="none" viewBox="0 0 43 49">
          <path
            fill="#49C6E8"
            d="M42.81 48.996c-1.202 0-22.534-.213-34.565-14.578-7.066-8.44-9.366-19.84-6.83-33.885l6.844 1.24c-2.152 11.914-.362 21.393 5.318 28.177C23.6 41.92 42.53 42.04 42.814 42.04l.018 6.96c-.004-.005-.014-.005-.023-.005z"
          ></path>
        </svg>
      </div>
      <div className="confetti-12">
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="53" fill="none" viewBox="0 0 51 53">
          <path
            fill="#F5C730"
            d="M27.03 52.402c-4.822 0-13.306-2.092-27.03-11.548l3.945-5.73C20.99 46.87 27.693 45.526 28.555 45.275c.223-.18.316-.375.353-.728.097-.863-.079-4.32-6.361-12.49-3.658-4.756-7.497-8.626-7.535-8.668l4.433-5.326c.05.032 5.17 3.53 10.979 5.98 6.333 2.673 10.951 3.04 12.676 1.012.028-.032.088-.107.046-.46-.519-4.435-10.747-13.77-17.84-18.98L29.417 0c3.26 2.394 19.584 14.783 20.637 23.784.26 2.204-.316 4.199-1.66 5.781-3.547 4.176-9.445 4.872-17.545 2.092 3.056 4.626 5.416 9.637 4.97 13.66-.282 2.538-1.562 4.594-3.7 5.948-.56.353-2.141 1.137-5.09 1.137zm1.47-7.085c-.065.037-.093.06-.093.06.033-.018.065-.037.093-.06z"
          ></path>
        </svg>
      </div>
      <div className="confetti-11">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="53" fill="none" viewBox="0 0 43 53">
          <path
            fill="#49C6E8"
            d="M22.797 52.15c-2.253 0-4.307-.172-6.152-.51-5.286-.98-7.28-3.1-7.766-3.73-1.549-2.014-2.045-4.385-1.438-6.863.96-3.93 4.892-7.828 9.347-11.122-8.568-.167-13.863-2.849-15.763-7.985-.72-1.944-.575-4.018.421-6C5.522 7.85 25.101 1.814 28.982.686l1.938 6.686c-8.452 2.46-21.258 7.716-23.265 11.706-.163.32-.126.409-.112.45.923 2.497 5.388 3.735 12.254 3.401 6.297-.306 12.305-1.832 12.366-1.85l2.336 6.523c-.046.023-4.979 2.338-10.047 5.554-8.702 5.52-10.051 8.709-10.255 9.553-.084.348-.07.561.083.807.728.534 6.57 4.093 26.6-1.09l1.742 6.737c-7.728 1.981-14.363 2.988-19.825 2.988zm-8.48-8.588c.019.032.042.06.065.093 0 0-.018-.038-.065-.093z"
          ></path>
        </svg>
      </div>
      <div className="confetti-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" fill="none" viewBox="0 0 25 26">
          <path fill="#F5C730" d="M19.662 0L0 20.75l5.046 4.789L24.708 4.789 19.662 0z"></path>
        </svg>
      </div>
      <div className="confetti-9">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" fill="none" viewBox="0 0 14 25">
          <path fill="#49C6E8" d="M6.936.997L.462 22.794l6.666 1.982L13.602 2.98 6.936.997z"></path>
        </svg>
      </div>
      <div className="confetti-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="none" viewBox="0 0 16 24">
          <path fill="#F5C730" d="M6.827.015L.44 2.773 9.447 23.65l6.385-2.758L6.827.015z"></path>
        </svg>
      </div>
      <div className="confetti-7">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="20" fill="none" viewBox="0 0 43 20">
          <path
            fill="#49C6E8"
            d="M6.904 20L.056 18.79C1.614 9.968 9.17 2.864 18.86 1.106 28.584-.656 37.755 3.552 42.8 12.094l-5.986 3.54c-3.574-6.06-9.824-8.927-16.71-7.679C13.26 9.195 7.962 14.033 6.904 20z"
          ></path>
        </svg>
      </div>
      <div className="confetti-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="49" fill="none" viewBox="0 0 43 49">
          <path
            fill="#E21219"
            d="M42.81 48.996c-1.202 0-22.534-.213-34.565-14.578-7.066-8.44-9.366-19.84-6.83-33.885l6.844 1.24c-2.152 11.914-.362 21.393 5.318 28.177C23.6 41.92 42.53 42.04 42.814 42.04l.018 6.96c-.004-.005-.014-.005-.023-.005z"
          ></path>
        </svg>
      </div>
      <div className="confetti-5">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="23" fill="none" viewBox="0 0 42 23">
          <path
            fill="#49C6E8"
            d="M7.198 22.992l-6.955-.028C.28 14.01 6.521 5.723 15.775 2.345c9.282-3.392 19.033-.798 25.454 6.76l-5.3 4.505c-4.552-5.363-11.196-7.13-17.766-4.728-6.533 2.38-10.937 8.055-10.965 14.11z"
          ></path>
        </svg>
      </div>
      <div className="confetti-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="21" fill="none" viewBox="0 0 44 21">
          <path
            fill="#F5C730"
            d="M20.014 20.287c-8.03 0-15.277-4.115-19.566-11.344L6.43 5.389c3.589 6.05 9.843 8.908 16.724 7.646C29.99 11.783 35.286 6.93 36.325.962l6.852 1.197c-1.539 8.82-9.082 15.943-18.768 17.72a24.223 24.223 0 01-4.395.408z"
          ></path>
        </svg>
      </div>
      <div className="confetti-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="22" fill="none" viewBox="0 0 48 22">
          <path
            fill="#49C6E8"
            d="M17.447 21.91c-6.31 0-12.06-2.097-16.807-6.162l4.52-5.285c5.3 4.538 11.29 4.83 15.384 4.278 8.174-1.104 16.39-6.444 21.443-13.938l5.763 3.898c-6.13 9.085-16.2 15.576-26.274 16.94-1.363.18-2.708.269-4.03.269z"
          ></path>
        </svg>
      </div>
      <div className="confetti-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="21" fill="none" viewBox="0 0 48 21">
          <path
            fill="#F5C730"
            d="M28.528 20.625c-4.373 0-8.657-.729-12.537-2.204C8.549 15.586 3.19 10.352.496 3.286L6.99.803c2.486 6.52 7.613 9.642 11.476 11.113 7.705 2.937 17.474 2.176 25.49-1.986l3.204 6.175c-5.754 2.988-12.286 4.52-18.633 4.52z"
          ></path>
        </svg>
      </div>
      <div className="confetti-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="26" fill="none" viewBox="0 0 43 26">
          <path
            fill="#49C6E8"
            d="M33.18 25.746c-7.83 0-15.578-2.232-21.55-6.398C5.097 14.787 1.17 8.408.27.9L7.175.07c.83 6.927 5.045 11.2 8.434 13.566 6.764 4.724 16.427 6.357 25.213 4.264l1.609 6.77a39.563 39.563 0 01-9.25 1.076z"
          ></path>
        </svg>
      </div>
      <div className="confetti-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="63" height="27" fill="none" viewBox="0 0 63 27">
          <path
            fill="#F5C730"
            d="M33.392 26.674a33.64 33.64 0 01-7.33-.788C15.318 23.506 6.551 15.873 0 3.196L6.18 0c5.546 10.741 12.732 17.158 21.347 19.079 15.216 3.392 30.428-8.138 30.581-8.254l4.252 5.507c-.626.487-13.557 10.342-28.968 10.342z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Confetti;
