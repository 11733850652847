import React from 'react';

const PlayIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29543 3C5.90546 2.9996 5.52181 3.10157 5.1807 3.29628C4.83088 3.47998 4.53617 3.75829 4.32818 4.10138C4.12018 4.44448 4.00674 4.83941 4 5.24387V19.744V19.7549C4.00674 20.1594 4.12018 20.5543 4.32818 20.8974C4.53617 21.2405 4.83088 21.5188 5.1807 21.7025C5.528 21.9012 5.91942 22.0037 6.31669 21.9999C6.71395 21.9961 7.10345 21.8861 7.4471 21.6807L18.8319 14.4259C19.1832 14.2435 19.4785 13.9641 19.6847 13.619C19.891 13.2738 20.0001 12.8764 20 12.4711C19.9999 12.0658 19.8905 11.6685 19.684 11.3235C19.4775 10.9784 19.182 10.6993 18.8306 10.5171L7.44578 3.31667C7.09604 3.10871 6.69924 2.99948 6.29543 3Z"
        fill="#652B82"
      />
      <defs>
        <linearGradient id="gradient" x1="20" y1="22" x2="3.3939" y2="9.70365" gradientUnits="userSpaceOnUse">
          <stop stop-color="#BB0F8B" />
          <stop offset="1" stop-color="#FFBC39" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlayIcon;
