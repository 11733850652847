/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import callApi from '../../../infrastructure/api/CallApi';

import './CountdownTimer.scss';
import { useSelector, useDispatch } from 'react-redux';
import RootState, { EntryCountDto } from '../../../types/index';
import { setEntryCount } from '../../../slices/users/actions';
interface CountdownResponse {
  secondsRemaining: number;
  currentSweepstakeName: string | null;
  nextSweepstakeName: string | null;
}

interface CountdownDetails {
  totalSecondsRemaining: number;
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

interface CountdownTimerProps {
  className?: string;
}

const getSecondsRemaining: (
  countdownDetails: CountdownDetails | undefined,
  countdownResponse: CountdownResponse | undefined
) => number = (countdownDetails, countdownResponse) => {
  if (countdownDetails) {
    return countdownDetails.totalSecondsRemaining;
  }
  return countdownResponse?.secondsRemaining || 0;
};

const floorAndPad = (value: number) => Math.floor(value).toString().padStart(2, '0');

const second = 1;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

const CountdownTimer: React.FC<CountdownTimerProps> = ({ className }) => {
  const [loading, setLoading] = useState(true);
  const [countdownResponse, setCountdownResponse] = useState<CountdownResponse>();
  const [countdownDetails, setCountdownDetails] = useState<CountdownDetails>();
  const userId = useSelector((state: RootState) => state.user.id);
  const dispatch = useDispatch();

  const getCountdownDetailsFromApi = () => {
    callApi<CountdownResponse>('api/app/sweepstake-countdown/countdown', 'GET').then(({ data }) => {
      setCountdownResponse(data);
      setLoading(false);
      if (countdownDetails && countdownDetails.totalSecondsRemaining <= 0 && data.secondsRemaining > 0) {
        setCountdownDetails({
          ...countdownDetails,
          totalSecondsRemaining: data.secondsRemaining,
        });
      }
    });
  };

  const updateCountdown = () => {
    const totalSecondsRemaining = getSecondsRemaining(countdownDetails, countdownResponse);
    if (totalSecondsRemaining !== undefined) {
      if (totalSecondsRemaining <= 0) {
        setCountdownDetails({
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
          totalSecondsRemaining: -1,
        });
        if (countdownResponse?.nextSweepstakeName) {
          getCountdownDetailsFromApi();
          callApi<EntryCountDto>(`api/app/form-entry/enter-existing-user/${userId}`, 'POST').then(
            ({ data }) => {
              dispatch(setEntryCount(data));             
            }
          );
        }
        return;
      }
      setCountdownDetails({
        totalSecondsRemaining: totalSecondsRemaining - 1,
        days: floorAndPad(totalSecondsRemaining / day),
        hours: floorAndPad((totalSecondsRemaining % day) / hour),
        minutes: floorAndPad((totalSecondsRemaining % hour) / minute),
        seconds: floorAndPad((totalSecondsRemaining % minute) / second),
      });
    }
  };

  useEffect(() => {
    getCountdownDetailsFromApi();
  }, []);

  useEffect(() => {
    if (!countdownDetails || countdownDetails.totalSecondsRemaining >= 0) {
      const timeout = setTimeout(updateCountdown, 1000);
      return () => clearTimeout(timeout);
    }
  }, [countdownDetails, countdownResponse]);

  if (
    loading ||
    !countdownDetails ||
    !countdownResponse ||
    (!countdownResponse.currentSweepstakeName && !countdownResponse.nextSweepstakeName)
  ) {
    return <></>;
  }

  return (
    <div className={`countdown-timer ${className || ''}`}>
      <p className="countdown-caption">
        {countdownResponse?.nextSweepstakeName && <span>{countdownResponse.nextSweepstakeName} Starts In </span>}
        {!countdownResponse?.nextSweepstakeName && countdownResponse?.currentSweepstakeName && (
          <span>{countdownResponse.currentSweepstakeName} Ends In </span>
        )}
      </p>
      <div className="countdown-sections">
        <p className="countdown-caption">
          {countdownResponse?.nextSweepstakeName && <span>{countdownResponse.nextSweepstakeName} Starts In </span>}
          {!countdownResponse?.nextSweepstakeName && countdownResponse?.currentSweepstakeName && (
            <span>{countdownResponse.currentSweepstakeName} Ends In </span>
          )}
        </p>
        <div className="countdown-section-content-container countdown-section-content-container-background">
          <p>{countdownDetails.days}</p>
        </div>
        <div className="countdown-separator">
          <p>:</p>
        </div>
        <div className="countdown-section-content-container countdown-section-content-container-background">
          <p>{countdownDetails.hours}</p>
        </div>
        <div className="countdown-separator">
          <p>:</p>
        </div>
        <div className="countdown-section-content-container countdown-section-content-container-background">
          <p>{countdownDetails.minutes}</p>
        </div>
        <div className="countdown-separator">
          <p>:</p>
        </div>
        <div className="countdown-section-content-container countdown-section-content-container-background">
          <p>{countdownDetails.seconds}</p>
        </div>
      </div>
      <div className="countdown-sections">
        <div className="countdown-section-content-container">
          <p>Days</p>
        </div>
        <div className="countdown-section-content-container">
          <p>Hours</p>
        </div>
        <div className="countdown-section-content-container">
          <p>Minutes</p>
        </div>
        <div className="countdown-section-content-container">
          <p>Seconds</p>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
