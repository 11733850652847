// @ts-nocheck
import GetConfig from '../Settings/config';
import store from '../../infrastructure/redux/configureStore';
import {
  setScoreOnLevelComplete,
  updateScoreDuringGame,
  updateShuffleCount,
  updateHintCount,
  loaded,
} from '../../slices/game/actions';
import preload from '../Helpers/preload';
import { coord, SceneConfig, PieceSprite, GameOverType } from '../types';
import { findWay } from '../Functions/gameLogic';
import { hint } from '../Functions/possibleMoves';
import levels from '../Helpers/levels';
import { drop as mikedrop } from '../Functions/boardMovement';
import { showLines, hideLines } from '../Functions/lineDrawing';
import { setNoPossibleMoves, gameOver, toggleSound, togglePaused } from '../../slices/game/actions';
import { startingHints, startingShuffles } from '../../slices/game/reducer';
import { trackEvent } from '../../infrastructure/tracking/GoogleAnalytics';
import { isAndroid } from 'react-device-detect';

let state = 'wait';
let gameStarted = false;
let useHintRef = () => {};
let shuffleBoardRef = () => {};
let gameOverRef = () => {};
let scoreOnLevelStart = 0;

const playerData = {
  hintsLeft: startingHints,
  shufflesLeft: startingShuffles,
  score: 0,
};
var game_data = {
  sound: true,
};

export default class Game extends Phaser.Scene {
  constructor() {
    super('Game');
  }
  public startGame(startingScore: number) {
    state = 'play';
    gameStarted = true;
    playerData.score = startingScore;
    scoreOnLevelStart = startingScore;
    const { hintsRemaining, shufflesRemaining } = store.getState().game;
    playerData.hintsLeft = hintsRemaining;
    playerData.shufflesLeft = shufflesRemaining;
    if (isAndroid) {
      this.scale.refresh();
    }
  }

  public shuffleBoard() {
    shuffleBoardRef();
  }
  public pauseGame() {
    state = 'paused';
    store.dispatch(togglePaused(true));
  }
  public resumeGame() {
    state = 'play';
    store.dispatch(togglePaused(false));
  }
  public useHint() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useHintRef();
  }
  public toggleSound() {
    game_data.sound = !game_data.sound;
    store.dispatch(toggleSound(game_data.sound));
  }
  public gameOver(gameOverType: GameOverType) {
    state = 'gameover1';
    gameOverRef(gameOverType);
  }
  preload = () => preload(this);

  create(sceneConfig: SceneConfig) {
    if (sceneConfig.autoStart) {
      this.startGame(0);
    }
    const thisScene = this;
    thisScene.sound.setVolume(0.2);

    playerData.score = 0;
    const config = GetConfig(sceneConfig.windowWidth);
    const levelData = levels[sceneConfig.level];
    levelData.isMobile = sceneConfig.windowWidth < 768;
    let numberOfColumns = levelData.numberOfColumns();
    let numberOfRows = levelData.numberOfRows();
    const { pointsPerMatch } = levelData;
    state = gameStarted ? 'play' : state;
    let self = this;
    let hint_shown: Array<coord>;
    let pieces = this.add.group();
    let lines = this.add.group();
    let selected = 0;
    const { tileSize, tilePadding, devicePixelRatio } = config;
    const canvasWidth = devicePixelRatio * config.width;

    let maxColumns = sceneConfig.windowWidth > 767 ? 8 : 6;
    let maxRows = sceneConfig.windowWidth > 767 ? 6 : 8;

    let size = {
      width: tileSize,
      height: tileSize,
    };
    // Centering the tiles
    const yMargin = (sceneConfig.windowWidth > 767 ? 60 : 80) * devicePixelRatio;
    const rowsToUse = sceneConfig.windowWidth > 767 ? maxRows : numberOfRows;
    const canvasHeight = rowsToUse * tileSize + tilePadding * (rowsToUse - 1) + yMargin * 2;

    let start_x =
      (canvasWidth - size.width * numberOfColumns) / 2 + size.width / 2 - (tilePadding * (maxColumns - 1)) / 2;
    let start_y_mobile = tileSize / 2 + yMargin;
    let start_y_desktop =
      (canvasHeight - size.height * numberOfRows) / 2 + size.height / 2 - (tilePadding * (numberOfRows - 1)) / 2;
    const start_y = sceneConfig.windowWidth > 767 ? start_y_desktop : start_y_mobile;
    let array = [];

    thisScene.scale.resize(canvasWidth, canvasHeight);
    thisScene.scale.canvas.style.setProperty('width', `${config.width}px`);
    thisScene.scale.canvas.style.setProperty('height', `${canvasHeight / devicePixelRatio}px`);

    //Generate pieces from data
    let colors = [];
    const { tileCount } = store.getState().gameConfig;
    const tileChoicesShuffled = shuffle(Array.from({ length: tileCount }, (_, i) => i + 1));
    let _cur = 0;
    for (let i = 0; i < (numberOfRows * numberOfColumns) / 2; i++) {
      if (_cur >= levelData.numberOfTileTypes) {
        _cur = 0;
      }
      colors.push(tileChoicesShuffled[_cur]);
      _cur++;
    }
    colors = colors.concat(colors);
    shuffle(colors);
    let index = 0;
    for (let y = 0; y < numberOfRows; y++) {
      let arr_x = [];
      for (let x = 0; x < numberOfColumns; x++) {
        let color = colors[index];
        let data = {
          color: color,
          filled: false,
        };
        data.filled = true;
        const piece: PieceSprite = this.add
          .sprite(
            start_x + size.width * x + tilePadding * x,
            start_y + size.height * y + tilePadding * y,
            'obj' + color
          )
          .setInteractive();
        // Set the image size to match the tile
        piece.displayWidth = size.width;
        piece.displayHeight = size.height;
        piece.color = color;
        piece.piece = true;
        piece.pos = {
          x: x,
          y: y,
        };
        pieces.add(piece);
        index++;
        arr_x.push(data);
      }
      array.push(arr_x);
    }

    //START UI
    // this.add.sprite(config.width/2,0,'header').setOrigin(0.5, 0);
    let sign = this.add.sprite(180, 180, 'sign');
    sign.displayWidth = size.width;
    sign.displayHeight = size.width;
    const animationScale = 1.1 * sign.scale;
    sign.setDepth(100);
    sign.setVisible(false);
    this.tweens.add({
      targets: sign,
      scaleX: animationScale,
      scaleY: animationScale,
      ease: 'Linear',
      duration: 250,
      yoyo: true,
      repeat: -1,
    });
    //END UI
    for (let i = 0; i < 25; i++) {
      let line = this.add.sprite(0, 0, 'lines');
      line.setDepth(100);
      line.setVisible(false);
      lines.add(line);
    }

    this.input.on('pointerup', (pointer) => {
      pointer.event.preventDefault();
    });

    this.input.on(
      'gameobjectdown',
      (pointer, obj) => {
        if (obj.piece) {
          if (hint_shown) {
            let total = pieces.getLength();
            let child = pieces.getChildren() as PieceSprite[];
            if (true) {
              for (let i = 0; i < total; i++) {
                let piece = child[i]!;
                if (
                  (piece.pos!.x === hint_shown[0].x && piece.pos!.y === hint_shown[0].y) ||
                  (piece.pos!.x === hint_shown[1].x && piece.pos!.y === hint_shown[1].y)
                ) {
                  // piece.available
                  piece.clearTint();
                }
              }
            }
            hint_shown = null;
          }
          if (!selected) {
            if (state === 'play') {
              //obj.available
              play_sound('itemclick', self);
              selected = obj;
              obj.setTint(0xe5bbd9);
              sign.setVisible(true);
              sign.setPosition(obj.x, obj.y);
            }
          } else {
            if (state === 'play') {
              // obj.available
              play_sound('itemclick', self);
              // eslint-disable-next-line eqeqeq
              if (obj.pos.x != selected.pos.x || obj.pos.y != selected.pos.y) {
                obj.setTint(0xe5bbd9);
                if (array[obj.pos.y][obj.pos.x].color === array[selected.pos.y][selected.pos.x].color) {
                  let way = findWay(selected.pos, obj.pos, array, levelData);
                  if (way) {
                    playerData.score += pointsPerMatch;
                    store.dispatch(updateScoreDuringGame(playerData.score));
                    trackEvent('Game Interaction', 'Successful Match Made', `Level ${sceneConfig.level}`);
                    state = 'wait1';
                    sign.setVisible(false);
                    showLines(way, lines, start_x, start_y, size, tilePadding, () => play_sound('connected', self));
                    array[obj.pos.y][obj.pos.x].filled = false;
                    array[selected.pos.y][selected.pos.x].filled = false;
                    setTimeout(() => {
                      state = 'wait';
                      copy_obj(obj.x, obj.y, obj.color);
                      copy_obj(selected.x, selected.y, selected.color);
                      obj.destroy(true, true);
                      selected.destroy(true, true);
                      selected = null;
                      setTimeout(() => {
                        mikedrop(
                          levelData,
                          array,
                          (newState) => {
                            state = newState;
                          },
                          pieces,
                          start_x,
                          start_y,
                          tilePadding,
                          size,
                          self,
                          () => completed(),
                          () => {
                            if (playerData.shufflesLeft > 0) {
                              store.dispatch(setNoPossibleMoves());
                              play_sound('nomatch', self);
                            } else {
                              state = 'gameover1';
                              setTimeout(() => gameover(GameOverType.NoMoves), 500);
                            }
                          }
                        );
                      }, 100);
                      hideLines(lines);
                    }, 300);
                  } else {
                    selected.clearTint();
                    selected = obj;
                    sign.setPosition(obj.x, obj.y);
                    trackEvent('Game Interaction', 'Unsuccessful Match Made', `Level ${sceneConfig.level}`);
                  }
                } else {
                  selected.clearTint();
                  selected = obj;
                  sign.setPosition(obj.x, obj.y);
                }
              }
            }
          }
        }
      },
      this
    );
    if (!hint(array, levelData, pieces)) {
      this.scene.start('game');
    }
    //
    function copy_obj(x, y, color) {
      let obj = self.add.sprite(x, y, 'obj' + color);
      obj.displayWidth = size.width;
      obj.displayHeight = size.width;

      self.tweens.add({
        targets: obj,
        scaleY: 0,
        scaleX: 0,
        duration: 150,
        ease: 'Linear',
        onComplete: () => {
          obj.destroy(true, true);
        },
      });
    }
    function shuffle(arr) {
      let m = arr.length,
        t,
        i;
      // While there remain elements to shuffle…
      while (m) {
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);
        // And swap it with the current element.
        t = arr[m];
        arr[m] = arr[i];
        arr[i] = t;
      }
      return arr;
    }
    function shuffle_pieces() {
      if (selected) {
        selected.clearTint();
        selected = null;
      }
      let arr = [];
      let total = pieces.getLength();
      let child = pieces.getChildren();
      for (let y = 0; y < numberOfRows; y++) {
        for (let x = 0; x < numberOfColumns; x++) {
          if (array[y][x].filled) {
            arr.push(array[y][x].color);
          }
        }
      }
      shuffle(arr);
      for (let i = 0; i < total; i++) {
        let piece = child[i];
        piece.color = arr[i];
        piece.setTexture('obj' + piece.color);
      }
      board_update();
      if (!hint(array, levelData, pieces)) {
        shuffle_pieces();
      } else {
        setTimeout(() => {
          play_sound('shuffle', self);
        }, 200);
      }
    }
    function show_hint(array, levelData, pieces) {
      let total = pieces.getLength();
      let child = pieces.getChildren();
      let _hint = hint(array, levelData, pieces);
      if (_hint) {
        hint_shown = _hint;
        for (let i = 0; i < total; i++) {
          let piece = child[i];
          if (
            (piece.pos.x === _hint[0].x && piece.pos.y === _hint[0].y) ||
            (piece.pos.x === _hint[1].x && piece.pos.y === _hint[1].y)
          ) {
            // piece.available
            piece.setTint(0xe5bbd9);
          }
        }
      } else {
        alert('err');
      }
      setTimeout(() => {
        play_sound('hint', self);
      }, 200);
    }

    function board_update() {
      for (let y = 0; y < numberOfRows; y++) {
        for (let x = 0; x < numberOfColumns; x++) {
          array[y][x].filled = false;
          array[y][x].color = 0;
          array[y][x].to = null;
        }
      }
      let total = pieces.getLength();
      let child = pieces.getChildren();
      for (let i = 0; i < total; i++) {
        let piece = child[i];
        array[piece.pos.y][piece.pos.x].filled = true;
        array[piece.pos.y][piece.pos.x].color = piece.color;
      }
    }
    function completed() {
      state = 'bonus';
      gameStarted = false;
      store.dispatch(
        setScoreOnLevelComplete(playerData.score - scoreOnLevelStart, playerData.score, sceneConfig.level)
      );
      play_sound('completed', self);
      if (sceneConfig.level < sceneConfig.totalNumberOfLevels) {
        thisScene.scene.start('Game', {
          level: sceneConfig.level + 1,
          windowWidth: window.innerWidth,
          startingScore: sceneConfig.level < sceneConfig.totalNumberOfLevels ? playerData.score : 0,
          totalNumberOfLevels: sceneConfig.totalNumberOfLevels,
        });
      }
    }
    function gameover(gameOverType: GameOverType) {
      store.dispatch(gameOver(gameOverType === GameOverType.NoMoves ? 'No more moves!' : 'Out of time!'));
      play_sound('gameover', self);
      state = 'gameover';
    }
    gameOverRef = gameover;

    const useHint = () => {
      if (state === 'play' && playerData.hintsLeft > 0) {
        playerData.hintsLeft--;
        store.dispatch(updateHintCount());
        show_hint(array, levelData, pieces);
        if (playerData.hintsLeft === 0) {
          // obj.alpha = 0.5;
        }
      }
    };
    const shuffleBoard = () => {
      if (state === 'play' && playerData.shufflesLeft > 0) {
        playerData.shufflesLeft--;
        store.dispatch(updateShuffleCount());
        shuffle_pieces();
      }
    };
    useHintRef = useHint;
    shuffleBoardRef = shuffleBoard;

    store.dispatch(loaded());
  }
}
function play_sound(id, scope) {
  if (game_data.sound) {
    scope.sound.play(id);
  }
}
