import React from 'react';

import hallmarkCrownSweepstakes from '../../images/icons/hallmark-crown-golden.webp';
import hallmarkCrown from '../../images/icons/hallmark-orange-crown.svg';
import { useSelector } from 'react-redux';
import { selectIsCurrentSweepstake } from 'slices/sweepstakes/selectors';
import classNames from 'classnames';

const HallmarkCrown = () => {
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  return (
    <div className="hallmark-crown-container">
      <div
        className={classNames('hallmark-crown-line', isCurrentSweepstake ? 'hallmark-crown-line--sweepstakes' : '')}
      />
      <img className="hallmark-crown" src={isCurrentSweepstake ? hallmarkCrownSweepstakes : hallmarkCrown} alt="" />
      <div
        className={classNames('hallmark-crown-line', isCurrentSweepstake ? 'hallmark-crown-line--sweepstakes' : '')}
      />
    </div>
  );
};

export default HallmarkCrown;
