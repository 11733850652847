import * as React from 'react';
import { Col } from 'react-bootstrap';

type ProfileStatProps = {
  title: string;
  image: string;
  value: string;
};

type ProfileSingleStatProps = ProfileStatProps & {
  type: 'SINGLE';
};

type ProfileDualStatProps = ProfileStatProps & {
  subheader1: string;
  subheader2: string;
  value2: string;
  type: 'DOUBLE';
};

const ProfileStat: React.FunctionComponent<ProfileSingleStatProps | ProfileDualStatProps> = (props) => {
  const { title, image, value } = props;

  return (
    <Col className="profile-stats-col" xs={6} lg={{ span: 4 }}>
      <div className="profile-stat-container">
        <div className="profile-stat_icon">
          <img src={image} alt="" className="img-fluid" />
        </div>
        <div className="profile-stat">
          <h4>{title}</h4>
          {props.type === 'DOUBLE' ? (
            <div className="profile-stat_dual">
              <div className="profile-stat profile-stat_tight">
                <h5>{props.subheader1}</h5>
                <p>{value}</p>
              </div>
              <div className="profile-stat-divider"></div>
              <div className="profile-stat profile-stat_tight">
                <h5>{props.subheader2}</h5>
                <p>{props.value2}</p>
              </div>
            </div>
          ) : (
            <p>{value}</p>
          )}
        </div>
      </div>
    </Col>
  );
};

export default ProfileStat;
